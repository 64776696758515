import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { RunRoster } from '../../../model/RunRoster';

@Component({
    selector: 'app-roster-generation',
    templateUrl: './roster-generation.component.html',
    styleUrls: ['./roster-generation.component.scss'],
})
export class RosterGenerationComponent {
    public formGroup: FormGroup;
    public pharmaGroupingIdFormControl: FormControl;
    public rosterPublicationIdFormControl: FormControl;
    public isQCRosterFormControl: FormControl;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    submitted: boolean = false;

    constructor(private http: HttpClient, private snackBar: MatSnackBar) {
        this.pharmaGroupingIdFormControl = new FormControl('', [
            Validators.required,
        ]);
        this.rosterPublicationIdFormControl = new FormControl('');
        this.isQCRosterFormControl = new FormControl(true);

        this.formGroup = new FormGroup({
            pharmaGroupingId: this.pharmaGroupingIdFormControl,
            rosterPublicationId: this.rosterPublicationIdFormControl,
            isQCRoster: this.isQCRosterFormControl,
        });
    }

    runRoster() {
        this.submitted = true;
        if (this.formGroup.invalid) {
            return;
        }

        var roster: RunRoster = {} as RunRoster;
        roster.pharmaGroupingId = this.pharmaGroupingIdFormControl.value;
        roster.onDemandRosterPublicationId = this.rosterPublicationIdFormControl.value;
        roster.isQCRoster = this.isQCRosterFormControl.value;
        this.http.post<any>(`/api/rosters/run`, roster).subscribe();

        this.openSnackBar(
            'Your report is generated. Please see logs to track progress.'
        );
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }
}
