<div class="column-layout">
    <div class="fixedDivStyle">
        <button
            color="primary"
            (click)="newemailgroup()"
            mat-raised-button
            class="buttonstyling">
            New Email Group
        </button>
        <button
            color="primary"
            style="margin-left: 10px"
            mat-raised-button
            (click)="btnExport()">
            Export
        </button>
    </div>

    <div class="flex-container-grid mainDivStyle">
        <div class="mat-elevation-z8">
            <div style="margin-bottom: 10px">
                <mat-accordion>
                    <mat-expansion-panel
                        [expanded]="panelOpenState"
                        (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Filters </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field class="matFilterField">
                            <input
                                matInput
                                class="form-field matInputFiterPadding"
                                [formControl]="emailGroupNameFilter"
                                placeholder="Email Group Name" />
                        </mat-form-field>

                        <mat-form-field class="matFilterField">
                            <input
                                matInput
                                class="form-field matInputFiterPadding"
                                [formControl]="manufacturerNameFilter"
                                placeholder="Manufacturer" />
                        </mat-form-field>

                        <div style="text-align: center">
                            <button
                                color="primary"
                                mat-raised-button
                                (click)="resetFilters()">
                                Reset
                            </button>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div style="width: 100%">
                <mat-table
                    [dataSource]="dataSource"
                    class="mat-elevation-z8"
                    matSort
                    style="width: 100%">
                    <ng-container matColumnDef="groupName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            Email Group Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.groupName }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="manufacturerName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            Manufacturer
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.manufacturerName }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="totalContacts">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            Contacts
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.totalContacts }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="updateDate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            Last Modified
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.updateDate | date : 'MM/dd/yyyy' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="active">
                        <mat-header-cell *matHeaderCellDef
                            >Active</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element">
                            <mat-checkbox
                                [value]="element.active"
                                [checked]="element.active"
                                [disabled]="true"></mat-checkbox>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actionsColumn">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell
                            class="icon-padding"
                            *matCellDef="let element; let j = index"
                            (click)="$event.stopPropagation()">
                            <mat-icon
                                inline="true"
                                matTooltip="Click to Edit"
                                (click)="getEmailGroup(element)"
                                >edit</mat-icon
                            >
                            <mat-icon
                                inline="true"
                                matTooltip="Click to Delete"
                                (click)="deleteEmailGroup(element.id)"
                                >close</mat-icon
                            >
                        </mat-cell>
                    </ng-container>

                    <mat-header-row
                        *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="
                            let row;
                            columns: displayedColumns
                        "></mat-row>
                </mat-table>
                <mat-paginator
                    [pageSizeOptions]="[100, 150, 250, 500, 750, 1000]"
                    class="matpaginator"
                    aria-label="Select page of reports"></mat-paginator>
            </div>
        </div>
    </div>
</div>
