import {
    Directive,
    Input,
    TemplateRef,
    ElementRef,
    OnInit,
    HostListener,
    ComponentRef,
    OnDestroy,
} from '@angular/core';
import {
    Overlay,
    OverlayPositionBuilder,
    OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CustomToolTipComponent } from './custom-tool-tip/custom-tool-tip.component';

@Directive({
    selector: '[appCustomToolTip]',
})
export class ToolTipRendererDirective implements OnInit, OnDestroy {
    /**
     * This will be used to show tooltip or not
     * This can be used to show the tooltip conditionally
     */
    @Input() showToolTip: boolean = true;

    //If this is specified then specified text will be showin in the tooltip
    @Input(`appCustomToolTip`) text: string | undefined;

    //If this is specified then specified template will be rendered in the tooltip
    @Input() contentTemplate: TemplateRef<any> | undefined;

    private overlayRef: OverlayRef | undefined;

    constructor(
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        if (!this.showToolTip) {
            return;
        }

        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([
                {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'top',
                    offsetY: 5,
                    offsetX: -500,
                },
            ]);

        this.overlayRef = this.overlay.create({ positionStrategy });
    }

    /**
     * This method will be called whenever mouse enters in the Host element
     * i.e. where this directive is applied
     * This method will show the tooltip by instantiating the McToolTipComponent and attaching to the overlay
     */
    @HostListener('mouseenter')
    show() {
        //attach the component if it has not already attached to the overlay
        if (this.overlayRef && !this.overlayRef.hasAttached()) {
            const tooltipRef: ComponentRef<CustomToolTipComponent> =
                this.overlayRef.attach(
                    new ComponentPortal(CustomToolTipComponent)
                );
            tooltipRef.instance.text = this.text;
            tooltipRef.instance.contentTemplate = this.contentTemplate;
        }
    }

    /**
     * This method will be called when mouse goes out of the host element
     * i.e. where this directive is applied
     * This method will close the tooltip by detaching the overlay from the view
     */
    @HostListener('mouseleave')
    hide() {
        this.closeToolTip();
    }

    /**
     * Destroy lifecycle event handler
     * This method will make sure to close the tooltip
     * It will be needed in case when app is navigating to different page
     * and user is still seeing the tooltip; In that case we do not want to hang around the
     * tooltip after the page [on which tooltip visible] is destroyed
     */
    ngOnDestroy() {
        this.closeToolTip();
    }

    /**
     * This method will close the tooltip by detaching the component from the overlay
     */
    private closeToolTip() {
        if (this.overlayRef) {
            this.overlayRef.detach();
        }
    }
}
