import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
} from '@angular/material/dialog';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { QuarterCode } from '../../../model/QuarterCode';
import { ScorecardService } from '../../../services/scorecard.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-notes-copydialog',
    templateUrl: './notes-copydialog.component.html',
    styleUrls: ['./notes-copydialog.component.scss'],
})
export class NotesCopydialogComponent implements OnInit {
    sourceQuarterCode: Array<QuarterCode> = [];
    futureQuarterCode: Array<QuarterCode> = [];
    public sourcequarterCodeFormControl;
    public targetquarterCodeFormControl;
    submitted: boolean = false;
    sameQuarters: boolean = false;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    constructor(
        private http: HttpClient,
        private scorecardservice: ScorecardService,
        private dialogRef: MatDialogRef<NotesCopydialogComponent>,
        private service: ScorecardService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar
    ) {
        this.sourcequarterCodeFormControl = new FormControl('', [
            Validators.required,
        ]);
        this.targetquarterCodeFormControl = new FormControl('', [
            Validators.required,
        ]);
    }

    ngOnInit(): void {
        this.loadQuarterCode();
    }
    loadQuarterCode() {
        this.service.loadQuarterCode().subscribe(result => {
            this.sourceQuarterCode = result.sort((a, b) =>
                a.sortPosition < b.sortPosition ? -1 : 1
            );
            const currentquarter = result.find(
                obj => obj.currentQuarter === true
            );
            if (currentquarter != null) {
                this.sourcequarterCodeFormControl.setValue(
                    currentquarter.quarter
                );
                this.futureQuarterCode = this.sourceQuarterCode.filter(
                    obj => obj.sortPosition > currentquarter?.sortPosition
                );
            }
        });
    }

    copy() {
        this.submitted = true;
        if (this.sourcequarterCodeFormControl.value === '') {
            return;
        } else if (this.targetquarterCodeFormControl.value === '') {
            return;
        } else if (
            this.sourcequarterCodeFormControl.value ===
            this.targetquarterCodeFormControl.value
        ) {
            this.sameQuarters = true;
            return;
        }
        const userDialogConfig = new MatDialogConfig();
        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = {
            data: {
                message:
                    'Warning: This operation will overwrite existing data in the target quarter. Are you sure?',
                rowid: 0,
                sourceValue: this.sourcequarterCodeFormControl.value,
                targetValue: this.targetquarterCodeFormControl.value,
                source: 'CopyNotes',
                buttonText: {
                    ok: 'Copy',
                    cancel: 'Cancel',
                },
            },
        };

        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.dialogRef.close(true);
                    this.openSnackBar('Notes copied successfully.');
                }
            });
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }
}
