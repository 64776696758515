<!--<div style="margin-left:20%;margin-right:20%;display:flex" >-->
<div class="fixedDivStyleChildComponent">
    <div style="position: fixed; z-index: 5; margin-left: 30%">
        <button
            color="primary"
            mat-raised-button
            (click)="openUserDialog()"
            class="buttonstyling">
            New Schedule
        </button>
        <button
            color="primary"
            style="margin-left: 10px"
            class="buttonstyling"
            mat-raised-button
            (click)="btnExport()">
            Export
        </button>
    </div>
</div>
<div
    style="margin-bottom: 30px; margin-top: 60px; margin-left: 10px"
    class="mainDivStyle">
    <mat-accordion>
        <mat-expansion-panel
            [expanded]="panelOpenState"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            style="margin-left: 10px">
            <mat-expansion-panel-header>
                <mat-panel-title> Filters </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field class="matFilterField">
                <mat-label> Active</mat-label>
                <select
                    matNativeControl
                    name="Schedules"
                    [formControl]="ActiveFilterFormControl"
                    (change)="filterChange($event)">
                    <option value="-- Select Active --">
                        -- Select Active --
                    </option>
                    <option [value]="item" *ngFor="let item of filterValues">
                        {{ item }}
                    </option>
                </select>
            </mat-form-field>
            <div style="text-align: center">
                <button
                    color="primary"
                    mat-raised-button
                    (click)="resetFilters()">
                    Reset
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        matSort
        style="margin-top: 10px; margin-left: 10px">
        <ng-container matColumnDef="period">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Period</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.period }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="dayOfWeek">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Month/Day</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
                element.dayOfWeek
            }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="updateDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Last Modified</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
                {{ element.updateDate | date : 'MM/dd/yyyy' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updateUserId">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Updated UserID
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{
                element.updateUserId
            }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="isActive">
            <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-checkbox
                    [value]="element.active"
                    [checked]="element.active"
                    [disabled]="true"></mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actionsColumn">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell
                class="icon-padding"
                *matCellDef="let element; let j = index">
                <mat-icon
                    inline="true"
                    matTooltip="Click to Edit"
                    (click)="getSchedule(element)"
                    >edit</mat-icon
                >
                <mat-icon
                    inline="true"
                    matTooltip="Click to View Associated Reports"
                    (click)="ViewReport(element.id)"
                    >view_list</mat-icon
                >

                <mat-icon
                    inline="true"
                    matTooltip="Click to Delete"
                    (click)="deleteScheduleDialog(element.id)"
                    >close</mat-icon
                >
            </mat-cell>
        </ng-container>
        <!--<a href="schedule-list.component.html">schedule-list.component.html</a>-->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
</div>
<!--</div>-->
