<div align="right">
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <div style="text-align: left; margin-left: 25px">
        <mat-form-field class="buttonstyling">
            <mat-label>Choose a date</mat-label>
            <input
                (dateInput)="OnDateChange($event.value)"
                [formControl]="dateformcontrol"
                matInput
                [matDatepicker]="picker" />

            <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button
            class="buttonstyling"
            color="primary"
            mat-raised-button
            (click)="showLogs()">
            Show Logs
        </button>
        <button
            class="buttonstyling"
            color="primary"
            mat-raised-button
            (click)="ShowLoadStatus()">
            Show Load Status
        </button>
        <!--<button class="buttonstyling"
                color="primary"
                mat-raised-button
                (click)="refresh()">
            Refresh-->
        <!--</button>-->
    </div>
    <div class="mainDivStyle" *ngIf="showLogsTable">
        <mat-table
            [dataSource]="dataSource"
            matSort
            class="mat-elevation-z8"
            style="width: 99%; padding: 5px">


            <ng-container matColumnDef="timestamp">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Timestamp
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{
                        element.timestamp
                           | date : 'MM/dd/yyyy h:mm:ss a'
                    }}
                </mat-cell>
            </ng-container>

            ]

            <ng-container matColumnDef="message">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Message
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <a
                        (click)="openDialogReportingServicesLogMessage(element)"
                        *ngIf="element.message?.length >= 200"
                        style="cursor: pointer"
                        >Show More Detail</a
                    >
                    <div *ngIf="element.message?.length < 200">
                        {{ element.message }}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="exception">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Error
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <a
                        (click)="openDialogReportingServicesLogError(element)"
                        *ngIf="element.exception?.length >= 200"
                        style="cursor: pointer"
                        >Show More Detail</a
                    >
                    <div *ngIf="element.exception?.length < 200">
                        {{ element.exception }}
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row
                *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <mat-paginator
            style="width: 100%; padding: 5px"
            class="mat-elevation-z8"
            [pageSizeOptions]="[100, 150, 250, 500, 750, 1000]"
            aria-label="Select page of reports"></mat-paginator>
    </div>
    <div class="mainDivStyle" *ngIf="!showLogsTable">
        <table
            mat-table
            [dataSource]="dataSourceStatus"
            multiTemplateDataRows
            matSort
            class="mat-elevation-z8"
            style="width: 99%; padding: 5px">


            <ng-container matColumnDef="jobStartedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Job Start
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.jobStartedDate | date : 'MM/dd/yyyy h:mm:ss a' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="jobCompleteDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Job End
                </th>
                <td mat-cell *matCellDef="let element">
                    {{
                        element.jobCompleteDate | date : 'MM/dd/yyyy h:mm:ss a'
                    }}
                </td>
            </ng-container>
            <ng-container matColumnDef="reportsRunCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Reports Run
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.reportsRunCount }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actionsColumn">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                    mat-cell
                    class="icon-padding"
                    *matCellDef="let element; let j = index">
                    <!--<button class="buttonstyling"
                    color="primary"
                    mat-raised-button
                    (click)="toggleRow(element)">
                Show Detail
            </button>-->
                    <mat-icon (click)="toggleRow(element)">
                        {{
                            expandedElement === element
                                ? 'expand_less'
                                : 'expand_more'
                        }}
                    </mat-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td
                    mat-cell
                    *matCellDef="let element"
                    [attr.colspan]="displayedColumnsStatus.length">
                    <div
                        class="example-element-detail"
                        *ngIf="
                            element.pharmaReportJobStatusDetails?.data?.length
                        "
                        [@detailExpand]="
                            element === expandedElement
                                ? 'expanded'
                                : 'collapsed'
                        ">
                        <div
                            class="inner-table mat-elevation-z8"
                            *ngIf="expandedElement">
                            <mat-table
                                #innerTables
                                mat-table
                                #innerSort="matSort"
                                [dataSource]="
                                    element.pharmaReportJobStatusDetails
                                "
                                matSort
                                multiTemplateDataRows
                                style="padding: 5px">
                                <!--<ng-container matColumnDef="{{innerColumn
                                   }}" *ngFor="let innerColumn of innerDisplayedColumns">
                                   <mat-header-cell *matHeaderCellDef mat-sort-header> {{innerColumn | titlecase}} </mat-header-cell>
                                   <mat-cell *matCellDef="let element"> {{element[innerColumn]}} </mat-cell>
                        </ng-container>-->

                                <ng-container matColumnDef="reportName">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Report Name
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{ element.reportName }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="jobStart">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Job Start
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{
                                            element.jobStart
                                                | date : 'MM/dd/yyyy h:mm:ss a'
                                        }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="jobEnd">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Job End
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{
                                            element.jobEnd
                                                | date : 'MM/dd/yyyy h:mm:ss a'
                                        }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="noOfRecords">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        No Of Records
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{ element.noOfRecords }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="reportConfig">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Report Config
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{ element.reportConfig }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="eapData">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        EAP Data
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{ element.eapData }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="generateExcel">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Generate Excel
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{ element.generateExcel }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="blobUploadSF">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Blob UploadSF
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{ element.blobUploadSF }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="createSFUpload">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Create SFUpload
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        {{ element.createSFUpload }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="errorMessage">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header>
                                        Error
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <a
                                            (click)="openDialog(element)"
                                            *ngIf="
                                                element.errorMessage?.length >=
                                                200
                                            "
                                            style="cursor: pointer"
                                            >Show More Detail
                                        </a>
                                        <div
                                            *ngIf="
                                                element.errorMessage?.length <
                                                200
                                            ">
                                            {{ element.errorMessage }}
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row
                                    *matHeaderRowDef="
                                        innerDisplayedColumns
                                    "></mat-header-row>
                                <mat-row
                                    *matRowDef="
                                        let row;
                                        columns: innerDisplayedColumns
                                    "></mat-row>
                            </mat-table>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsStatus"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsStatus"
                [class.example-element-row]="
                    row.pharmaReportJobStatusDetails?.length
                "
                [class.example-expanded-row]="expandedElement === row"></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: ['expandedDetail']"
                class="example-detail-row"></tr>
        </table>
    </div>
</div>
