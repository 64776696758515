import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './components/home/home.component';
import { SystemStatusComponent } from './components/systemstatus/system-status.component';
import { EmailgroupListComponent } from './components/mailer/emailgroup-list/emailgroup-list.component';
import { EmailgroupComponent } from './components/mailer/emailgroup/emailgroup.component';
import { EmailtemplateListComponent } from './components/mailer/emailtemplate-list/emailtemplate-list.component';
import { EmailtemplateComponent } from './components/mailer/emailtemplate/emailtemplate.component';
import { NotesListComponent } from './components/scorecard/notes-list/notes-list.component';
import { SystemConfigurationComponent } from './components/systemconfiguration/system-configuration.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UserManagementComponent } from './components/user/user-management.component';
import { SortComponent } from './components/scorecard/sort/sort/sort.component';
import { ScheduledEmailComponent } from './components/mailer/scheduledemails/scheduledemail.component';
import { UserAuthenticatedGuardService } from './services/authorization/user-authenticated-guard.service';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';
import { ReportWizardComponent } from './components/reports/report-wizard/report-wizard.component';
import { ReportManagementComponent } from './components/reports/report-management/report-management.component';
import { ScheduleComponent } from './components/reports/schedule/schedule.component';
import { UsersGuardService } from './services/authorization/users-guard.service';
import { DatasetsGuardService } from './services/authorization/datasets-guard.service';
import { DatasetListComponent } from './components/reports/dataset-list/dataset-list.component';
import { AuditComponent } from './components/audit/audit.component';
import { RosterGenerationComponent } from './components/rosters/roster-generation/roster-generation.component';
import { RosterLogsComponent } from './components/rosters/roster-logs/roster-logs.component';

const routes: Routes = [
    {
        path: 'unauthorized',
        component: UnauthorizedComponent,
    },
    {
        path: 'systemconfiguration',
        component: SystemConfigurationComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Settings - GPOlink',
        },
    },
    {
        path: 'audit',
        component: AuditComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Audit - GPOlink',
        },
    },
    {
        path: 'systemstatus',
        component: SystemStatusComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'System Status - GPOlink',
        },
    },
    {
        path: 'usermanagement',
        component: UserManagementComponent,
        canActivate: [
            MsalGuard,
            UserAuthenticatedGuardService,
            UsersGuardService,
        ],
        data: {
            title: 'User Management - GPOlink',
        },
    },
    {
        path: 'schedules',
        component: ScheduleComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Schedules - GPOlink',
        },
    },
    {
        path: 'datasetlist',
        component: DatasetListComponent,
        canActivate: [
            MsalGuard,
            UserAuthenticatedGuardService,
            DatasetsGuardService,
        ],
        data: {
            title: 'Dataset - GPOlink',
        },
    },
    {
        path: 'reportmanagement',
        component: ReportManagementComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Report Management - GPOlink',
        },
    },
    {
        path: 'report-wizard',
        component: ReportWizardComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Report- Wizard - GPOlink',
        },
    },
    {
        path: 'emailtemplatelist',
        component: EmailtemplateListComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Email Templates  - GPOlink',
        },
    },
    {
        path: 'emailtemplate',
        component: EmailtemplateComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Email Templates - GPOlink',
        },
    },
    {
        path: 'emailgrouplist',
        component: EmailgroupListComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Email Groups - GPOlink',
        },
    },
    {
        path: 'emailgroup',
        component: EmailgroupComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Email Groups - GPOlink',
        },
    },
    {
        path: 'noteslist',
        component: NotesListComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Notes - GPOlink',
        },
    },
    {
        path: 'sort',
        component: SortComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
            title: 'Sort - GPOlink',
        },
    },
    {
        path: 'scheduledemails',
        component: ScheduledEmailComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Scheduled Emails - GPOlink',
        },
    },
    /*{
        path: 'rosterGeneration',
        component: RosterGenerationComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Roster Generation - GPOlink',
        },
    },*/
    {
        path: 'rosterLogs',
        component: RosterLogsComponent,
        canActivate: [MsalGuard, UserAuthenticatedGuardService],
        data: {
            title: 'Roster Logs - GPOlink',
        },
    },
    {
        path: '**',
        component: HomeComponent,
        canActivate: [MsalGuard],
    },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled', // Don't perform initial navigation in iframes
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
