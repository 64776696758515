<div style="margin-left: 20px; padding-bottom: 10px">
    <div>
        <p
            style="
                align-content: flex-start;
                padding-top: 3px;
                margin-left: 3px;
            ">
            {{ isAddOrEdit() }} Email Group
        </p>
    </div>

    <div class="rowStyle">
        <div class="divWidth">
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Group Name</mat-label>
                <input
                    type="text"
                    matInput
                    [formControl]="GroupNameFormControl"
                    maxlength="100"
                    required />
            </mat-form-field>
            <mat-error
                class="mat-errorField"
                *ngIf="GroupNameFormControl.hasError('required') && submitted">
                Group Name is <strong>required</strong>
            </mat-error>
        </div>
        <mat-checkbox
            class="checkboxstyling"
            [formControl]="isActiveFormControl">
            Active
        </mat-checkbox>
        <div style="position: fixed; right: 100px; z-index: 5">
            <button
                class="buttonstyling"
                color="primary"
                mat-raised-button
                (click)="onCancel()">
                Cancel
            </button>
            <button
                class="buttonstyling"
                color="primary"
                mat-raised-button
                (click)="onSubmit()">
                Save
            </button>
        </div>
    </div>
    <div>
        <mat-radio-group
            aria-label="Select an option"
            [formControl]="GroupTypeFormControl"
            (change)="radioButtonChanged()">
            <mat-radio-button value="1">Manufacturer</mat-radio-button>
            <mat-radio-button value="2">Internal Users</mat-radio-button>
        </mat-radio-group>
        <mat-error
            class="mat-errorField"
            *ngIf="GroupTypeFormControl.hasError('required') && submitted">
            <br />
            Select an <strong>option</strong>
        </mat-error>
    </div>
    <div *ngIf="isVisibleManufacturer" class="matTextField divWidth">
        <mat-form-field appearance="fill" class="full-width">
            <mat-select
                id="manufacturer"
                [formControl]="manufacturerFormControl"
                (selectionChange)="onChangeManufacturer($event.value)">
                <mat-option
                    *ngFor="let manufacturerlist of manufacturer"
                    [value]="manufacturerlist">
                    {{ manufacturerlist }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-error
            class="mat-errorField"
            *ngIf="manufacturerFormControl.hasError('required') && submitted">
            Manufacturer is <strong>required</strong>
        </mat-error>
    </div>
    <div *ngIf="isVisibleInternalUsers" class="matTextField divWidth">
        <mat-form-field appearance="fill" class="full-width">
            <input
                type="text"
                matInput
                (keyup)="applyFilter($any($event.target).value)"
                placeholder="Filter" />
        </mat-form-field>
    </div>
    <div class="main-container">
        <div style="width: 50%">
            <p style="align-content: flex-start; font-weight: bold">
                Excluded Contacts
            </p>

            <div class="matselection">
                <mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="firstName">
                        <mat-header-cell *matHeaderCellDef>
                            First Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.firstName }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lastName">
                        <mat-header-cell *matHeaderCellDef>
                            Last Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.lastName }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>
                            Email Address
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let element"
                            [matTooltip]="element.email">
                            {{ element.email }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="select">
                        <mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                                (change)="$event ? masterToggle() : null"
                                [checked]="
                                    selection.hasValue() && isAllSelected()
                                "
                                [indeterminate]="
                                    selection.hasValue() && !isAllSelected()
                                ">
                            </mat-checkbox>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row
                        *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="
                            let row;
                            columns: displayedColumns
                        "></mat-row>
                </mat-table>
            </div>
        </div>
        <div class="alignMatIcon">
            <mat-icon class="matIconDisplay" (click)="AddEmail()"
                >arrow_forward</mat-icon
            ><br />

            <mat-icon class="matIconDisplay" (click)="RemoveEmail()"
                >arrow_back</mat-icon
            >
        </div>
        <div style="width: 50%">
            <p style="align-content: flex-start; font-weight: bold">
                Included Contacts
            </p>

            <div class="matselection">
                <mat-error
                    class="mat-errorField"
                    *ngIf="!addedemailcontactList.length && submitted">
                    <br />
                    Please add a <strong>contact</strong>
                </mat-error>
                <mat-table [dataSource]="dataSourceSelected">
                    <ng-container matColumnDef="firstName">
                        <mat-header-cell *matHeaderCellDef>
                            First Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.firstName }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lastName">
                        <mat-header-cell *matHeaderCellDef>
                            Last Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.lastName }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef>
                            Email Address
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let element"
                            [matTooltip]="element.email">
                            {{ element.email }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="select">
                        <mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                                (change)="
                                    $event ? masterToggleSelectedValue() : null
                                "
                                [checked]="
                                    selectedEmails.hasValue() &&
                                    isAllSelectedEmail()
                                "
                                [indeterminate]="
                                    selectedEmails.hasValue() &&
                                    !isAllSelectedEmail()
                                ">
                            </mat-checkbox>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="
                                    $event ? selectedEmails.toggle(row) : null
                                "
                                [checked]="selectedEmails.isSelected(row)">
                            </mat-checkbox>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row
                        *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="
                            let row;
                            columns: displayedColumns
                        "></mat-row>
                </mat-table>
            </div>
        </div>
    </div>
</div>
