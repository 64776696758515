import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { clientSession } from '../../../client-session';
import { Router } from '@angular/router';
import { ReportDeleteDialogComponent } from '../report-delete-dialog/report-delete-dialog.component';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { filterValues, Report } from '../../../model/Report';
import { ReportService } from '../../../services/report.service';
import { View } from '../../../model/View';
import { FormControl } from '@angular/forms';
import { Schedule } from '../../../model/Schedule';
import { ReportParameter } from '../../../model/ReportParameter';
import { TableUtil } from '../../../utilities/TableUtil';
import { formatDate } from '@angular/common';
import { LogViewerComponent } from '../log-viewer/log-viewer.component';
import { RunReport } from '../../../model/RunReport';

@Component({
    selector: 'app-report-management',
    templateUrl: './report-management.component.html',
    styleUrls: ['./report-management.component.scss'],
})
export class ReportManagementComponent implements OnInit, AfterViewInit {
    enablelist: Array<Report> = [];
    reportparameterlist: Array<ReportParameter> = [];
    reportmanagementlist: Array<Report> = [];
    tooltipmsg: string | undefined;
    filterOptions: filterValues = {} as filterValues;
    //showTooltipinUI: boolean ;
    panelOpenState = false;
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    dataSource: MatTableDataSource<Report>;
    selection = new SelectionModel<Report>(true, []);
    displayedColumns: string[] = [
        'select',
        'manufacturerName',
        'productName',
        'gpo',
        'reportType',
        'reportPeriod',
        'dataset',
        'enabled',
        'actionsColumn',
    ];
    displayedColumnsReportParameters = [
        'columnName',
        'operator',
        'parameterValue',
    ];
    dataSourceReportParameters: MatTableDataSource<ReportParameter>;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    drugFilter = new FormControl();
    manufacturerNameFilter = new FormControl();
    productNameFilter = new FormControl();
    ScheduleFilterFormControl = new FormControl();
    GPO = new FormControl();
    ReportType = new FormControl();
    ReportPeriod = new FormControl();
    filteredValues = {
        manufacturerName: '',
        productName: '',
        gpo: '',
        reportType: '',
        reportPeriod: '',
        dataset: '',
        scheduleID: '',
        enabled: '',
    };
    filterSelectObj: any[] = [];
    schedules: Array<Schedule> = [];
    dateformcontrol = new FormControl(new Date());
    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        private router: Router,
        private _snackBar: MatSnackBar,
        private reportservice: ReportService
    ) {
        this.dataSource = new MatTableDataSource(this.reportmanagementlist);
        this.dataSourceReportParameters = new MatTableDataSource(
            this.reportparameterlist
        );
        this.filterSelectObj = [
            {
                name: 'GPO',
                columnProp: 'gpo',
                options: [],
            },
            {
                name: 'ReportType',
                columnProp: 'reportType',
                options: [],
            },
            {
                name: 'ReportPeriod',
                columnProp: 'reportPeriod',
                options: [],
            },
            {
                name: 'Dataset',
                columnProp: 'reportView',
                options: [],
            },
            {
                name: 'Enabled',
                columnProp: 'enabled',
                options: [],
            },
        ];
    }

    AddRemoveForEnableDisable(row: any, event: any) {
        if (event.target.checked) {
            this.enablelist.push(row);
        } else {
            if (this.enablelist.indexOf(row) >= 0) {
                this.enablelist.forEach((element, index) => {
                    if (element == row) this.enablelist.splice(index, 1);
                });
            }
        }
    }

    enable() {
        if (this.isAllSelected()) {
            this.enablelist = [];
            if (this.dataSource.filteredData !== undefined) {
                this.dataSource.filteredData.forEach(row =>
                    this.enablelist.push(row)
                );
            } else {
                this.dataSource.data.forEach(row => this.enablelist.push(row));
            }

            for (var i = 0; i < this.enablelist.length; i++) {
                this.enablelist[i].enabled = true;
                if (
                    this.enablelist[i].reportSchedules != null &&
                    this.enablelist[i].reportSchedules != undefined &&
                    this.enablelist[i].reportSchedules.length > 0
                ) {
                    this.enablelist[i].reportSchedules.splice(
                        0,
                        this.enablelist[i].reportSchedules.length
                    );
                    this.enablelist[i].updateUserId = this.getLoginUserId();
                }
            }

            //this.enablelist.map(function (x) {
            //    x.enabled = true;
            //    if (
            //        x.reportSchedules != null &&
            //        x.reportSchedules != undefined &&
            //        x.reportSchedules.length > 0
            //    ) {
            //        x.reportSchedules.splice(0, x.reportSchedules.length);
            //        //for (var j = 0; j < x.reportSchedules.length; j++) {

            //        //    x.reportSchedules[j].schedule == undefined;
            //        //}
            //    }
            //});
            this.enablelist.forEach(function (obj) {
                delete obj.showTooltip;
            });
            this.http
                .put<any>('/api/reports/statuses', this.enablelist)
                .subscribe({
                    next: data => {
                        this.resetFilters();
                        this.SaveFilterValues();
                        this.loadReportList();
                    },
                });
        } else if (this.selection.selected.length == 0) {
            this.openSnackBar('Please select a Report');
            return;
        } else {
            for (var i = 0; i < this.enablelist.length; i++) {
                this.enablelist[i].enabled = true;
                if (
                    this.enablelist[i].reportSchedules != null &&
                    this.enablelist[i].reportSchedules != undefined &&
                    this.enablelist[i].reportSchedules.length > 0
                ) {
                    this.enablelist[i].reportSchedules.splice(
                        0,
                        this.enablelist[i].reportSchedules.length
                    );
                    this.enablelist[i].updateUserId = this.getLoginUserId();
                }
            }

            //this.enablelist.map(function (x) {
            //    x.enabled = true;

            //    if (
            //        x.reportSchedules != null &&
            //        x.reportSchedules != undefined &&
            //        x.reportSchedules.length > 0
            //    ) {
            //        x.reportSchedules.splice(0, x.reportSchedules.length);

            //        //for (var j = 0; j < x.reportSchedules.length; j++) {
            //        //    x.reportSchedules[j].schedule == undefined;
            //        //}
            //    }
            //});
            this.enablelist.forEach(function (obj) {
                delete obj.showTooltip;
            });

            this.http
                .put<any>('/api/reports/statuses', this.enablelist)
                .subscribe({
                    next: data => {
                        this.resetFilters();
                        this.SaveFilterValues();
                        this.loadReportList();
                    },
                });

            this.openSnackBar('Report has been enabled successfully');
        }
        this.selection.clear();

        this.enablelist = [];
    }

    resetFilterandEnabledValues() {
        this.filterSelectObj.filter(o => {
            if (o.columnProp == 'enabled') {
                o.options = this.getFilterObject(
                    this.reportmanagementlist,
                    o.columnProp
                );
            }
        });
        this.resetFilters();
    }

    disable() {
        if (this.isAllSelected()) {
            this.enablelist = [];
            if (this.dataSource.filteredData !== undefined) {
                this.dataSource.filteredData.forEach(row =>
                    this.enablelist.push(row)
                );
            } else {
                this.dataSource.data.forEach(row => this.enablelist.push(row));
            }

            for (var i = 0; i < this.enablelist.length; i++) {
                this.enablelist[i].enabled = false;
                if (
                    this.enablelist[i].reportSchedules != null &&
                    this.enablelist[i].reportSchedules != undefined &&
                    this.enablelist[i].reportSchedules.length > 0
                ) {
                    this.enablelist[i].reportSchedules.splice(
                        0,
                        this.enablelist[i].reportSchedules.length
                    );
                    this.enablelist[i].updateUserId = this.getLoginUserId();
                }
            }

            //this.enablelist.map(function (x) {
            //    x.enabled = false;
            //    //x.showTooltip = undefined;
            //    if (
            //        x.reportSchedules != null &&
            //        x.reportSchedules != undefined &&
            //        x.reportSchedules.length > 0
            //    ) {
            //        x.reportSchedules.splice(0, x.reportSchedules.length);
            //        //for (var j = 0; j < x.reportSchedules.length; j++) {
            //        //    x.reportSchedules[j].schedule == undefined;
            //        //}
            //    }
            //});
            this.enablelist.forEach(function (obj) {
                delete obj.showTooltip;
            });
            this.http
                .put<any>('/api/reports/statuses', this.enablelist)
                .subscribe({
                    next: data => {
                        this.resetFilters();
                        this.SaveFilterValues();
                        this.loadReportList();
                    },
                });
            this.selection.clear();
        } else if (this.selection.selected.length == 0) {
            this.openSnackBar('Please select a Report');
            return;
        } else {
            for (var i = 0; i < this.enablelist.length; i++) {
                this.enablelist[i].enabled = false;
                if (
                    this.enablelist[i].reportSchedules != null &&
                    this.enablelist[i].reportSchedules != undefined &&
                    this.enablelist[i].reportSchedules.length > 0
                ) {
                    this.enablelist[i].reportSchedules.splice(
                        0,
                        this.enablelist[i].reportSchedules.length
                    );
                    this.enablelist[i].updateUserId = this.getLoginUserId();
                }
            }
            //this.enablelist.map(function (x) {
            //    x.enabled = false;

            //    if (
            //        x.reportSchedules != null &&
            //        x.reportSchedules != undefined &&
            //        x.reportSchedules.length > 0
            //    ) {
            //        x.reportSchedules.splice(0, x.reportSchedules.length);
            //        //for (var j = 0; j < x.reportSchedules.length; j++) {
            //        //    x.reportSchedules[j].schedule == undefined;
            //        //}
            //    }
            //});

            this.enablelist.forEach(function (obj) {
                delete obj.showTooltip;
            });

            this.http
                .put<any>('/api/reports/statuses', this.enablelist)
                .subscribe({
                    next: data => {
                        this.resetFilters();
                        this.SaveFilterValues();
                        this.loadReportList();
                    },
                });

            this.openSnackBar('Report has been disabled successfully');
        }
        this.selection.clear();

        this.enablelist = [];
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }

    Schedule() {
        var selectedReportIds: string[] = [];

        if (this.isAllSelected()) {
            if (this.dataSource.filteredData !== undefined) {
                this.dataSource.filteredData.forEach(row =>
                    selectedReportIds.push(row.id)
                );
            } else {
                this.dataSource.data.forEach(row =>
                    selectedReportIds.push(row.id)
                );
            }

            var reportlist: RunReport = {} as RunReport;
            reportlist.reportIds = selectedReportIds;
            reportlist.selectedDate = formatDate(
                this.dateformcontrol.value != undefined
                    ? this.dateformcontrol.value
                    : Date.now(),
                'MM-dd-yyyy',
                'en-US'
            );
            this.http.post<any>(`/api/reports/scheduleReport`, reportlist).subscribe(response =>{
                if (response.status === 'offSchedule') {
                    this.openSnackBar('The selected reports are off schedule' + response.reports.toString());
                }
                else if (response.status === 'duplicates') {
                    this.openSnackBar('The selected reports are already scheduled: ' + response.reports.toString());
                }
            });
        } else if (this.selection.selected.length == 0) {
            this.openSnackBar('Please select a Report');
            return;
        } else {
            for (var i = 0; i < this.enablelist.length; i++) {
                selectedReportIds.push(this.enablelist[i].id);
            }

            var reportlist: RunReport = {} as RunReport;
            reportlist.reportIds = selectedReportIds;
            reportlist.selectedDate = formatDate(
                this.dateformcontrol.value != undefined
                    ? this.dateformcontrol.value
                    : Date.now(),
                'MM-dd-yyyy',
                'en-US'
            );
            this.http.post<any>(`/api/reports/scheduleReport`, reportlist).subscribe(response => {
                if (response.status === 'offSchedule') {
                    this.openSnackBar('The selected reports are off schedule: ' + response.reports.toString());
                }
                else if (response.status === 'duplicates') {
                    this.openSnackBar('The selected reports are already scheduled: ' + response.reports.toString());
                }
            });

        }
    }
    Run() {
        var selectedReportIds: string[] = [];

        if (this.isAllSelected()) {
            if (this.dataSource.filteredData !== undefined) {
                this.dataSource.filteredData.forEach(row =>
                    selectedReportIds.push(row.id)
                );
            } else {
                this.dataSource.data.forEach(row =>
                    selectedReportIds.push(row.id)
                );
            }

            var reportlist: RunReport = {} as RunReport;
            reportlist.reportIds = selectedReportIds;
            reportlist.selectedDate = formatDate(
                this.dateformcontrol.value != undefined
                    ? this.dateformcontrol.value
                    : Date.now(),
                'MM-dd-yyyy',
                'en-US'
            );
            this.http.post<any>(`/api/reports/run`, reportlist).subscribe();
        } else if (this.selection.selected.length == 0) {
            this.openSnackBar('Please select a Report');
            return;
        } else {
            for (var i = 0; i < this.enablelist.length; i++) {
                selectedReportIds.push(this.enablelist[i].id);
            }

            var reportlist: RunReport = {} as RunReport;
            reportlist.reportIds = selectedReportIds;
            reportlist.selectedDate = formatDate(
                this.dateformcontrol.value != undefined
                    ? this.dateformcontrol.value
                    : Date.now(),
                'MM-dd-yyyy',
                'en-US'
            );
            this.http.post<any>(`/api/reports/run`, reportlist).subscribe();
        }
        //this.resetFilters();
        this.SaveFilterValues();
        this.loadReportList();

        this.selection.clear();

        this.enablelist = [];
        this.openSnackBar(
            'Your report is generated. Please see logs to track progress.'
        );
    }

    btnclick = () => {
        this.SaveFilterValues();
        this.router.navigateByUrl('/report-wizard');
    };

    //Excel Downloads of the report table
    btnExport() {
        this.reportservice
            .ExportReport(this.dataSource.filteredData.map(item => item.id))
            .subscribe(data => {
                var file = new Blob([data], {
                    type: 'application/vnd.ms-excel',
                });
                var fileURL = URL.createObjectURL(file);
                let downloadLink = document.createElement('a');
                document.body.appendChild(downloadLink);
                downloadLink.style.display = 'none';
                downloadLink.href = fileURL;
                downloadLink.target = '_blank';
                downloadLink.download = 'GPOlink - Report Management.xlsx';
                downloadLink.click();
                downloadLink.remove();
            });
    }

    btnDelete() {
        if (this.enablelist.length !== 0) {
            const userDialogConfig = new MatDialogConfig();
            userDialogConfig.disableClose = true;
            userDialogConfig.autoFocus = true;
            userDialogConfig.data = {
                reports: this.enablelist,
            };
            this.dialog
                .open(ReportDeleteDialogComponent, userDialogConfig)
                .afterClosed()
                .subscribe(saved => {
                    if (saved == true) {
                        this.loadReportList();
                    }
                });
        }
    }

    getReport(row: any) {
        this.SaveFilterValues();
        this.router.navigate(['/report-wizard'], { state: { viewMode: row } });
        //this.router.navigate(['/report-wizard'], { state: { viewMode: row }, queryParams: { filter: this.filteredValues } });
    }

    GetFilterValues() {
        this.filterOptions = clientSession.filterOptions;

        this.filteredValues.manufacturerName =
            this.filterOptions.manufacturerName || '';
        this.filteredValues.dataset = this.filterOptions.dataset || '';
        this.filteredValues.enabled = this.filterOptions.enabled || '';
        this.filteredValues.gpo = this.filterOptions.gpo || '';
        this.filteredValues.productName = this.filterOptions.productName || '';
        this.filteredValues.reportPeriod =
            this.filterOptions.reportPeriod || '';
        this.filteredValues.scheduleID = this.filterOptions.scheduleID || '';
        this.filteredValues.reportType = this.filterOptions.reportType || '';
        if (
            (this.filteredValues.manufacturerName != undefined &&
                this.filteredValues.manufacturerName.length > 0) ||
            (this.filteredValues.dataset != undefined &&
                this.filteredValues.dataset.length > 0) ||
            (this.filteredValues.gpo != undefined &&
                this.filteredValues.gpo.length > 0) ||
            (this.filteredValues.productName != undefined &&
                this.filteredValues.productName.length > 0) ||
            (this.filteredValues.reportPeriod != undefined &&
                this.filteredValues.reportPeriod.length > 0) ||
            (this.filteredValues.scheduleID != undefined &&
                this.filteredValues.scheduleID.length > 0) ||
            (this.filteredValues.reportType != undefined &&
                this.filteredValues.reportType.length > 0)
        ) {
            this.panelOpenState = true;
        } else {
            this.filteredValues.enabled = 'true';
            this.panelOpenState = true;
        }
        this.filterSelectObj.forEach((value, key) => {
            value.modelValue = undefined;
        });
        if (this.panelOpenState) {
            this.manufacturerNameFilter.setValue(
                this.filteredValues.manufacturerName
            );
            this.productNameFilter.setValue(this.filteredValues.productName);
            if (this.filteredValues.scheduleID != '') {
                this.ScheduleFilterFormControl.setValue(
                    this.filteredValues.scheduleID
                );
            }
            this.filterSelectObj.forEach((value, key) => {
                if (
                    value.columnProp === 'gpo' &&
                    this.filteredValues.gpo != undefined &&
                    this.filteredValues.gpo != ''
                ) {
                    value.modelValue = this.filteredValues.gpo;
                }
                if (
                    value.columnProp === 'reportType' &&
                    this.filteredValues.reportType != undefined &&
                    this.filteredValues.reportType != ''
                ) {
                    value.modelValue = this.filteredValues.reportType;
                }
                if (
                    value.columnProp === 'reportPeriod' &&
                    this.filteredValues.reportPeriod != undefined &&
                    this.filteredValues.reportPeriod != ''
                ) {
                    value.modelValue = this.filteredValues.reportPeriod;
                }
                if (
                    value.columnProp === 'reportView' &&
                    this.filteredValues.dataset != undefined &&
                    this.filteredValues.dataset != ''
                ) {
                    value.modelValue = this.filteredValues.dataset;
                }
                if (
                    value.columnProp === 'enabled' &&
                    this.filteredValues.enabled != undefined &&
                    this.filteredValues.enabled != ''
                ) {
                    value.modelValue = this.filteredValues.enabled;
                }
            });
            this.dataSource.filter = JSON.stringify(this.filteredValues);
            this.dataSource.filterPredicate = this.customFilterPredicate();
        }
    }

    SaveFilterValues() {
        this.filterOptions.manufacturerName =
            this.filteredValues.manufacturerName;
        this.filterOptions.dataset = this.filteredValues.dataset;
        this.filterOptions.enabled = this.filteredValues.enabled;
        this.filterOptions.gpo = this.filteredValues.gpo;
        this.filterOptions.productName = this.filteredValues.productName;
        this.filterOptions.reportPeriod = this.filteredValues.reportPeriod;
        this.filterOptions.scheduleID = this.filteredValues.scheduleID;
        this.filterOptions.reportType = this.filteredValues.reportType;
        clientSession.filterOptions = this.filterOptions;
    }

    getTooltip(row: Report) {
        this.reportparameterlist = [];
        if (
            row != undefined &&
            row.reportParameters != undefined &&
            row.reportParameters.length > 0
        ) {
            this.reportparameterlist = row.reportParameters.filter(
                obj =>
                    obj.columnName?.toLowerCase() === 'rebate name' &&
                    obj.active === true
            );
        }
        this.dataSourceReportParameters = new MatTableDataSource(
            this.reportparameterlist
        );
    }

    ngAfterViewInit(): void {
        this.dataSource = new MatTableDataSource(this.reportmanagementlist);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows =
            this.dataSource.filteredData !== undefined
                ? this.dataSource.filteredData.length
                : this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.filteredData !== undefined
            ? this.dataSource.filteredData.forEach(row =>
                  this.selection.select(row)
              )
            : this.dataSource.data.forEach(row => this.selection.select(row));
        if (this.isAllSelected() == false) {
            this.enablelist = [];
        } else {
            if (
                this.dataSource.filteredData !== undefined &&
                this.dataSource.filteredData.length > 0 &&
                this.selection.selected != undefined &&
                this.selection.selected.length > 0
            ) {
                this.enablelist = [];
                this.selection.selected.forEach(row =>
                    this.enablelist.push(row)
                );
            }
        }
    }

    ngOnInit(): void {
        this.loadReportList();
        // this.GetFilterValues();
        this.manufacturerNameFilter.valueChanges.subscribe(
            manufacturerFilterValue => {
                this.filteredValues['manufacturerName'] =
                    manufacturerFilterValue;
                this.dataSource.filter = JSON.stringify(this.filteredValues);
            }
        );
        this.productNameFilter.valueChanges.subscribe(productFilterValue => {
            this.filteredValues['productName'] = productFilterValue;
            this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.dataSource.filterPredicate = this.customFilterPredicate();
    }

    filterChange(filter: any, event: any) {
        if (filter == 'reportType') {
            this.filteredValues['reportType'] = event.target.value.trim();
            this.dataSource.filter = JSON.stringify(this.filteredValues);
            this.dataSource.filterPredicate = this.customFilterPredicate();
        } else if (filter == 'reportPeriod') {
            this.filteredValues['reportPeriod'] = event.target.value.trim();

            this.dataSource.filter = JSON.stringify(this.filteredValues);
            this.dataSource.filterPredicate = this.customFilterPredicate();
        } else if (filter == 'gpo') {
            this.filteredValues['gpo'] = event.target.value.trim();

            this.dataSource.filter = JSON.stringify(this.filteredValues);
            this.dataSource.filterPredicate = this.customFilterPredicate();
        } else if (filter == 'reportView') {
            this.filteredValues['dataset'] = event.target.value.trim();

            this.dataSource.filter = JSON.stringify(this.filteredValues);
            this.dataSource.filterPredicate = this.customFilterPredicate();
        } else if (filter == 'enabled') {
            this.filteredValues['enabled'] = event.target.value.trim();

            this.dataSource.filter = JSON.stringify(this.filteredValues);
            this.dataSource.filterPredicate = this.customFilterPredicate();
        } else {
            this.filteredValues['scheduleID'] = event.target.value.trim();

            this.dataSource.filter = JSON.stringify(this.filteredValues);
            this.dataSource.filterPredicate = this.customFilterPredicate();
        }
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

    customFilterPredicate() {
        const myFilterPredicate = function (
            data: Report,
            filter: string
        ): boolean {
            let searchString = JSON.parse(filter);
            if (searchString.scheduleID === '') {
                return (
                    data.gpo
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.gpo.toLowerCase()) !== -1 &&
                    data.manufacturerName
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(
                            searchString.manufacturerName.toLowerCase()
                        ) !== -1 &&
                    data.productName
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.productName.toLowerCase()) !==
                        -1 &&
                    data.reportType
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.reportType.toLowerCase()) !==
                        -1 &&
                    data.reportPeriod
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.reportPeriod.toLowerCase()) !==
                        -1 &&
                    data.reportView.viewName
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.dataset.toLowerCase()) !== -1 &&
                    data.enabled
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.enabled.toLowerCase()) !== -1
                );
            } else {
                return (
                    data.gpo
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.gpo.toLowerCase()) !== -1 &&
                    data.manufacturerName
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(
                            searchString.manufacturerName.toLowerCase()
                        ) !== -1 &&
                    data.productName
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.productName.toLowerCase()) !==
                        -1 &&
                    data.reportType
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.reportType.toLowerCase()) !==
                        -1 &&
                    data.reportPeriod
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.reportPeriod.toLowerCase()) !==
                        -1 &&
                    data.reportView.viewName
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.dataset.toLowerCase()) !== -1 &&
                    //p.agreements.some(agr => agr.agreementId.toLowerCase().includes(filterValue))

                    data.reportSchedules.some(agr =>
                        agr.scheduleId
                            .toLowerCase()
                            .includes(searchString.scheduleID)
                    ) &&
                    data.enabled
                        .toString()
                        .trim()
                        .toLowerCase()
                        .indexOf(searchString.enabled.toLowerCase()) !== -1
                );
            }
        };
        return myFilterPredicate;
    }

    // Get Uniqu values from columns to build filter
    getFilterObject(fullObj: any[], key: any) {
        if (key == 'reportView') {
            return this.getFilterObjectReportView(fullObj, key);
        }
        const uniqChk: any[] = [];

        fullObj.filter(obj => {
            if (!uniqChk.includes(obj[key])) {
                uniqChk.push(obj[key]);
            }
            return obj;
        });

        return uniqChk;
    }

    // Get Uniqu values from columns to build filter
    getFilterObjectReportView(fullObj: any[], key: any) {
        const uniqChk: any[] = [];

        fullObj.filter(obj => {
            if (!uniqChk.includes(obj[key].viewName)) {
                uniqChk.push(obj[key].viewName);
            }
            return obj;
        });

        return uniqChk;
    }

    resetFilters() {
        this.filteredValues = {
            manufacturerName: '',
            productName: '',
            gpo: '',
            reportType: '',
            reportPeriod: '',
            dataset: '',
            scheduleID: '',
            enabled: '',
        };
        this.filterSelectObj.forEach((value, key) => {
            value.modelValue = null;
        });

        this.dataSource.filter = '';
        //this.dataSource.filter = JSON.stringify(this.filteredValues);
        //this.dataSource.filterPredicate = this.customFilterPredicate();
        this.manufacturerNameFilter.setValue('');
        this.productNameFilter.setValue('');
        this.ScheduleFilterFormControl.setValue(undefined);
    }

    // Format the Schedules
    FormatScheduleObject(scheduleobj: Schedule) {
        if (scheduleobj.period.toUpperCase() == 'WEEKLY') {
            scheduleobj.period = 'Once a Week - ';
            switch (scheduleobj.dayOfWeek) {
                case 2:
                    scheduleobj.dayOfWeek = 'Monday';
                    break;
                case 3:
                    scheduleobj.dayOfWeek = 'Tuesday';
                    break;
                case 4:
                    scheduleobj.dayOfWeek = 'Wednesday';
                    break;
                case 5:
                    scheduleobj.dayOfWeek = 'Thursday';
                    break;
                case 6:
                    scheduleobj.dayOfWeek = 'Friday';
                    break;
                case 7:
                    scheduleobj.dayOfWeek = 'Saturday';
                    break;
                case 1:
                    scheduleobj.dayOfWeek = 'Sunday';
                    break;
                default:
                    scheduleobj.dayOfWeek = '';
            }
        } else if (scheduleobj.period.toUpperCase() == 'YEARLY') {
            scheduleobj.period = 'Once a Year - Month ';

            scheduleobj.dayOfWeek =
                scheduleobj.month == null
                    ? scheduleobj.day
                    : scheduleobj.month + ' Day ' + scheduleobj.day;
        } else if (scheduleobj.period.toUpperCase() == 'QUARTERLY') {
            scheduleobj.period = 'Once a Quarter - Month ';
            scheduleobj.dayOfWeek =
                scheduleobj.month + ' Day ' + scheduleobj.day;
        } else if (scheduleobj.period.toUpperCase() == 'MONTHLY') {
            scheduleobj.period = 'Once a Month - Day ';
            scheduleobj.dayOfWeek = scheduleobj.day;
        }

        scheduleobj.dayOfWeek = scheduleobj.period + scheduleobj.dayOfWeek;

        return scheduleobj;
    }

    OnDateChange(selecteddate: any) {
        selecteddate = formatDate(selecteddate, 'MM-dd-yyyy', 'en-US');
        // this.loadAuditLogList(selecteddate);
    }
    getLoginUserId() {
        return clientSession.linkUser?.id || '';
    }

    showLogs() {
        const userDialogConfig = new MatDialogConfig();
        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = {
            SelectedDate: this.dateformcontrol.value,
        };
        userDialogConfig.width = '100%';
        userDialogConfig.height = '100%';
        userDialogConfig.maxWidth = '98%';
        this.dialog.open(LogViewerComponent, userDialogConfig);
        //.afterClosed()
    }

    loadReportList() {
        this.reportservice.loadReportList().subscribe(result => {
            for (var i = 0; i < result.length; i++) {
                //Set the tool tip
                if (
                    result[i] != undefined &&
                    result[i].reportParameters != undefined &&
                    result[i].reportParameters.length > 0
                ) {
                    this.reportparameterlist = result[
                        i
                    ].reportParameters.filter(
                        obj =>
                            obj.columnName?.toLowerCase() === 'rebate name' &&
                            obj.active === true
                    );
                    if (this.reportparameterlist.length > 0) {
                        result[i].showTooltip = true;
                    }
                }
                if (
                    result[i].reportSchedules != null &&
                    result[i].reportSchedules != undefined &&
                    result[i].reportSchedules.length > 0
                ) {
                    for (var j = 0; j < result[i].reportSchedules.length; j++) {
                        if (
                            this.schedules.findIndex(
                                x =>
                                    x.id ===
                                    result[i].reportSchedules[j].scheduleId
                            ) < 0
                        ) {
                            var scheduleobj: Schedule;
                            scheduleobj = result[i].reportSchedules[j].schedule;

                            scheduleobj =
                                this.FormatScheduleObject(scheduleobj);
                            this.schedules.push(scheduleobj);
                            result[i].reportSchedules[j].schedule.dayOfWeek =
                                scheduleobj.dayOfWeek;
                        } else {
                            var scheduleobj: Schedule;
                            scheduleobj = result[i].reportSchedules[j].schedule;
                            scheduleobj =
                                this.FormatScheduleObject(scheduleobj);
                        }
                    }
                } else if (result[i].reportSchedules.length === 0) {
                    result[i].reportSchedules = []; // this.reportSchedules;
                }
            }
            //  console.log(result);
            this.reportmanagementlist = result;
            this.dataSource = new MatTableDataSource(this.reportmanagementlist);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.customFilterPredicate();

            this.filterSelectObj.filter(o => {
                o.options = this.getFilterObject(result, o.columnProp);
            });
            if (this.reportmanagementlist.length > 0) {
                if (clientSession.filterOptions != undefined) {
                    this.GetFilterValues();
                }
            }
        });
    }
}
