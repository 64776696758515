<div class="dialog-layout">
    <p align="center">
        {{ message }}
    </p>
    <p align="center">
        {{ message2 }}
    </p>
    <mat-dialog-actions align="center">
        <button color="primary" mat-raised-button (click)="delete()">
            Confirm
        </button>
        <button color="primary" mat-raised-button mat-dialog-close>
            Cancel
        </button>
    </mat-dialog-actions>
</div>
