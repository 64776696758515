import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailGroup } from '../model/EmailGroup';
import { ManufacturerContact } from '../model/ManufacturerContact';

@Injectable({
    providedIn: 'root',
})
export class EmailgroupService {
    constructor(private client: HttpClient) {}

    loadEmailgroupList(): Observable<Array<EmailGroup>> {
        return this.client.get<Array<EmailGroup>>('/api/emailgroups');
    }

    loadManufacturerContactsList(): Observable<Array<ManufacturerContact>> {
        return this.client.get<Array<ManufacturerContact>>(
            '/api/manufacturers/contacts'
        );
    }

    loadInternalContactsList(): Observable<Array<ManufacturerContact>> {
        return this.client.get<Array<ManufacturerContact>>(
            '/api/internal/contacts'
        );
    }

    SaveEmailgroup(emailgroup: EmailGroup): Observable<any> {
        return this.client.post<any>('/api/emailgroups', emailgroup);
    }
    EditEmailgroup(emailgroup: EmailGroup): Observable<any> {
        return this.client.put<any>('/api/emailgroups', emailgroup);
    }
}
