import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Schedule } from '../../../model/Schedule';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { clientSession } from '../../../client-session';
import {
    MatSnackBar,
    MatSnackBarVerticalPosition,
    MatSnackBarHorizontalPosition,
} from '@angular/material/snack-bar';

interface Period {
    value: string;
    viewValue: string;
}

interface Weekday {
    value: string;
    viewValue: string;
}

interface Month {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-schedule-dialog',
    templateUrl: './schedule-dialog.component.html',
    styleUrls: ['./schedule-dialog.component.scss'],
})
export class ScheduleDialogComponent {
    public formGroup: FormGroup;
    public monthFormControl;
    public monthlymonthFormControl;
    public dayFormControl;
    public periodFormControl;
    public weekdayFormControl;
    public isActiveFormControl;
    isEdit: boolean = false;
    id: string = '';
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    period: Period[] = [
        { value: 'Weekly', viewValue: 'Once a Week' },
        { value: 'Monthly', viewValue: 'Once a Month' },
        { value: 'Quarterly', viewValue: 'Once a Quarter' },
        { value: 'Yearly', viewValue: 'Once a Year' },
    ];

    weekday: Weekday[] = [
        { value: '1', viewValue: 'Sunday' },
        { value: '2', viewValue: 'Monday' },
        { value: '3', viewValue: 'Tuesday' },
        { value: '4', viewValue: 'Wednesday' },
        { value: '5', viewValue: 'Thursday' },
        { value: '6', viewValue: 'Friday' },
        { value: '7', viewValue: 'Saturday' },
    ];

    month: Month[] = [
        { value: '1', viewValue: '1' },
        { value: '2', viewValue: '2' },
        { value: '3', viewValue: '3' },
    ];

    monthlymonth: Month[] = [
        { value: '1', viewValue: '01 - January' },
        { value: '2', viewValue: '02 - February' },
        { value: '3', viewValue: '03 - March' },
        { value: '4', viewValue: '04 - April' },
        { value: '5', viewValue: '05 - May' },
        { value: '6', viewValue: '06 - June' },
        { value: '7', viewValue: '07 - July' },
        { value: '8', viewValue: '08 - August' },
        { value: '9', viewValue: '09 - September' },
        { value: '10', viewValue: '10 - October' },
        { value: '11', viewValue: '11 - November' },
        { value: '12', viewValue: '12 - December' },
    ];

    constructor(
        private http: HttpClient,
        private dialogRef: MatDialogRef<ScheduleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: Schedule,
        private _snackBar: MatSnackBar
    ) {
        if (data) {
            this.isEdit = true;
            this.id = data.id;
            //this.objEditSchedule = data;
            if (data.period === 'Once a Year') {
                data.periodViewvalue = 'Yearly';
            } else if (data.period === 'Once a Week') {
                data.periodViewvalue = 'Weekly';
            } else if (data.period === 'Once a Quarter') {
                data.periodViewvalue = 'Quarterly';
            } else if (data.period === 'Once a Month') {
                data.periodViewvalue = 'Monthly';
            }
        }

        this.monthFormControl = new FormControl('', [Validators.required]);
        this.monthlymonthFormControl = new FormControl('', [
            Validators.required,
        ]);
        if (
            this.isEdit == true &&
            data != null &&
            data.periodViewvalue == 'Yearly'
        ) {
            this.monthlymonthFormControl = new FormControl(
                data?.month?.toString() ?? '',
                [Validators.required]
            );
        } else if (
            this.isEdit == true &&
            data != null &&
            data.periodViewvalue == 'Quarterly'
        ) {
            this.monthFormControl = new FormControl(
                data?.month?.toString() ?? '',
                [Validators.required]
            );
        }

        this.dayFormControl = new FormControl(data?.day ?? '', [
            Validators.required,
            Validators.min(1),
            Validators.max(31),
        ]);

        this.periodFormControl = new FormControl(data?.periodViewvalue ?? '', [
            Validators.required,
        ]);

        this.weekdayFormControl = new FormControl(
            this.weekday.find(day => day.viewValue === data?.dayOfWeek)
                ?.value ?? '',
            [Validators.required]
        );
        this.isActiveFormControl = new FormControl(data?.active ?? true);

        this.formGroup = new FormGroup({
            month: this.monthFormControl,
            monthlymonth: this.monthlymonthFormControl,
            day: this.dayFormControl,
            period: this.periodFormControl,
            weekday: this.weekdayFormControl,
            isactive: this.isActiveFormControl,
        });
    }

    isAddOrEdit() {
        return this.isEdit ? 'Edit' : 'Add';
    }

    onSubmit() {
        var month = this.monthFormControl.value;
        var day = this.dayFormControl.value;
        var period = this.periodFormControl.value;
        var wday = this.weekdayFormControl.value;
        //this.periodFormControl.remove
        if (period == 'Quarterly') {
            this.formGroup.removeControl('weekday');
            this.formGroup.removeControl('monthlymonth');
            wday = null;
        } else if (period == 'Yearly') {
            this.formGroup.removeControl('weekday');
            this.formGroup.removeControl('month');
            month = this.monthlymonthFormControl.value;
            wday = null;
        } else if (period == 'Weekly') {
            this.formGroup.removeControl('month');
            this.formGroup.removeControl('day');
            this.formGroup.removeControl('monthlymonth');
            month = null;
            day = null;
        } else if (period == 'Monthly') {
            this.formGroup.removeControl('month');
            this.formGroup.removeControl('weekday');
            this.formGroup.removeControl('monthlymonth');
            month = null;
            wday = null;
        }

        if (this.formGroup.invalid) {
            this.formGroup.addControl('month', this.monthFormControl);
            this.formGroup.addControl('weekday', this.weekdayFormControl);
            this.formGroup.addControl('day', this.dayFormControl);
            this.formGroup.addControl(
                'monthlymonth',
                this.monthlymonthFormControl
            );
            return;
        }
        if (this.isEdit) {
            this.http
                .put<any>('/api/schedules', {
                    Id: this.id,
                    month: month,
                    day: day,
                    period: this.periodFormControl.value,
                    DayOfWeek: wday,
                    Active: this.isActiveFormControl.value,
                    UpdateUserId: clientSession.linkUser?.id,
                })
                .pipe(catchError(this.handleError))
                .subscribe(result =>
                    result.status === 'notUnique'
                        ? this.openSnackBar('Data should be Unique')
                        : this.dialogRef.close(true)
                );
        } else {
            this.http
                .post<any>('/api/schedules', {
                    month: month,
                    day: day,
                    period: this.periodFormControl.value,
                    DayOfWeek: wday,
                    Active: this.isActiveFormControl.value,
                    UpdateUserId: clientSession.linkUser?.id,
                })
                .pipe(catchError(this.handleError))
                .subscribe(result =>
                    result.status === 'notUnique'
                        ? this.openSnackBar('Data should be Unique')
                        : this.dialogRef.close(true)
                );
        }
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }
}
