<div style="margin: 10px">
    <p>{{ isAddOrEdit() }} Dataset</p>
</div>
<div style="display: grid; height: 90%; margin: 10px">
    <mat-form-field class="half-width" appearance="fill">
        <mat-label>DataSet</mat-label>
        <input
            type="text"
            matInput
            [formControl]="dataSetFormControl"
            maxlength="255" />
    </mat-form-field>
    <mat-error
        class="mat-errorField"
        *ngIf="dataSetFormControl.hasError('required') && submitted">
        Dataset is <strong>required</strong>
    </mat-error>

    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Columns</mat-label>
        <textarea matInput rows="3" [formControl]="columnsFormControl">
        </textarea>
    </mat-form-field>
    <mat-error
        class="mat-errorField"
        *ngIf="columnsFormControl.hasError('required') && submitted">
        Columns is <strong>required</strong>
    </mat-error>

    <mat-form-field class="full-width" appearance="fill">
        <mat-label>SQL</mat-label>
        <textarea matInput rows="6" [formControl]="sqlFormControl"> </textarea>
    </mat-form-field>
    <mat-error
        class="mat-errorField"
        *ngIf="sqlFormControl.hasError('required') && submitted">
        SQL is <strong>required</strong>
    </mat-error>

    <div style="display: flex">
        <mat-form-field class="half-width" appearance="fill">
            <mat-label>Version</mat-label>
            <input
                type="text"
                matInput
                [formControl]="versionFormControl"
                maxlength="255" />
        </mat-form-field>

        <mat-checkbox
            class="checkboxstyling"
            [formControl]="isActiveFormControl">
            Active
        </mat-checkbox>
    </div>
    <mat-error
        class="mat-errorField"
        *ngIf="versionFormControl.hasError('required') && submitted">
        Version is <strong>required</strong>
    </mat-error>

    <mat-dialog-actions align="center">
        <button color="primary" mat-raised-button mat-dialog-close>
            Cancel
        </button>
        <button
            color="primary"
            mat-raised-button
            (click)="save()"
            [disabled]="readonlyDataset">
            Save
        </button>
    </mat-dialog-actions>
</div>
