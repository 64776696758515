import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EmailGroup } from '../../../model/EmailGroup';
import { EmailgroupService } from '../../../services/emailgroup.service';
import { TableUtil } from '../../../utilities/TableUtil';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-emailgroup-list',
    templateUrl: './emailgroup-list.component.html',
    styleUrls: ['./emailgroup-list.component.scss'],
})
export class EmailgroupListComponent implements OnInit, AfterViewInit {
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    emailGroup: Array<EmailGroup> = [];
    panelOpenState = false;
    manufacturerNameFilter = new FormControl();
    emailGroupNameFilter = new FormControl();
    dataSource: MatTableDataSource<EmailGroup>;
    displayedColumns: string[] = [
        'groupName',
        'manufacturerName',
        'totalContacts',
        'updateDate',
        'active',
        'actionsColumn',
    ];
    filteredValues = {
        manufacturerName: '',
        groupName: '',
    };

    constructor(
        private router: Router,
        private emailgroupservice: EmailgroupService,
        private dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource(this.emailGroup);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    ngOnInit(): void {
        this.loadEmailgroupList();
        this.panelOpenState = true;
        this.manufacturerNameFilter.valueChanges.subscribe(
            manufacturerFilterValue => {
                this.filteredValues['manufacturerName'] =
                    manufacturerFilterValue;
                this.dataSource.filter = JSON.stringify(this.filteredValues);
            }
        );
        this.emailGroupNameFilter.valueChanges.subscribe(
            emailGroupFilterValue => {
                this.filteredValues['groupName'] = emailGroupFilterValue;
                this.dataSource.filter = JSON.stringify(this.filteredValues);
            }
        );

        this.dataSource.filterPredicate = this.customFilterPredicate();
    }

    customFilterPredicate() {
        const myFilterPredicate = function (
            data: EmailGroup,
            filter: string
        ): boolean {
            let searchString = JSON.parse(filter);

            return (
                data.groupName
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(searchString.groupName.toLowerCase()) !== -1 &&
                data.manufacturerName
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(searchString.manufacturerName.toLowerCase()) !== -1
            );
            // }
        };
        return myFilterPredicate;
    }

    loadEmailgroupList() {
        this.emailgroupservice.loadEmailgroupList().subscribe(result => {
            this.emailGroup = result;
            for (var i = 0; i < this.emailGroup.length; i++) {
                this.emailGroup[i].totalContacts =
                    this.emailGroup[i].emails.length;
            }

            this.dataSource = new MatTableDataSource(this.emailGroup);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.customFilterPredicate();
        });
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = this.customFilterPredicate();
    }

    newemailgroup = () => {
        this.router.navigateByUrl('/emailgroup');
    };

    deleteEmailGroup(rowid: any) {
        const userDialogConfig = new MatDialogConfig();

        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = {
            data: {
                message: 'Are you sure want to delete?',
                rowid: rowid,
                source: 'EmailGroup',
                buttonText: {
                    ok: 'Delete',
                    cancel: 'Cancel',
                },
            },
        };

        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.loadEmailgroupList();
                }
            });
    }
    getEmailGroup(data: any) {
        this.router.navigate(['/emailgroup'], { state: { editData: data } });
    }
    btnExport() {
        TableUtil.exportArray(this.emailGroup, 'Email Groups');
    }

    resetFilters() {
        this.filteredValues = {
            manufacturerName: '',
            groupName: '',
        };

        this.dataSource.filter = '';
        this.manufacturerNameFilter.setValue('');
        this.emailGroupNameFilter.setValue('');
    }
}
