import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailTemplate } from '../model/EmailTemplate';

@Injectable({
    providedIn: 'root',
})
export class EmailtemplateService {
    constructor(private client: HttpClient) {}

    loadEmailtemplateList(): Observable<Array<EmailTemplate>> {
        return this.client.get<Array<EmailTemplate>>('/api/emailtemplates');
    }

    SaveEmailTemplate(emailtemplate: EmailTemplate): Observable<any> {
        return this.client.post<any>('/api/emailtemplates', emailtemplate);
    }

    EditEmailTemplate(emailtemplate: EmailTemplate): Observable<any> {
        return this.client.put<any>('/api/emailtemplates', emailtemplate);
    }
}
