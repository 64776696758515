import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { clientSession } from '../../../client-session';
import { EmailTemplate } from '../../../model/EmailTemplate';
import { EmailtemplateService } from '../../../services/emailtemplate.service';

@Component({
    selector: 'app-emailtemplate',
    templateUrl: './emailtemplate.component.html',
    styleUrls: ['./emailtemplate.component.scss'],
})
export class EmailtemplateComponent implements OnInit {
    public formGroup: FormGroup;
    public emailTempNameFormControl: FormControl;
    public subjectLineFormControl: FormControl;
    public templateFormControl: FormControl;
    public isActiveFormControl: FormControl;

    objEmailTemplate: EmailTemplate = {} as EmailTemplate;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    isEdit: boolean = false;
    data: any;
    submitted: boolean = false;

    constructor(
        private router: Router,
        private emailtemplateservice: EmailtemplateService,
        private snackBar: MatSnackBar
    ) {
        var mode = this.router.getCurrentNavigation()?.extras.state;

        if (mode != null) {
            this.data = mode['viewMode'];

            this.isEdit = true;
        }
        this.emailTempNameFormControl = new FormControl('', [
            Validators.required,
            Validators.maxLength(512),
        ]);
        this.subjectLineFormControl = new FormControl('', [
            Validators.required,
            Validators.maxLength(512),
        ]);
        this.templateFormControl = new FormControl('', [Validators.required]);

        this.isActiveFormControl = new FormControl('' ?? false);

        this.formGroup = new FormGroup({
            emailTemplateName: this.emailTempNameFormControl,
            subjectLine: this.subjectLineFormControl,
            templateFormControl: this.templateFormControl,
            activeFormControl: this.isActiveFormControl,
        });
    }

    ngOnInit(): void {
        if (this.isEdit) {
            this.emailTempNameFormControl.setValue(this.data.templateName);
            this.subjectLineFormControl.setValue(this.data.subjectline);
            this.templateFormControl.setValue(this.data.template);
            this.isActiveFormControl.setValue(this.data.active);
        } else {
            this.isActiveFormControl.setValue(true);
        }
    }

    cancel() {
        this.router.navigateByUrl('/emailtemplatelist');
    }
    save() {
        this.submitted = true;
        if (this.formGroup.invalid) {
            return;
        }

        this.objEmailTemplate.templateName =
            this.emailTempNameFormControl.value;
        this.objEmailTemplate.subjectline = this.subjectLineFormControl.value;
        this.objEmailTemplate.template = this.templateFormControl.value;
        this.objEmailTemplate.active = this.isActiveFormControl.value;
        this.objEmailTemplate.updateUserId = this.getLoginUserId();
        if (this.isActiveFormControl.value == '') {
            this.objEmailTemplate.active = false;
        }

        if (this.isEdit) {
            this.objEmailTemplate.id = this.data.id;
            this.emailtemplateservice
                .EditEmailTemplate(this.objEmailTemplate)
                .pipe(catchError(this.handleError))
                .subscribe(
                    result => {
                        if (result != undefined) {
                            if (result.status === 'notUnique') {
                                this.openSnackBar('Email Template notUnique.');
                            } else {
                                this.openSnackBar(
                                    'Email Template updated successfully'
                                );
                                this.router.navigateByUrl('/emailtemplatelist');
                            }
                        }
                    },
                    err => {
                        this.openSnackBar(err);
                    }
                );
        } else {
            this.emailtemplateservice
                .SaveEmailTemplate(this.objEmailTemplate)
                .pipe(catchError(this.handleError))
                .subscribe(
                    result => {
                        if (result != undefined) {
                            if (result.status === 'notUnique') {
                                this.openSnackBar('Email Template notUnique.');
                            } else {
                                this.openSnackBar(
                                    'Email Template saved successfully'
                                );
                                this.router.navigateByUrl('/emailtemplatelist');
                            }
                        }
                    },
                    err => {
                        this.openSnackBar(err);
                    }
                );
        }
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }
    isAddOrEdit() {
        return this.isEdit ? 'Edit' : 'Create';
    }

    getLoginUserId() {
        return clientSession.linkUser?.id || '';
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }
}
