import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx-js-style';
import { PermissionName, Permission } from '../model/UserPermission';

const getFileName = (name: string) => {
    // let timeSpan = new Date().toISOString();
    let sheetName = 'Sheet1';
    let fileName = 'GPO-Link - ' + `${name}`;

    return {
        sheetName,
        fileName,
    };
};
export class TableUtil {
    //Export the Array to Excel
    static exportArrayToExcel(arr: any[], name: string) {
        let { sheetName, fileName } = getFileName(name);
        var wb = XLSX.utils.book_new();

        var ws = XLSX.utils.json_to_sheet(arr);
        //Call the function to define the width and Wrap the columns
        TableUtil.autoFitColumns(ws);

        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    static exportArray(arr: any[], name: string) {
        if (name === 'Report Management') {
            let dataToExport = arr.map(x => ({
                //...x,
                Manufacturer: x.manufacturerName,
                'Product Name': x.productName,
                'Report Name': x.reportName,
                'Append to Name': x.appendToName,
                'Report Period': x.reportPeriod,
                'Report Type': x.reportType,
                GPO: x.gpo,
                Dataset: x.reportView.viewName,
                'Email Template':
                    x.reportEmailTemplate.emailTemplate.templateName,
                Schedules: TableUtil.getReportNestedObjectValues(
                    x.reportSchedules,
                    'reportSchedules'
                ),
                'Email Groups': TableUtil.getReportNestedObjectValues(
                    x.reportEmailGroups,
                    'reportEmailGroups'
                ),
                'Included Columns': TableUtil.getReportNestedObjectValues(
                    x.reportColumns,
                    'reportcolumn'
                ),
                'Configured Parameters': TableUtil.getReportNestedObjectValues(
                    x.reportParameters,
                    'reportparameter'
                ),

                // ReportParameter2: x.reportParameters.map((item: { rptparameter: ReportParameter }) => item?.rptparameter?.columnName + '\n\r' + item?.rptparameter?.operator).join(';'),
            }));

            TableUtil.exportArrayToExcel(dataToExport, name);
        } else if (name === 'Sort') {
            arr.forEach(item => {
                (item.agreementType =
                    item.agreementTypeCode!.agreementTypeDescription),
                    (item.periodProfile =
                        item.periodProfileCode!.periodProfileDescription),
                    (item.level = item.levelCode!.levelDescription);
            });
            let dataToExport = arr.map(x => ({
                ...x,
            }));
            dataToExport.forEach(v => {
                delete v.id;
                delete v.endDate;
                delete v.startDate;
                delete v.updateDate;
                delete v.updateUserId;
                delete v.quarter;
                delete v.agreementTypeCode;
                delete v.periodProfileCode;
                delete v.levelCode;
            });
            TableUtil.exportArrayToExcel(dataToExport, name);
        } else if (name === 'Schedules') {
            let dataToExport = arr.map(x => ({
                Period: x.period,
                'Month/ Day': x.dayOfWeek,
                Active: x.active,
                UpdateDate: new DatePipe('en-US').transform(
                    x.updateDate,
                    'MM/dd/yyyy'
                ),
            }));

            TableUtil.exportArrayToExcel(dataToExport, name);
        } else if (name === 'Scheduled Emails') {
            let dataToExport = arr.map(x => ({
                ManufacturerName: x.report.manufacturerName,
                ProductName: x.report.productName,
                Gpo: x.report.gpo,
                Dataset: x.report.reportView.viewName,
                ReportDate: new DatePipe('en-US').transform(
                    x.reportGenerationDate,
                    'MM/dd/yyyy h:mm a'
                ),
                SentDate: new DatePipe('en-US').transform(
                    x.emailSentDate,
                    'MM/dd/yyyy h:mm a'
                ),
                Error: x.error,
            }));

            TableUtil.exportArrayToExcel(dataToExport, name);
        } else if (name === 'Users') {
            let dataToExport = arr.map(x => ({
                AzureUsername: x.azureUsername,
                Email: x.email,
                FirstName: x.firstName,
                LastName: x.lastName,
                Status: x.Enabled,
                DataSetPermissions:
                    TableUtil.getuserPermissionNestedObjectValues(
                        x.userPermissions,
                        PermissionName.Datasets
                    ),
                UserPermissions: TableUtil.getuserPermissionNestedObjectValues(
                    x.userPermissions,
                    PermissionName.Users
                ),
            }));

            TableUtil.exportArrayToExcel(dataToExport, name);
        } else if (name === 'Email Groups') {
            let dataToExport = arr.map(x => ({
                GroupName: x.groupName,
                ManufacturerName: x.manufacturerName,
                Active: x.active,
                Contacts: TableUtil.getEmailGroupContactsNestedObjectValues(
                    x.emails
                ),
                TotalContacts: x.totalContacts,
                UpdateDate: new DatePipe('en-US').transform(
                    x.updateDate,
                    'MM/dd/yyyy'
                ),
            }));

            TableUtil.exportArrayToExcel(dataToExport, name);
        } else if (name === 'DataSet') {
            let dataToExport = arr.map(x => ({
                ViewName: x.viewName,
                Version: x.version,
                Active: x.active,
                UpdateDate: new DatePipe('en-US').transform(
                    x.updateDate,
                    'MM/dd/yyyy'
                ),
            }));

            TableUtil.exportArrayToExcel(dataToExport, name);
        } else {
            //generic function to delete unwanted columns
            arr.forEach(v => {
                delete v.id;
                delete v.updateUserId;
            });
            TableUtil.exportArrayToExcel(arr, name);
        }
    }

    //function to get format and return nested objects
    static getReportNestedObjectValues(
        arr: any[],
        nestedObjectName: string
    ): string {
        var objValue: string = ''; //ReportParameter
        // console.log(arr,nestedObjectName)
        if (nestedObjectName === 'reportparameter') {
            if (arr != null && arr != undefined && arr.length > 0) {
                arr = arr.filter(item => item.active == true);
                if (arr != null && arr != undefined && arr.length > 0) {
                    objValue = arr
                        .map(
                            item =>
                                item.columnName +
                                ' ' +
                                item.operator +
                                ' ' +
                                item.parameterValue
                        )
                        .join(',\n');
                }
            }
        } else if (nestedObjectName === 'reportcolumn') {
            if (arr != null && arr != undefined && arr.length > 0) {
                objValue = arr.map(item => item.columnName).join(',');
            }
        } else if (nestedObjectName === 'reportSchedules') {
            if (arr != null && arr != undefined && arr.length > 0) {
                objValue = arr.map(item => item.schedule.dayOfWeek).join(',\n');
            }
        } else if (nestedObjectName === 'reportEmailGroups') {
            if (arr != null && arr != undefined && arr.length > 0) {
                objValue = arr
                    .map(item => item.emailGroup.groupName)
                    .join('\n');
            }
        }

        return objValue;
    }

    static getEmailGroupContactsNestedObjectValues(arr: any[]): string {
        var objValue: string = '';
        if (arr != null && arr != undefined && arr.length > 0) {
            objValue = arr
                .map(
                    item =>
                        item.firstName +
                        ' ' +
                        item.lastName +
                        ' - ' +
                        item.emailAddress
                )
                .join(',\n');
        }
        return objValue;
    }

    static getuserPermissionNestedObjectValues(
        arr: any[],
        permissionName: string
    ): string {
        var objValue: string = '';
        if (arr != null && arr != undefined && arr.length > 0) {
            objValue =
                arr.find(item => item.permissionName == permissionName)
                    ?.permission ?? Permission.None;
        }
        return objValue;
    }

    //to adjust the column width and wrap the text according to data
    static autoFitColumns(worksheet: XLSX.WorkSheet): void {
        const [firstCol, lastCol] = worksheet['!ref']
            ?.replace(/\d/, '')
            .split(':')!;
        //console.log(lastCol);

        const numRegexp = new RegExp(/\d+$/g);
        if (firstCol != undefined && lastCol != undefined) {
            const firstColIndex = firstCol.charCodeAt(0),
                lastColIndex = lastCol.charCodeAt(0),
                rows = +numRegexp.exec(lastCol)![0];

            const objectMaxLength: XLSX.ColInfo[] = [];

            // Loop on columns
            for (
                let colIndex = firstColIndex;
                colIndex <= lastColIndex;
                colIndex++
            ) {
                const col = String.fromCharCode(colIndex);

                let maxCellLength = 0;

                // Loop on rows
                for (let row = 1; row <= rows; row++) {
                    if (
                        worksheet[`${col}${row}`].v !== undefined &&
                        worksheet[`${col}${row}`].v !== null
                    ) {
                        if (row == 1) {
                            //console.log(worksheet[`${col}${row}`].v);
                            //worksheet[`${col}${row}`].v = worksheet[`${col}${row}`].v.toUpperCase();
                            worksheet[`${col}${row}`].v =
                                worksheet[`${col}${row}`].v
                                    .substring(0, 1)
                                    .toUpperCase() +
                                worksheet[`${col}${row}`].v.substring(
                                    1,
                                    worksheet[`${col}${row}`].v.length
                                );
                        }
                        //worksheet[`${col}${row}`].wrapText = true;// [{ v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } }];
                        worksheet[`${col}${row}`].s = {
                            alignment: {
                                wrapText: '1', // any truthy value here
                            },
                        };
                        //const cellLength = worksheet[`${col}${row}`].v!.length + 1
                        //if (cellLength > maxCellLength) maxCellLength = cellLength
                    }
                }

                objectMaxLength.push({ width: 25 });
            }

            worksheet['!cols'] = objectMaxLength;
        }
    }
}
