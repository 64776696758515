<div style="height: 300px">
    <p *ngIf="!viewMode">
        {{ message }}
    </p>
    <div style="height: 200px; overflow-y: auto">
        <table
            mat-table
            style="width: 100%"
            align="center"
            [dataSource]="dataSource"
            class="mat-elevation-z8">
            <ng-container matColumnDef="manufacturerName">
                <th mat-header-cell *matHeaderCellDef>Manufacturer</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.manufacturerName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="productName">
                <th mat-header-cell *matHeaderCellDef>Drug Name</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.productName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="gpo">
                <th mat-header-cell *matHeaderCellDef>GPO</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.gpo }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reportType">
                <th mat-header-cell *matHeaderCellDef>Report Type</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.reportType }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reportPeriod">
                <th mat-header-cell *matHeaderCellDef>Report Period</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.reportPeriod }}
                </td>
            </ng-container>
            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <mat-dialog-actions align="center">
        <button
            color="primary"
            *ngIf="!viewMode"
            mat-raised-button
            (click)="delete()">
            Delete
        </button>
        <button
            color="primary"
            *ngIf="!viewMode"
            mat-raised-button
            mat-dialog-close>
            Cancel
        </button>
        <button
            color="primary"
            *ngIf="viewMode"
            mat-raised-button
            mat-dialog-close>
            Close
        </button>
    </mat-dialog-actions>
</div>
