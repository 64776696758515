import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportingServicesLog } from '../model/ReportingServicesLog';
import { LinkLog } from '../model/LinkLog';
import { PharmaReportJobStatusHeader } from '../model/PharmaReportJobStatus';
import { RosterJobStatusHeader } from '../model/RosterPublicationJobStatus';

@Injectable({
    providedIn: 'root',
})
export class AuditlogService {
    constructor(private client: HttpClient) {}

    //loadLinkLogList(): Observable<Array<LinkLog>> {
    //    return this.client.get<Array<LinkLog>>('/api/getAllLinklogs');
    //}

    loadLinkLogListByDate(selecteddate: Date): Observable<Array<LinkLog>> {
        return this.client.get<Array<LinkLog>>(
            `/api/getAllLinklogsByDate/${selecteddate}`
        );
    }

    loadLinkLogSourceList(): Observable<Array<string>> {
        return this.client.get<Array<string>>('/api/getAllLinklogsSource');
    }

    loadReportingServicesLogListByDate(
        selecteddate: Date
    ): Observable<Array<ReportingServicesLog>> {
        return this.client.get<Array<ReportingServicesLog>>(
            `/api/getAllReportingServicesLogsByDate/${selecteddate}`
        );
    }

    loadPharmaReportJobStatusByDate(
        selecteddate: Date
    ): Observable<Array<PharmaReportJobStatusHeader>> {
        return this.client.get<Array<PharmaReportJobStatusHeader>>(
            `/api/getAllPharmaReportJobStatusByDate/${selecteddate}`
        );
    }

    loadRosterPublicationStatusByDate(
        selecteddate: Date
    ): Observable<Array<RosterJobStatusHeader>> {
        return this.client.get<Array<RosterJobStatusHeader>>(
            `/api/getAllRosterPublicationsStatusByDate/${selecteddate}`
        );
    }

    loadRosterPublicationLogListByDate(
        selecteddate: Date
    ): Observable<Array<ReportingServicesLog>> {
        return this.client.get<Array<ReportingServicesLog>>(
            `/api/getAllRosterPublicationsLogsByDate/${selecteddate}`
        );
    }
}
