import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EmailTemplate } from '../../../model/EmailTemplate';
import { EmailtemplateService } from '../../../services/emailtemplate.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-emailtemplate-list',
    templateUrl: './emailtemplate-list.component.html',
    styleUrls: ['./emailtemplate-list.component.scss'],
})
export class EmailtemplateListComponent implements OnInit {
    emailtemplates: Array<EmailTemplate> = [];

    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    dataSource: MatTableDataSource<EmailTemplate>;
    displayedColumns: string[] = [
        'templateName',
        'subjectline',
        'updateDate',
        'active',
        'actionsColumn',
    ];

    constructor(
        private router: Router,
        private emailtemplateservice: EmailtemplateService,
        private dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource(this.emailtemplates);
    }

    //Routes the user to EmailTemplate Screen for adding new Emailtemplate
    newemail = () => {
        this.router.navigateByUrl('/emailtemplate');
    };

    ngOnInit(): void {
        this.loadEmailtemplateList();
    }

    //Loads the Template from the Database
    loadEmailtemplateList() {
        this.emailtemplateservice.loadEmailtemplateList().subscribe(result => {
            this.emailtemplates = result;
            this.dataSource = new MatTableDataSource(this.emailtemplates);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    //Opens the delete dialog
    deleteEmailDialog(rowid: string) {
        const userDialogConfig = new MatDialogConfig();

        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = {
            data: {
                message: 'Are you sure want to delete?',
                rowid: rowid,
                source: 'EmailTemplate',
                buttonText: {
                    ok: 'Delete',
                    cancel: 'Cancel',
                },
            },
        };

        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.loadEmailtemplateList();
                }
            });
    }

    //Routes the user to EmailTemplate Screen for editing
    getEmailTemplate(data: any) {
        this.router.navigate(['/emailtemplate'], { state: { viewMode: data } });
    }
}
