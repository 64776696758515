import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { clientSession } from '../../../client-session';
import { Permission, PermissionName } from '../../../model/UserPermission';
import { View } from '../../../model/View';
import { ReportService } from '../../../services/report.service';

@Component({
    selector: 'app-dataset-dialog',
    templateUrl: './dataset-dialog.component.html',
    styleUrls: ['./dataset-dialog.component.scss'],
})
export class DatasetDialogComponent {
    public formGroup: FormGroup;
    public dataSetFormControl: FormControl;
    public columnsFormControl: FormControl;
    public sqlFormControl: FormControl;
    public versionFormControl: FormControl;
    public isActiveFormControl: FormControl;

    objView: View = {} as View;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    isEdit: boolean = false;
    readonlyDataset: boolean = false;
    id: string = '';
    submitted: boolean = false;

    constructor(
        private router: Router,
        private reportservice: ReportService,
        private dialogRef: MatDialogRef<DatasetDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: View,
        private snackBar: MatSnackBar
    ) {
        if (data) {
            this.isEdit = true;
            this.id = data.id;
        }
        var userPermission = clientSession.userHasPermission(
            PermissionName.Datasets,
            Permission.All
        );
        if (userPermission === undefined) {
            this.readonlyDataset = true;
        }

        this.dataSetFormControl = new FormControl(data?.viewName ?? '', [
            Validators.required,
            Validators.maxLength(255),
        ]);
        this.columnsFormControl = new FormControl(data?.viewColumns ?? '', [
            Validators.required,
        ]);
        this.sqlFormControl = new FormControl(data?.sqlCode ?? '', [
            Validators.required,
        ]);
        this.versionFormControl = new FormControl(data?.version ?? '', [
            Validators.required,
            Validators.maxLength(255),
        ]);

        this.isActiveFormControl = new FormControl(data?.active ?? true);

        this.formGroup = new FormGroup({
            dataset: this.dataSetFormControl,
            columns: this.columnsFormControl,
            sqlCode: this.sqlFormControl,
            version: this.versionFormControl,
            activeFormControl: this.isActiveFormControl,
        });
    }

    save() {
        this.submitted = true;
        if (this.formGroup.invalid) {
            return;
        }
        if (this.readonlyDataset) {
            this.openSnackBar('Read only access.');
            return;
        }
        this.objView.viewName = this.dataSetFormControl.value.trim();
        this.objView.viewColumns = this.columnsFormControl.value;
        this.objView.sqlCode = this.sqlFormControl.value;
        this.objView.version = this.versionFormControl.value;

        this.objView.active = this.isActiveFormControl.value;
        this.objView.updateUserId = this.getLoginUserId();
        if (this.isEdit) {
            this.objView.id = this.id;
            this.reportservice
                .EditView(this.objView)
                .pipe(catchError(this.handleError))
                .subscribe(
                    result => {
                        if (result != undefined) {
                            if (result.status === 'Error: NotUnique') {
                                this.openSnackBar('Dataset is not unique.');
                            } else {
                                this.openSnackBar('Dataset saved successfully');
                                this.dialogRef.close(true);
                            }
                        }
                    },
                    err => {
                        this.openSnackBar(err);
                    }
                );
        } else {
            this.reportservice
                .SaveView(this.objView)
                .pipe(catchError(this.handleError))
                .subscribe(
                    result => {
                        if (result != undefined) {
                            if (result.status === 'Error: NotUnique') {
                                this.openSnackBar('Dataset is not unique.');
                            } else {
                                this.openSnackBar('Dataset saved successfully');
                                this.dialogRef.close(true);
                            }
                        }
                    },
                    err => {
                        this.openSnackBar(err);
                    }
                );
        }
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }

    isAddOrEdit() {
        return this.isEdit ? 'Edit' : 'Create';
    }

    getLoginUserId() {
        return clientSession.linkUser?.id || '';
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }
}
