// loader-interceptor.service.ts
import { Injectable } from '@angular/core';
import {
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    totalRequests = 0;
    requestsCompleted = 0;

    constructor(private loaderService: LoaderService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.loaderService.show();
        this.totalRequests++;

        return next.handle(req).pipe(
            finalize(() => {
                this.requestsCompleted++;

                if (this.requestsCompleted === this.totalRequests) {
                    this.loaderService.hide();
                    this.totalRequests = 0;
                    this.requestsCompleted = 0;
                }
            })
        );
    }
}
