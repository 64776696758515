<mat-dialog-content class="mat-dialog-style">
    <div class="user-form">
        <div class="fixedDivStyleDialog">
            <p>{{ isAddOrEdit() }} Note</p>
            <div style="display: flex">
                <div class="divWidth">
                    <mat-form-field appearance="fill" class="textboxstyling">
                        <mat-label>Subject</mat-label>
                        <input
                            type="text"
                            matInput
                            [formControl]="SubjectFormControl"
                            maxlength="255" />
                    </mat-form-field>

                    <mat-error
                        class="mat-errorField"
                        *ngIf="
                            SubjectFormControl.hasError('required') && submitted
                        ">
                        Subject is <strong>required</strong>
                    </mat-error>
                </div>
                <div align="left">
                    <mat-checkbox
                        class="checkboxstyling"
                        [formControl]="isActiveFormControl">
                        Active
                    </mat-checkbox>
                </div>
                <mat-dialog-actions align="end" class="buttonstyling">
                    <button color="primary" mat-raised-button mat-dialog-close>
                        Cancel
                    </button>
                    <button
                        color="primary"
                        mat-raised-button
                        (click)="onSubmit()">
                        Save
                    </button>
                </mat-dialog-actions>
            </div>
            <div style="display: flex">
                <div class="divWidth">
                    <mat-form-field appearance="fill" class="textboxstyling">
                        <mat-label>Note</mat-label>
                        <textarea
                            matInput
                            rows="6"
                            cols="50"
                            [formControl]="noteFormControl"
                            id="txtNote">
                        </textarea>
                    </mat-form-field>
                    <mat-error
                        *ngIf="
                            noteFormControl.hasError('required') && submitted
                        "
                        class="mat-errorField">
                        Note is required.
                    </mat-error>
                </div>

                <div style="width: 30%">
                    <div align="left" class="filterstyle">
                        <mat-form-field appearance="fill" style="width: 100%">
                            <mat-label>Please select an Option</mat-label>
                            <mat-select
                                [formControl]="agreementTypeFormControl"
                                multiple>
                                <mat-option
                                    *ngFor="let obj of agreementtype"
                                    [value]="obj.agreementType"
                                    (onSelectionChange)="
                                        changeAgreementType($event)
                                    ">
                                    {{ obj.agreementTypeDescription }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div align="left">
                        <mat-form-field appearance="fill" class="filterstyle">
                            <input
                                type="text"
                                [formControl]="inputFilterFormControl"
                                matInput
                                (keyup)="applyFilter($any($event.target).value)"
                                placeholder="Filter by AgreementId or AgreementName" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="mainDivStyleDialog">
            <div class="matselection">
                <mat-error
                    class="mat-errorField"
                    *ngIf="!selectedagreements.length && submitted">
                    <br />
                    Please add an <strong>Agreement</strong>
                </mat-error>
                <mat-table [dataSource]="dataSource" id="tblAgreements" #table>
                    <ng-container matColumnDef="agreementId">
                        <mat-header-cell *matHeaderCellDef>
                            Agreement Id
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.agreementId }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="agreementName">
                        <mat-header-cell *matHeaderCellDef>
                            Agreement Name
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ element.agreementName }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="agreementTypeDescription">
                        <mat-header-cell *matHeaderCellDef>
                            Agreement Type
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let element"
                            [ngStyle]="{
                                color: !element.agreementTypeDescription
                                    ? 'red'
                                    : 'black'
                            }">
                            {{ element.agreementTypeDescription ?? 'Expired' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="select">
                        <mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                                (change)="$event ? masterToggle($event) : null"
                                [checked]="
                                    selection.hasValue() && isAllSelected()
                                "
                                [indeterminate]="
                                    selection.hasValue() && !isAllSelected()
                                ">
                            </mat-checkbox>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (input)="moveToTop(row, $event)"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                (click)="$event.stopPropagation()">
                            </mat-checkbox>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row
                        *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="
                            let row;
                            columns: displayedColumns
                        "></mat-row>
                </mat-table>
            </div>
        </div>
    </div>
</mat-dialog-content>
