<mat-dialog-content style="overflow-y: hidden">
    <div class="user-form">
        <p>{{ isAddOrEdit() }} Schedule</p>
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Please select a Report Period</mat-label>
            <mat-select
                [formControl]="periodFormControl"
                name="period"
                required>
                <mat-option
                    *ngFor="let rptperiod of period"
                    [value]="rptperiod.value">
                    {{ rptperiod.viewValue }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="periodFormControl.value === 'Weekly'">
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Please select a Day</mat-label>
                <mat-select [formControl]="weekdayFormControl" name="weekday">
                    <mat-option *ngFor="let day of weekday" [value]="day.value">
                        {{ day.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="periodFormControl.value === 'Quarterly'">
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Please select a Month</mat-label>
                <mat-select [formControl]="monthFormControl" name="months">
                    <mat-option
                        *ngFor="let months of month"
                        [value]="months.value">
                        {{ months.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="periodFormControl.value === 'Yearly'">
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Please select a Month</mat-label>
                <mat-select
                    [formControl]="monthlymonthFormControl"
                    name="monthlymonths">
                    <mat-option
                        *ngFor="let months of monthlymonth"
                        [value]="months.value">
                        {{ months.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div
            *ngIf="
                periodFormControl.value === 'Monthly' ||
                periodFormControl.value === 'Yearly' ||
                periodFormControl.value === 'Quarterly'
            ">
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Please enter a Day</mat-label>
                <input
                    type="number"
                    matInput
                    maxlength="2"
                    max="31"
                    min="1"
                    [formControl]="dayFormControl" />

                <mat-error *ngIf="dayFormControl.hasError('required')">
                    Day is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="dayFormControl.hasError('min')">
                    Minimum value is <strong>1</strong>
                </mat-error>
                <mat-error *ngIf="dayFormControl.hasError('max')">
                    Maximum value is <strong>31</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="checkbox-group">
            <mat-checkbox [formControl]="isActiveFormControl"
                >Active</mat-checkbox
            >
        </div>
        <div style="margin-left: 70px">
            <mat-error *ngIf="periodFormControl.hasError('notUnique')">
                Data should be Unique
            </mat-error>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button color="primary" mat-raised-button mat-dialog-close>Cancel</button>
    <button color="primary" mat-raised-button (click)="onSubmit()">Save</button>
</mat-dialog-actions>
