<div align="right" *ngIf="showLogsDetails">
    <button
        inline="true"
        mat-icon-button
        aria-label="close dialog"
        mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <p>
        {{ message }}
    </p>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="!showLogsDetails">
    <button
        mat-raised-button
        color="primary"
        (click)="onConfirmClick()"
        tabindex="1">
        {{ confirmButtonText }}
    </button>
    <button color="primary" mat-raised-button mat-dialog-close tabindex="-1">
        {{ cancelButtonText }}
    </button>
</mat-dialog-actions>
