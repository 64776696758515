import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AgreementTypeCode } from '../model/AgreementTypeCode';
import { Note } from '../model/Note';
import { QuarterCode } from '../model/QuarterCode';
import { AgreementSort } from '../model/Sort';

@Injectable({
    providedIn: 'root',
})
export class ScorecardService {
    constructor(private client: HttpClient) {}

    loadNoteList(): Observable<Array<Note>> {
        return this.client.get<Array<Note>>('/api/scorecard/notes');
    }

    loadnotelistbyquarter(quarter: string): Observable<Array<Note>> {
        return this.client.get<Array<Note>>(
            `/api/scorecard/notes/quarters/${quarter}`
        );
    }

    loadQuarterCode(): Observable<Array<QuarterCode>> {
        return this.client.get<Array<QuarterCode>>(
            '/api/scorecard/quartercodes'
        );
    }

    loadagreementType(): Observable<Array<AgreementTypeCode>> {
        return this.client.get<Array<AgreementTypeCode>>(
            '/api/scorecard/agreementtypes'
        );
    }

    SaveNote(note: Note): Observable<any> {
        return this.client.post<any>('/api/scorecard/notes', note);
    }
    EditNote(note: Note): Observable<any> {
        return this.client.put<any>('/api/scorecard/notes', note);
    }

    SaveSort(sortdata: Array<AgreementSort>): Observable<any> {
        return this.client.post<any>('/api/scorecard/sort/quarters', sortdata);
    }

    loadSort(): Observable<Array<AgreementSort>> {
        return this.client.get<Array<AgreementSort>>('/api/scorecard/sort');
    }

    loadSortlistbyquarter(quarter: string): Observable<Array<AgreementSort>> {
        return this.client.get<Array<AgreementSort>>(
            `/api/scorecard/sort/quarters/${quarter}`
        );
    }

    generateExcelForNotesExport(quarter: string) {
        let headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        return this.client.get(
            `/api/scorecard/notes/downloadNotes/${quarter}`,
            {
                headers: headers,
                responseType: 'arraybuffer',
            }
        );
    }
}
