import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { clientSession } from '../../client-session';

@Injectable()
export class UserAuthenticatedGuardService implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (clientSession.linkUser && clientSession.linkUser.isEnabled) {
            return true;
        } else {
            this.router.navigate(['unauthorized']);
            return false;
        }
    }
}
