<div class="mainDivStyle" style="width: 90%; align-items: center">
    <mat-accordion style="width: 90%">
        <mat-expansion-panel
            [expanded]="panelOpenState"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            style="margin-left: 10px">
            <mat-expansion-panel-header>
                <mat-panel-title> Filters </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field class="matFilterField">
                <mat-label> Source</mat-label>
                <select
                    matNativeControl
                    name="Source"
                    [formControl]="sourceFilterFormControl"
                    (change)="filterChange($event)">
                    <option value="">-- Select Source --</option>
                    <option
                        [value]="item"
                        *ngFor="let item of sourceFilterValues">
                        {{ item }}
                    </option>
                </select>
            </mat-form-field>
            <mat-form-field style="padding-left: 10px">
                <mat-label>Choose a date</mat-label>
                <input
                    (dateInput)="OnDateChange($event.value)"
                    [formControl]="dateformcontrol"
                    matInput
                    [matDatepicker]="picker" />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div style="text-align: center">
                <button
                    color="primary"
                    mat-raised-button
                    (click)="resetFilters()">
                    Reset
                </button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <!--</div>
    
    <div class="flex-container-grid">-->

    <mat-table
        [dataSource]="dataSource"
        matSort
        class="mat-elevation-z8"
        style="width: 100%; margin-top: 10px; margin-left: 10px">
        <ng-container matColumnDef="source">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Source
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.source }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sourceName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ dynamicHeader }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.sourceName }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="message">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Message
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.message }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="data">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Data
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.data }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="exception">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Exception
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.exception }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="timestamp">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                Timestamp
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.timestamp | date : 'MM/dd/yyyy h:mm a' }}
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <mat-paginator
        style="width: 100%; margin-left: 10px"
        class="mat-elevation-z8"
        [pageSizeOptions]="[100, 150, 250, 500, 750, 1000]"
        aria-label="Select page of reports"></mat-paginator>
</div>
