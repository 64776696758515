import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { clientSession } from '../../../client-session';
import { Agreement } from '../../../model/Agreement';
import { AgreementTypeCode } from '../../../model/AgreementTypeCode';
import { Note, NoteAgreement } from '../../../model/Note';
import { QuarterCode } from '../../../model/QuarterCode';
import { ScorecardService } from '../../../services/scorecard.service';

@Component({
    selector: 'app-notes-dialog',
    templateUrl: './notes-dialog.component.html',
    styleUrls: ['./notes-dialog.component.scss'],
})
export class NotesDialogComponent implements OnInit {
    public SubjectFormControl: FormControl;
    public isActiveFormControl: FormControl;
    public noteFormControl: FormControl;
    public agreementTypeFormControl;
    public inputFilterFormControl;
    public formGroup: FormGroup;

    submitted: boolean = false;
    isEdit: boolean = false;
    agreementType: Array<String> = [];

    filteredagreements: Array<Agreement> | undefined = [];
    selectedagreementType: Array<String> = [];
    agreementtype: Array<AgreementTypeCode> = [];
    agreementList: Array<Agreement> | undefined;

    selectedagreements: Array<Agreement> = [];
    tempselectedagreements: Array<Agreement> = [];
    dataSource: MatTableDataSource<Agreement>;
    displayedColumns = [
        'select',
        'agreementId',
        'agreementName',
        'agreementTypeDescription',
    ];
    @ViewChild('table', { read: ElementRef })
    table!: ElementRef;

    selection = new SelectionModel<Agreement>(true, []);
    SaveNote: Note = {} as Note;
    selectedQuarter: string;
    selectedQuarterCode: QuarterCode | undefined;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    id: string = '';
    constructor(
        private scorecardservice: ScorecardService,
        private dialogRef: MatDialogRef<NotesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private router: Router,
        private snackBar: MatSnackBar,
        private http: HttpClient
    ) {
        this.selectedQuarter = '';
        this.id = '';
        if (data) {
            if (data.SelectedNote != undefined) {
                this.id = data.SelectedNote.id;
            }

            this.agreementList = data.AgreementList;
            this.selectedQuarterCode = data.SelectedQuarter;
            if (this.selectedQuarterCode != undefined) {
                this.selectedQuarter = this.selectedQuarterCode?.quarter;
            }
        }
        if (data && this.id != '') {
            this.isEdit = true;

            if (
                data.SelectedNote.noteAgreements != null &&
                data.SelectedNote.noteAgreements.length > 0
            ) {
                this.data.SelectedNote.noteAgreements.forEach(
                    (objAgreement: NoteAgreement) => {
                        var agradd: Agreement = {} as Agreement;
                        agradd.agreementId = objAgreement.agreementId;
                        agradd.agreementName = objAgreement.agreementName;
                        agradd.agreementType = objAgreement.agreementType;
                        this.selectedagreements.push(agradd);
                    }
                );
            }
        }

        this.SubjectFormControl = new FormControl(
            data?.SelectedNote?.subject ?? '',
            [Validators.required, Validators.maxLength(255)]
        );
        this.isActiveFormControl = new FormControl(
            data?.SelectedNote?.active ?? true
        );
        this.noteFormControl = new FormControl(
            data?.SelectedNote?.noteText ?? '',
            [Validators.required]
        );

        this.agreementTypeFormControl = new FormControl(this.agreementType);
        this.inputFilterFormControl = new FormControl();
        this.dataSource = new MatTableDataSource(this.agreementList);
        this.dataSource.filterPredicate = (
            data: Agreement,
            filterValue: string
        ) =>
            data.agreementId.trim().toLowerCase().indexOf(filterValue) !== -1 ||
            data.agreementName.trim().toLowerCase().indexOf(filterValue) !== -1;
        this.formGroup = new FormGroup({
            subject: this.SubjectFormControl,
            notetext: this.noteFormControl,
        });
    }

    ngOnInit(): void {
        this.loadAgreementList();
        this.loadInitialDropdown();
        this.dataSource = new MatTableDataSource(this.agreementList);
        this.dataSource.filterPredicate = (
            data: Agreement,
            filterValue: string
        ) =>
            data.agreementId.trim().toLowerCase().indexOf(filterValue) !== -1 ||
            data.agreementName.trim().toLowerCase().indexOf(filterValue) !== -1;
    }
    //Saves the note to the database
    onSubmit() {
        this.submitted = true;
        if (this.selectedagreements.length <= 0) {
            return;
        } else if (this.formGroup.invalid) {
            return;
        }
        this.SaveNote.quarter = this.selectedQuarter;
        this.SaveNote.subject = this.SubjectFormControl.value;
        this.SaveNote.noteText = this.noteFormControl.value;
        this.SaveNote.updateUserId = this.getLoginUserId();
        this.SaveNote.updateDate = new Date();
        this.SaveNote.active = this.isActiveFormControl.value;
        if (this.isActiveFormControl.value === '') {
            this.SaveNote.active = false;
        }
        const agreement: Array<NoteAgreement> = [];
        this.selectedagreements.forEach((objAgreement: Agreement) => {
            agreement.push({
                agreementId: objAgreement.agreementId,
                agreementName: objAgreement.agreementName,
                agreementType: objAgreement.agreementType,
                updateDate: new Date(),
                updateUserId: this.SaveNote.updateUserId,
            });
        });
        this.SaveNote.noteAgreements = agreement;
        if (this.isEdit) {
            this.SaveNote.id = this.id;
            this.scorecardservice
                .EditNote(this.SaveNote)
                .pipe(catchError(this.handleError))
                .subscribe(
                    result => {
                        if (result != undefined) {
                            if (result.status === 'notUnique') {
                                this.openSnackBar('Notes notUnique.');
                            } else {
                                this.openSnackBar('Notes updated successfully');

                                this.dialogRef.close(true);
                            }
                        }
                    },
                    err => {
                        this.openSnackBar(err);
                    }
                );
        } else {
            this.scorecardservice
                .SaveNote(this.SaveNote)
                .pipe(catchError(this.handleError))
                .subscribe(
                    result => {
                        if (result != undefined) {
                            if (result.status === 'notUnique') {
                                this.openSnackBar('Notes notUnique.');
                            } else {
                                this.openSnackBar('Notes saved successfully');

                                this.dialogRef.close(true);
                            }
                        }
                    },
                    err => {
                        this.openSnackBar(err);
                    }
                );
        }
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }

    getLoginUserId() {
        return clientSession.linkUser?.id || '';
    }

    isAddOrEdit() {
        return this.isEdit ? 'Edit' : 'Add';
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches

        //this.filterednotes = this.notes.filter(p => p.subject.toLowerCase().includes(filterValue) || p.agreements.some(agr => agr.agreementName.toLowerCase().includes(filterValue)) || p.agreements.some(agr => agr.agreementId.toLowerCase().includes(filterValue)));

        this.dataSource.filter = filterValue;
    }
    //Move the selected agreements to the top of the table during selection
    moveToTop(ev: any, event: any) {
        if (event.target.checked === true) {
            this.selectedagreements.push(ev);
            const selctedagreement = this.agreementList?.filter(
                r =>
                    parseInt(r.agreementId) === parseInt(ev.agreementId) &&
                    r.agreementType.trim().toLowerCase() ===
                        ev.agreementType.trim().toLowerCase() &&
                    r.agreementName.trim().toLowerCase() ===
                        ev.agreementName.trim().toLowerCase()
            );

            if (
                this.selectedagreementType !== undefined &&
                this.selectedagreementType.length > 0
            ) {
                for (var i = 0; i < this.selectedagreements.length; i++) {
                    var indexSelected = this.filteredagreements?.findIndex(
                        row =>
                            parseInt(row.agreementId) ===
                                parseInt(
                                    this.selectedagreements[i].agreementId
                                ) &&
                            row.agreementType.trim().toLowerCase() ===
                                this.selectedagreements[i].agreementType
                                    .trim()
                                    .toLowerCase() &&
                            row.agreementName.trim().toLowerCase() ===
                                this.selectedagreements[i].agreementName
                                    .trim()
                                    .toLowerCase()
                    );
                    if (indexSelected != undefined && indexSelected > -1) {
                        this.filteredagreements?.splice(indexSelected, 1);
                    }
                }

                //this.filteredagreements = this.filteredagreements?.filter(r => r.agreementId !== ev.agreementId);
                if (this.filteredagreements != null) {
                    this.filteredagreements = this.selectedagreements?.concat(
                        this.filteredagreements
                    );
                }

                this.dataSource = new MatTableDataSource(
                    this.filteredagreements
                );
                this.dataSource.filter = this.inputFilterFormControl.value;
            } else {
                var indexSelect = this.agreementList?.findIndex(
                    row =>
                        parseInt(row.agreementId) ===
                            parseInt(ev.agreementId) &&
                        row.agreementType.trim().toLowerCase() ===
                            ev.agreementType.trim().toLowerCase() &&
                        row.agreementName.trim().toLowerCase() ===
                            ev.agreementName.trim().toLowerCase()
                );
                if (indexSelect != undefined && indexSelect > -1) {
                    this.agreementList?.splice(indexSelect, 1);
                }

                if (this.agreementList != null) {
                    this.agreementList = selctedagreement?.concat(
                        this.agreementList
                    );
                }

                this.dataSource = new MatTableDataSource(this.agreementList);
                this.dataSource.filter = this.inputFilterFormControl.value;
            }
        } else {
            var index = this.selectedagreements.findIndex(
                row =>
                    parseInt(row.agreementId) == parseInt(ev.agreementId) &&
                    row.agreementType.trim().toLowerCase() ===
                        ev.agreementType.trim().toLowerCase() &&
                    row.agreementName.trim().toLowerCase() ===
                        ev.agreementName.trim().toLowerCase()
            );
            if (index > -1) {
                this.selectedagreements.splice(index, 1);

                const selctedAgr = this.filteredagreements?.filter(
                    r =>
                        r.agreementId === ev.agreementId &&
                        r.agreementType.trim().toLowerCase() ===
                            ev.agreementType.trim().toLowerCase() &&
                        r.agreementName.trim().toLowerCase() ===
                            ev.agreementName.trim().toLowerCase()
                );

                var indexRemove = this.filteredagreements?.findIndex(
                    row =>
                        parseInt(row.agreementId) == parseInt(ev.agreementId) &&
                        row.agreementType.trim().toLowerCase() ===
                            ev.agreementType.trim().toLowerCase() &&
                        row.agreementName.trim().toLowerCase() ===
                            ev.agreementName.trim().toLowerCase()
                );
                if (indexRemove != undefined && indexRemove > -1) {
                    this.filteredagreements?.splice(index, 1);
                }

                if (selctedAgr != null) {
                    //this.filteredagreements = this.filteredagreements?.concat(selctedagreement);
                    this.filteredagreements = this.filteredagreements
                        ?.slice(0, this.selectedagreements.length)
                        .concat(selctedAgr)
                        .concat(
                            this.filteredagreements?.slice(
                                this.selectedagreements.length,
                                this.filteredagreements.length
                            )
                        );
                }

                this.dataSource = new MatTableDataSource(
                    this.filteredagreements
                );

                if (this.inputFilterFormControl.value != null) {
                    this.dataSource.filter = this.inputFilterFormControl.value;
                }
            }
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }
    masterToggle(event: any) {
        this.selection.clear();
        this.selectedagreements = [];

        if (event.checked === true) {
            this.dataSource.data.forEach(row => this.selection.select(row));
            this.dataSource.data.forEach(row =>
                this.selectedagreements.push(row)
            );
        }
    }

    loadInitialDropdown() {
        this.loadagreementType();
    }
    //Loads the Agreement Types in the dropdown
    loadagreementType() {
        this.scorecardservice.loadagreementType().subscribe(result => {
            this.agreementtype = result;
            for (var i = 0; i < result.length; i++) {
                this.agreementType.push(result[i].agreementType);
                this.selectedagreementType.push(result[i].agreementType);
            }
        });
    }
    //Loads the Agreement from the EAP
    async loadAgreementList() {
        //console.log( this.agreementList?.length);
        this.dataSource = new MatTableDataSource(this.agreementList);

        this.filteredagreements = this.agreementList;

        if (this.isEdit) {
            this.syncAgreementlist();
        }
    }
    //Handles the dropdown selection change event
    changeAgreementType(event: any) {
        if (event.isUserInput) {
            this.filteredagreements = [];
            this.tempselectedagreements = [];
            if (event.source.selected) {
                if (
                    this.selectedagreementType.indexOf(event.source.value) ===
                    -1
                ) {
                    this.selectedagreementType.push(event.source.value);
                }
            } else {
                if (
                    this.selectedagreementType.indexOf(event.source.value) >= 0
                ) {
                    this.selectedagreementType.splice(
                        this.selectedagreementType.indexOf(event.source.value),
                        1
                    );
                }
            }
            if (
                this.selectedagreementType !== undefined &&
                this.selectedagreementType.length > 0
            ) {
                for (var i = 0; i < this.selectedagreementType.length; i++) {
                    const filteragr = this.agreementList?.filter(
                        agr =>
                            agr.agreementType === this.selectedagreementType[i]
                    );
                    if (filteragr != null && this.filteredagreements != null) {
                        this.filteredagreements =
                            this.filteredagreements.concat(filteragr);
                    }
                }
                if (
                    this.selectedagreements != null &&
                    this.selectedagreements.length > 0
                ) {
                    for (var i = 0; i < this.selectedagreements.length; i++) {
                        var index = this.filteredagreements.findIndex(
                            x =>
                                parseInt(x.agreementId) ===
                                parseInt(this.selectedagreements[i].agreementId)
                        );
                        if (index > -1) {
                            this.tempselectedagreements.push(
                                this.filteredagreements[index]
                            );
                            this.filteredagreements.splice(index, 1);
                        }
                    }
                }
                if (
                    this.tempselectedagreements != null &&
                    this.tempselectedagreements.length > 0
                ) {
                    this.filteredagreements =
                        this.tempselectedagreements.concat(
                            this.filteredagreements
                        );
                }
                this.dataSource = new MatTableDataSource(
                    this.filteredagreements
                );

                if (this.inputFilterFormControl.value != null) {
                    this.applyFilter(this.inputFilterFormControl.value);
                    this.dataSource.filterPredicate = (
                        data: Agreement,
                        filterValue: string
                    ) =>
                        data.agreementId
                            .trim()
                            .toLowerCase()
                            .indexOf(filterValue) !== -1 ||
                        data.agreementName
                            .trim()
                            .toLowerCase()
                            .indexOf(filterValue) !== -1;
                }
            } else if (this.selectedagreementType.length == 0) {
                this.dataSource = new MatTableDataSource(
                    this.filteredagreements
                );

                if (this.inputFilterFormControl.value != null) {
                    this.applyFilter(this.inputFilterFormControl.value);
                    this.dataSource.filterPredicate = (
                        data: Agreement,
                        filterValue: string
                    ) =>
                        data.agreementId
                            .trim()
                            .toLowerCase()
                            .indexOf(filterValue) !== -1 ||
                        data.agreementName
                            .trim()
                            .toLowerCase()
                            .indexOf(filterValue) !== -1;
                }
            } else {
                if (
                    this.selectedagreements != null &&
                    this.selectedagreements.length > 0 &&
                    this.agreementList != null &&
                    this.agreementList.length > 0
                ) {
                    for (var i = 0; i < this.selectedagreements.length; i++) {
                        var index = this.agreementList.findIndex(
                            x =>
                                parseInt(x.agreementId) ===
                                parseInt(this.selectedagreements[i].agreementId)
                        );
                        if (index > -1) {
                            this.tempselectedagreements.push(
                                this.agreementList[index]
                            );
                            this.agreementList.splice(index, 1);
                        }
                    }
                }
                if (
                    this.tempselectedagreements != null &&
                    this.tempselectedagreements.length > 0 &&
                    this.agreementList != null &&
                    this.agreementList.length > 0
                ) {
                    this.agreementList = this.tempselectedagreements.concat(
                        this.agreementList
                    );
                }

                this.dataSource = new MatTableDataSource(this.agreementList);

                if (this.inputFilterFormControl.value != null) {
                    this.applyFilter(this.inputFilterFormControl.value);
                    this.dataSource.filterPredicate = (
                        data: Agreement,
                        filterValue: string
                    ) =>
                        data.agreementId
                            .trim()
                            .toLowerCase()
                            .indexOf(filterValue) !== -1 ||
                        data.agreementName
                            .trim()
                            .toLowerCase()
                            .indexOf(filterValue) !== -1;
                }
            }
        }
    }

    //Sync the agreement list by moving the selected agreement to the top during Edit
    private syncAgreementlist() {
        if (
            this.selectedagreements != null &&
            this.selectedagreements.length > 0 &&
            this.agreementList != null &&
            this.agreementList.length > 0
        ) {
            for (var i = 0; i < this.selectedagreements.length; i++) {
                var index = this.agreementList.findIndex(
                    x =>
                        parseInt(x.agreementId) ===
                            parseInt(this.selectedagreements[i].agreementId) &&
                        x.agreementType.trim().toLowerCase() ==
                            this.selectedagreements[i].agreementType
                                .trim()
                                .toLowerCase()
                );
                if (index > -1) {
                    this.selectedagreements[i].agreementTypeDescription =
                        this.agreementList[index].agreementTypeDescription;
                    this.tempselectedagreements.push(this.agreementList[index]);
                    this.agreementList.splice(index, 1);
                } else {
                    this.tempselectedagreements.push(
                        this.selectedagreements[i]
                    );
                }
            }
        }
        if (
            this.tempselectedagreements != null &&
            this.tempselectedagreements.length > 0 &&
            this.agreementList != null &&
            this.agreementList.length > 0
        ) {
            this.agreementList = this.tempselectedagreements.concat(
                this.agreementList
            );
        }

        this.dataSource = new MatTableDataSource(this.agreementList);
        this.SyncCheckboxForEdit();

        if (this.inputFilterFormControl.value != null) {
            this.applyFilter(this.inputFilterFormControl.value);
            this.dataSource.filterPredicate = (
                data: Agreement,
                filterValue: string
            ) =>
                data.agreementId.trim().toLowerCase().indexOf(filterValue) !==
                    -1 ||
                data.agreementName.trim().toLowerCase().indexOf(filterValue) !==
                    -1;
        }
    }

    //Check the checkbox for the saved agreements during Edit
    private SyncCheckboxForEdit() {
        if (
            this.selectedagreements != null &&
            this.selectedagreements.length > 0
        ) {
            const Agreement = this.dataSource.data.slice(
                0,
                this.selectedagreements.length
            );

            this.filteredagreements = this.agreementList;
            this.selection = new SelectionModel<Agreement>(true, Agreement);
            for (var i = 0; i < this.selectedagreements.length; i++) {
                this.selection.select(this.selectedagreements[i]);
            }
        }
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }
}
