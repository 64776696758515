import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.startsWith('/api/') && environment.overrideUrl) {
            const apiReq = req.clone({
                url: `${environment.apiUrl}${req.url}`,
            });
            return next.handle(apiReq);
        }
        return next.handle(req);
    }
}
