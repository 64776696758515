import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';
import { clientSession } from '../../client-session';
import { Permission, PermissionName } from '../../model/UserPermission';

@Injectable()
export class DatasetsGuardService implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (
            clientSession.userHasPermission(
                PermissionName.Datasets,
                Permission.Read
            ) ||
            clientSession.userHasPermission(
                PermissionName.Datasets,
                Permission.All
            )
        ) {
            return true;
        } else {
            this.router.navigate(['unauthorized']);
            return false;
        }
    }
}
