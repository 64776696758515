<!DOCTYPE html>

<div class="header">
    <h1>Roster Publication</h1>
    <div class="rowStyle">
        <div>
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Pharma Grouping Id:</mat-label>
                <input
                    type="text"
                    matInput
                    [formControl]="pharmaGroupingIdFormControl" />
            </mat-form-field>
            <mat-error
                class="mat-errorField"
                *ngIf="
                    pharmaGroupingIdFormControl.hasError('required') &&
                    submitted
                ">
                Pharma Grouping Id is <strong>required</strong>
            </mat-error>
        </div>
        <div>
            <mat-form-field class="full-width" appearance="fill">
                <mat-label> Roster Publication Id: </mat-label>
                <input
                    type="text"
                    matInput
                    [formControl]="rosterPublicationIdFormControl" />
            </mat-form-field>
        </div>
    </div>
    <div class="rowStyle">
        <mat-checkbox
            class="checkboxstyling"
            [formControl]="isQCRosterFormControl">
            QC Roster
        </mat-checkbox>
        <button
            class="buttonstyling"
            color="primary"
            mat-raised-button
            (click)="runRoster()">
            Trigger On Demand Report
        </button>
    </div>
</div>
