import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
    AsyncValidatorFn,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DetailedAgreement } from '../../../../model/Sort';

@Component({
    selector: 'app-sortadditem',
    templateUrl: './sortadditem.component.html',
    styleUrls: ['./sortadditem.component.scss'],
})
export class SortadditemComponent implements OnInit {
    dataSource: MatTableDataSource<DetailedAgreement>;
    selectedgpo: string;
    //selectedQuarterCode: QuarterCode | undefined;
    public formGroup: FormGroup;
    additemlist: Array<DetailedAgreement> = [];
    agreementList: Array<DetailedAgreement> | undefined;
    selection = new SelectionModel<DetailedAgreement>(true, []);
    public startingPositionFormControl: FormControl;
    public inputFilterFormControl;
    public maxposition = 1;
    submitted: boolean = false;
    isInteger: boolean;
    displayedColumns: string[] = [
        'select',
        'agreementId',
        'agreementName',
        'agreementTypeDescription',
        'periodProfileDescription',
        'manufacturer',
        'drug',
        'levelDescription',
        'gpo',
        'route',
        'contractStartDate',
        'contractExpDate',
        'hide',
    ];
    constructor(
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<SortadditemComponent>
    ) {
        this.inputFilterFormControl = new FormControl('');
        this.startingPositionFormControl = new FormControl('', [
            Validators.min(1) as AsyncValidatorFn,
        ]);
        this.selectedgpo = '';
        this.isInteger = true;
        if (data) {
            this.selectedgpo = data.GPO;
            this.additemlist = data.SelectedAgreements;
            this.agreementList = data.AgreementList;
            //this.selectedQuarterCode = data.SelectedQuarter;
        }
        this.dataSource = new MatTableDataSource(this.agreementList);
        this.formGroup = new FormGroup({
            position: this.startingPositionFormControl,
        });
    }

    ngOnInit(): void {
        this.loadAgreementList();
        this.dataSource = new MatTableDataSource(this.agreementList);
        this.dataSource.filterPredicate = (
            data: DetailedAgreement,
            filterValue: string
        ) =>
            data.agreementId.trim().toLowerCase().indexOf(filterValue) !== -1 ||
            data.agreementName.trim().toLowerCase().indexOf(filterValue) !== -1;
    }
    add() {
        //debugger;
        this.submitted = true;
        if (this.startingPositionFormControl.value === null) {
            this.isInteger = false;
            // return;
        } else if (this.startingPositionFormControl.value % 1 === 0) {
            this.isInteger = true;
        } else {
            this.isInteger = false;
            //return;
        }
        if (this.selection.selected.length <= 0) {
            return;
        } else if (this.formGroup.invalid) {
            return;
        } else if (this.isInteger === false) {
            return;
        }

        this.dialogRef.close({
            data: {
                Position: this.startingPositionFormControl.value,
                SelectedAgreements: this.selection.selected,
            },
        });
    }
    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches

        //this.filterednotes = this.notes.filter(p => p.subject.toLowerCase().includes(filterValue) || p.agreements.some(agr => agr.agreementName.toLowerCase().includes(filterValue)) || p.agreements.some(agr => agr.agreementId.toLowerCase().includes(filterValue)));

        this.dataSource.filter = filterValue;
    }

    RemoveselectedAgreements() {
        if (
            this.additemlist != undefined &&
            this.additemlist.length > 0 &&
            this.agreementList != undefined
        ) {
            for (var i = 0; i < this.additemlist.length; i++) {
                var index = this.agreementList?.findIndex(
                    row =>
                        parseInt(row.agreementId) ===
                        parseInt(this.additemlist[i].agreementId)
                );
                if (index > -1) {
                    this.agreementList.splice(index, 1);
                }
            }
        }
    }

    async loadAgreementList() {
        if (this.selectedgpo != undefined) {
            this.agreementList = this.agreementList?.filter(
                row => row.gpo.toLowerCase() == this.selectedgpo.toLowerCase()
            );
        }
        if (this.agreementList != null) {
            if (this.additemlist != undefined && this.additemlist.length > 0) {
                for (var i = 0; i < this.additemlist.length; i++) {
                    var index = this.agreementList.findIndex(
                        row =>
                            parseInt(row.agreementId) ===
                                parseInt(this.additemlist[i].agreementId) &&
                            row.agreementType.trim().toLowerCase() ===
                                this.additemlist[i].agreementType
                                    .trim()
                                    .toLowerCase() &&
                            row.agreementName.trim().toLowerCase() ===
                                this.additemlist[i].agreementName
                                    .trim()
                                    .toLowerCase() &&
                            row.drug.trim().toLowerCase() ===
                                this.additemlist[i].drug.trim().toLowerCase() &&
                            row.level.trim().toLowerCase() ===
                                this.additemlist[i].level.trim().toLowerCase()
                    );
                    if (index > -1) {
                        this.agreementList.splice(index, 1);
                    }
                }
            }
        }

        this.dataSource = new MatTableDataSource(this.agreementList);
    }
}
