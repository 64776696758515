<div>
    <div
        style="
            padding-left: 25px;
            display: flex;
            position: fixed;
            z-index: 5;
            width: 95%;
            height: 80px;
        "
        class="fixedDivStyle">
        <div style="width: 40%; padding-left: 100px">
            <mat-button-toggle-group
                #groupOral="matButtonToggleGroup"
                style="
                    margin-left: -100px;
                    margin-right: 25px;
                    margin-top: 20px;
                "
                [value]="selectedValOral"
                (change)="onValChangeOral(groupOral.value)">
                <mat-button-toggle value="Oral"
                    ><mat-icon>event_note</mat-icon> Oral</mat-button-toggle
                >
                <mat-button-toggle value="Non-Oral"
                    ><mat-icon>event_note</mat-icon> Non-Oral</mat-button-toggle
                >
                <mat-button-toggle value="All"
                    ><mat-icon>event_note</mat-icon> All</mat-button-toggle
                >
            </mat-button-toggle-group>
            <mat-button-toggle-group
                #group="matButtonToggleGroup"
                style="margin-top: 20px"
                [value]="selectedVal"
                (change)="onValChange(group.value)">
                <mat-button-toggle value="ION"
                    ><mat-icon>event_note</mat-icon> ION</mat-button-toggle
                >
                <mat-button-toggle value="IPN"
                    ><mat-icon>event_note</mat-icon> IPN</mat-button-toggle
                >
                <mat-button-toggle value="SAN"
                    ><mat-icon>event_note</mat-icon> SAN</mat-button-toggle
                >
            </mat-button-toggle-group>
        </div>
        <div style="width: 12%; padding-left: 25px; margin-top: 12px">
            <mat-form-field appearance="fill" class="filterstyle">
                <mat-label>Please select a Quarter</mat-label>
                <mat-select
                    name="quarterCode"
                    [formControl]="quarterCodeFormControl"
                    (selectionChange)="changeSortList($event.value)">
                    <mat-option
                        *ngFor="let quarterCodeval of quarterCode"
                        [value]="quarterCodeval.quarter">
                        {{ quarterCodeval.quarter }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div style="width: 10%; padding-left: 25px">
            <button
                color="primary"
                (click)="copysort()"
                mat-raised-button
                class="buttonstyling">
                Copy Sort
            </button>
        </div>
        <div style="width: 36%; margin-right: 4%" align="end">
            <button
                color="primary"
                (click)="moveitem()"
                mat-raised-button
                class="buttonstyling">
                Move Item
            </button>

            <button
                color="primary"
                (click)="addnewitem()"
                mat-raised-button
                class="buttonstyling">
                Add New Item
            </button>

            <button
                color="primary"
                (click)="onsubmit()"
                mat-raised-button
                class="buttonstyling">
                Save
            </button>
            <button
                color="primary"
                mat-raised-button
                (click)="btnExport()"
                class="buttonstyling">
                Export
            </button>
        </div>
    </div>
    <mat-error class="mat-errorField" *ngIf="!aggsort.length && submitted">
        <br />
        Please Add an <strong>Agreement</strong>
    </mat-error>

    <div
        class="mat-elevation-z8 mainDivStyle"
        style="
            width: 95%;
            margin-left: 20px;
            margin-top: 110px;
            display: inline-block;
            margin-bottom: 10px;
        ">
        <mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8"
            style="width: 100%">
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>Select</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="agreementId">
                <mat-header-cell *matHeaderCellDef
                    >Agreement Id</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                    {{ element.agreementId }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="agreementName">
                <mat-header-cell *matHeaderCellDef
                    >Agreement Name</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                    {{ element.agreementName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="agreementTypeDescription">
                <mat-header-cell *matHeaderCellDef
                    >Agreement Type</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                    <p *ngIf="element.agreementTypeCode">
                        {{ element.agreementTypeCode.agreementTypeDescription }}
                    </p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="periodProfileDescription">
                <mat-header-cell *matHeaderCellDef
                    >Period Profile</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                    <p *ngIf="element.periodProfileCode">
                        {{ element.periodProfileCode.periodProfileDescription }}
                    </p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="manufacturer">
                <mat-header-cell *matHeaderCellDef
                    >Manufacturer</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                    {{ element.manufacturer }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="drug">
                <mat-header-cell *matHeaderCellDef>Drug</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.drug }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="levelDescription">
                <mat-header-cell *matHeaderCellDef>Level</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <p *ngIf="element.levelCode">
                        {{ element.levelCode.levelDescription }}
                    </p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="gpo">
                <mat-header-cell *matHeaderCellDef>GPO</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.gpo }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="route">
                <mat-header-cell *matHeaderCellDef>Route</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.route }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="hidden">
                <mat-header-cell *matHeaderCellDef>Hide</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-checkbox
                        [value]="element.hidden"
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="element.hidden"
                        [checked]="element.hidden"></mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sortPosition">
                <mat-header-cell *matHeaderCellDef>Sort #</mat-header-cell>
                <mat-cell
                    class="sort-padding"
                    style="padding-top: 4px !important"
                    *matCellDef="let element; let i = index">
                    <p *ngIf="!dbvalue">
                        {{ i + 1 }}
                    </p>
                    <p *ngIf="dbvalue">
                        {{ element.sortPosition }}
                    </p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actionsColumn">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell
                    class="icon-padding"
                    *matCellDef="let element; let j = index">
                    <mat-icon
                        matTooltip="Click to Delete"
                        (click)="deleteItem(element)"
                        inline="true"
                        >close</mat-icon
                    >
                </mat-cell>
            </ng-container>

            <mat-header-row
                *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
        </mat-table>
    </div>
</div>
