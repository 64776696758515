<div class="column-layout">
    <div class="fixedDivStyle">
        <div style="position: fixed; z-index: 5">
            <mat-form-field style="padding-left: 10px">
                <mat-label>Choose a date</mat-label>
                <input (dateInput)="OnDateChange($event.value)"
                       [formControl]="dateformcontrol"
                       matInput
                       [matDatepicker]="picker" />
                <!--<mat-hint>MM/DD/YYYY</mat-hint>-->
                <mat-datepicker-toggle matIconSuffix
                                       [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <button class="buttonstyling"
                    color="primary"
                    mat-raised-button
                    (click)="showLogs()">
                Show Logs
            </button>

            <button class="buttonstyling"
                    color="primary"
                    mat-raised-button
                    (click)="Schedule()">
                Schedule Report
            </button>
            <button class="buttonstyling"
                    color="primary"
                    mat-raised-button
                    (click)="Run()">
                Run Report
            </button>

            <button color="primary"
                    class="leftbuttonstyling"
                    mat-raised-button
                    (click)="btnclick()">
                New Report
            </button>

            <button class="buttonstyling"
                    color="primary"
                    mat-raised-button
                    (click)="enable()">
                Enable
            </button>
            <button class="buttonstyling"
                    color="primary"
                    mat-raised-button
                    (click)="disable()">
                Disable
            </button>
            <button color="primary"
                    class="leftbuttonstyling"
                    mat-raised-button
                    (click)="btnDelete()">
                Delete
            </button>
            <button color="primary"
                    class="buttonstyling"
                    mat-raised-button
                    (click)="btnExport()">
                Export
            </button>
        </div>
    </div>

    <div class="flex-container-grid mainDivStyle">
        <div class="mat-elevation-z8">
            <div style="margin-bottom: 30px">
                <mat-accordion>
                    <mat-expansion-panel
                        [expanded]="panelOpenState"
                        (opened)="panelOpenState = true"
                        (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Filters </mat-panel-title>
                            <!--<mat-panel-description>
                                        Select Options to filter the Table below
                                    </mat-panel-description>-->
                        </mat-expansion-panel-header>

                        <mat-form-field class="matFilterField">
                            <input
                                matInput
                                class="form-field matInputFiterPadding"
                                [formControl]="manufacturerNameFilter"
                                placeholder="Manufacturer" />
                        </mat-form-field>
                        <mat-form-field class="matFilterField">
                            <input
                                matInput
                                class="form-field matInputFiterPadding"
                                [formControl]="productNameFilter"
                                placeholder="Product" />
                        </mat-form-field>

                        <mat-form-field
                            *ngFor="let filter of filterSelectObj"
                            class="matFilterField">
                            <mat-label> {{ filter.name }}</mat-label>
                            <select
                                matNativeControl
                                name="{{ filter.columnProp }}"
                                [(ngModel)]="filter.modelValue"
                                (change)="
                                    filterChange(filter.columnProp, $event)
                                ">
                                <option value="">
                                    -- Select {{ filter.name }} --
                                </option>
                                <option
                                    [value]="item"
                                    *ngFor="let item of filter.options">
                                    {{ item }}
                                </option>
                            </select>
                        </mat-form-field>
                        <mat-form-field class="matFilterField">
                            <mat-label> Schedules</mat-label>
                            <select
                                matNativeControl
                                name="Schedules"
                                [formControl]="ScheduleFilterFormControl"
                                (change)="filterChange('Schedules', $event)">
                                <option value="">-- Select Schedules --</option>
                                <option
                                    [value]="item.id"
                                    *ngFor="let item of schedules">
                                    {{ item.dayOfWeek }}
                                </option>
                            </select>
                        </mat-form-field>
                        <div style="text-align: center">
                            <button
                                color="primary"
                                mat-raised-button
                                (click)="resetFilters()">
                                Reset
                            </button>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <mat-table
                [dataSource]="dataSource"
                class="mat-elevation-z8"
                matSort>
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                            (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="
                                selection.hasValue() && !isAllSelected()
                            ">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            (input)="AddRemoveForEnableDisable(row, $event)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="manufacturerName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Manufacturer </mat-header-cell
                    >>
                    <mat-cell *matCellDef="let element">
                        {{ element.manufacturerName }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="productName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Drug Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.productName }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="gpo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        GPO
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.gpo }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reportType">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Report Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.reportType }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reportPeriod">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Report Period
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.reportPeriod }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="dataset">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Dataset
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.reportView.viewName }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="enabled">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Enabled
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.enabled }}
                    </mat-cell>
                </ng-container>

                <ng-container
                    matColumnDef="actionsColumn"
                    class="mat-td-actions-width">
                    <mat-header-cell
                        *matHeaderCellDef
                        class="mat-td-actions-width"></mat-header-cell>
                    <mat-cell
                        class="mat-td-actions-width icon-padding"
                        *matCellDef="let element; let j = index"
                        (click)="$event.stopPropagation()">
                        <mat-icon
                            inline="true"
                            matTooltip="Click to Edit"
                            (click)="getReport(element)"
                            >edit</mat-icon
                        >
                        <mat-icon
                            inline="true"
                            (mouseover)="getTooltip(element)"
                            [showToolTip]="element.showTooltip"
                            *ngIf="element.showTooltip"
                            appCustomToolTip
                            [contentTemplate]="template"
                            >info</mat-icon
                        >
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator
                [pageSizeOptions]="[250, 500]"
                aria-label="Select page of reports"></mat-paginator>
        </div>
    </div>
</div>
<ng-template #template>
    <div
        style="
            display: flex;
            flex-direction: column;
            align-content: center;
            vertical-align: central;
            width: 425px;
            font-size: 10px !important;
        ">
        <mat-table
            style="width: 100%; overflow-y: scroll; max-height: 200px"
            class="mat-elevation-z8"
            [dataSource]="dataSourceReportParameters">
            <ng-container matColumnDef="columnName">
                <mat-header-cell class="mat-rowParameter" *matHeaderCellDef>
                    Column Name
                </mat-header-cell>
                <mat-cell class="mat-rowParameter" *matCellDef="let element">
                    {{ element.columnName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operator">
                <mat-header-cell class="mat-rowParameter" *matHeaderCellDef>
                    Operator
                </mat-header-cell>
                <mat-cell class="mat-rowParameter" *matCellDef="let element">
                    {{ element.operator }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="parameterValue">
                <mat-header-cell class="mat-rowParameter" *matHeaderCellDef>
                    Value
                </mat-header-cell>
                <mat-cell class="mat-rowParameter" *matCellDef="let element">
                    {{ element.parameterValue }}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsReportParameters">
            </mat-header-row>
            <mat-row
                *matRowDef="
                    let row;
                    columns: displayedColumnsReportParameters
                "></mat-row>
        </mat-table>
    </div>
</ng-template>
<router-outlet></router-outlet>
