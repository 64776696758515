<div class="column-layout">
    <div class="fixedDivStyle">
        <button
            color="primary"
            mat-raised-button
            (click)="newemail()"
            class="buttonstyling">
            New Email Template
        </button>
    </div>

    <div class="flex-container-grid mainDivStyle">
        <div class="mat-elevation-z8" style="width: 80%">
            <mat-table [dataSource]="dataSource" matSort style="width: 100%">
                <ng-container matColumnDef="templateName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Email Template Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.templateName }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="subjectline">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Subject Line
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.subjectline }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="updateDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        Last Modified
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.updateDate | date : 'MM/dd/yyyy' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="active">
                    <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox
                            [value]="element.active"
                            [checked]="element.active"
                            [disabled]="true"></mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actionsColumn">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell
                        class="icon-padding"
                        *matCellDef="let element; let j = index"
                        (click)="$event.stopPropagation()">
                        <mat-icon
                            inline="true"
                            matTooltip="Click to Edit"
                            (click)="getEmailTemplate(element)"
                            >edit</mat-icon
                        >
                        <mat-icon
                            inline="true"
                            matTooltip="Click to Delete"
                            (click)="deleteEmailDialog(element.id)"
                            >close</mat-icon
                        >
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>

            <mat-paginator
                style="width: 100%"
                [pageSizeOptions]="[20, 40, 60, 100, 150, 250, 500, 750, 1000]"
                aria-label="Select page of reports"></mat-paginator>
        </div>
    </div>
</div>
