import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { Schedule } from '../../../model/Schedule';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { throwError } from 'rxjs';

import { ReportService } from '../../../services/report.service';
import { Report } from '../../../model/Report';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { clientSession } from '../../../client-session';
import { TableUtil } from '../../../utilities/TableUtil';

@Component({
    selector: 'app-report-delete-dialog',
    templateUrl: './report-delete-dialog.component.html',
    styleUrls: ['./report-delete-dialog.component.scss'],
})
export class ReportDeleteDialogComponent {
    reportlist: Array<Report> = [];
    message: string = 'Are you sure you want to delete selected reports?';
    message2: string = 'This action can not be undone';
    //dataSource: MatTableDataSource<Report>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private reportservice: ReportService,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<ReportDeleteDialogComponent>
    ) {
        this.reportlist = [];
        if (data) {
            this.reportlist = data.reports;
        }
    }

    delete() {
        for (var i = 0; i < this.reportlist.length; i++) {
            const id = this.reportlist[i].id;
            this.http
                .delete<any>(`/api/reports/${id}`)
                .subscribe(() => undefined);
        }
        this.dialogRef.close(true);
        return true;
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }
}
