<div style="margin: 10px">
    <p style="color: orangered">
        This operation will overwrite existing data in the target quarter
    </p>
    <div style="display: flex; text-align: center">
        <div style="width: 100%; padding-top: 20px">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Please select a Source Quarter</mat-label>
                <mat-select
                    name="quarterCode"
                    [formControl]="sourcequarterCodeFormControl">
                    <mat-option
                        *ngFor="let quarterCodeval of sourceQuarterCode"
                        [value]="quarterCodeval.quarter">
                        {{ quarterCodeval.quarter }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error
                *ngIf="
                    sourcequarterCodeFormControl.hasError('required') &&
                    submitted
                "
                class="mat-errorField">
                Source Quarter is required.
            </mat-error>
        </div>
        <div style="width: 100%; padding-top: 20px; padding-left: 10px">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Please select a Target Quarter</mat-label>
                <mat-select
                    name="quarterCode"
                    [formControl]="targetquarterCodeFormControl">
                    <mat-option
                        *ngFor="let quarterCodeval of futureQuarterCode"
                        [value]="quarterCodeval.quarter">
                        {{ quarterCodeval.quarter }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error
                *ngIf="
                    targetquarterCodeFormControl.hasError('required') &&
                    submitted
                "
                class="mat-errorField">
                Target Quarter is required.
            </mat-error>
            <mat-error class="mat-errorField" *ngIf="sameQuarters && submitted">
                <br />
                Source and Target Quarters can not be same.
            </mat-error>
        </div>
    </div>
    <div>
        <mat-dialog-actions class="actions" align="center">
            <button color="primary" mat-raised-button (click)="copy()">
                Copy
            </button>
            <button color="primary" mat-raised-button mat-dialog-close>
                Cancel
            </button>
        </mat-dialog-actions>
    </div>
</div>
