import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { CopyQuarter } from '../../../model/QuarterCode';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
    message: string = 'Are you sure you want to delete?';
    confirmButtonText = 'Delete';
    cancelButtonText = 'Cancel';
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    showLogsDetails: Boolean = false;

    rowid = '';
    constructor(
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        private snackBar: MatSnackBar
    ) {
        if (data) {
            this.message = data.data.message; // || this.message;
            this.rowid = data.data.rowid;
            if (data.data.buttonText) {
                this.confirmButtonText = data.data.buttonText.ok; // || this.confirmButtonText;
                this.cancelButtonText = data.data.buttonText.cancel; // || this.cancelButtonText;
            }
            if (this.data.data.source == 'logviewer') {
                this.showLogsDetails = true;
            }
        }
    }

    async onConfirmClick(): Promise<void> {
        //debugger;
        if (this.data.data.source == 'EmailTemplate') {
            await this.http
                .delete(`/api/emailtemplates/${this.rowid}`)
                .toPromise()
                .catch((error: Response) => {
                    console.log(error);
                    this.snackBar.open(
                        'This EmailTemplate is associated with a Report.'
                    );
                    //return Observable.throw(error);
                });
        } else if (this.data.data.source == 'schedules') {
            await this.http
                .delete(`/api/schedules/${this.rowid}`)
                .toPromise()
                .catch((error: Response) => {
                    console.log(error);
                    this.openSnackBar(
                        'This Schedule is associated with a Report.'
                    );
                });
        } else if (this.data.data.source == 'EmailGroup') {
            await this.http
                .delete(`/api/emailgroups/${this.rowid}`)
                .toPromise()
                .catch((error: Response) => {
                    console.log(error);
                    this.openSnackBar(
                        'This EmailGroup is associated with a Report.'
                    );
                });
        } else if (this.data.data.source == 'noteslist') {
            await this.http
                .delete(`/api/scorecard/notes/${this.rowid}`)
                .toPromise()
                .catch((error: Response) => {
                    console.log(error);
                    this.openSnackBar(
                        'Error in deleteing the notes. Please try again.'
                    );
                });
        } else if (this.data.data.source == 'CopyNotes') {
            var objcopyQuarter: CopyQuarter = {} as CopyQuarter;
            objcopyQuarter.sourceQuarter = this.data.data.sourceValue;
            objcopyQuarter.targetQuarter = this.data.data.targetValue;

            await this.http
                .post<any>('/api/scorecard/notes/copyquarter', objcopyQuarter)
                .toPromise()
                .catch((error: Response) => {
                    console.log(error);
                    this.openSnackBar(
                        'Error in copying the notes. Please try again.'
                    );
                    this.dialogRef.close(false);
                });
        } else if (this.data.data.source == 'CopySort') {
            var objcopyQuarter: CopyQuarter = {} as CopyQuarter;
            objcopyQuarter.sourceQuarter = this.data.data.sourceValue;
            objcopyQuarter.targetQuarter = this.data.data.targetValue;

            await this.http
                .post<any>('/api/scorecard/sort/copyquarter', objcopyQuarter)
                .toPromise()
                .catch((error: Response) => {
                    console.log(error);
                    this.openSnackBar(
                        'Error in copying the sort. Please try again.'
                    );
                    this.dialogRef.close(false);
                });
        }
        this.dialogRef.close(true);
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }
}
