import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ScheduledEmail } from '../../../model/ScheduledEmail';
import { ScheduledEmailService } from '../../../services/scheduledemail.service';
import { TableUtil } from '../../../utilities/TableUtil';

@Component({
    selector: 'app-scheduledemail',
    templateUrl: './scheduledemail.component.html',
    styleUrls: ['./scheduledemail.component.scss'],
})
export class ScheduledEmailComponent implements OnInit {
    scheduledEmails: Array<ScheduledEmail> = [];
    sendlist: Array<ScheduledEmail> = [];
    selection = new SelectionModel<ScheduledEmail>(true, []);
    dataSource: MatTableDataSource<ScheduledEmail> =
        new MatTableDataSource<ScheduledEmail>();
    displayedColumns: string[] = [
        'select',
        'manufacturerName',
        'productName',
        'gpo',
        'dataset',
        'reportGenerationDate',
        'emailSentDate',
        'error',
    ];
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        private http: HttpClient,
        private _snackBar: MatSnackBar,
        private scheduledEmailservice: ScheduledEmailService
    ) {}

    ngOnInit(): void {
        this.loadScheduleEmailsList();
    }

    loadScheduleEmailsList() {
        this.scheduledEmailservice.LoadScheduledEmails().subscribe(result => {
            this.scheduledEmails = result;
            this.dataSource.data = this.scheduledEmails;
        });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    resend() {
        if (this.isAllSelected()) {
            this.sendlist = [];
            this.dataSource.data.forEach(row => this.sendlist.push(row));
            this.sendlist.map(function (x) {
                x.sent = false;
                x.emailSentDate = undefined;
                x.error = undefined;
            });

            this.http
                .put<any>('/api/scheduledemail', this.sendlist)
                .subscribe();
            this.selection.clear();
        } else {
            this.sendlist.map(function (x) {
                x.sent = false;
                x.emailSentDate = undefined;
                x.error = undefined;
            });
            this.http
                .put<any>('/api/scheduledemail', this.sendlist)
                .subscribe();
        }
        this.selection.clear();
        this.sendlist = [];
        this.loadScheduleEmailsList();
    }

    AddRemoveForResend(row: any, event: any) {
        if (event.target.checked) {
            this.sendlist.push(row);
        } else {
            if (this.sendlist.indexOf(row) >= 0) {
                this.sendlist.forEach((element, index) => {
                    if (element == row) this.sendlist.splice(index, 1);
                });
            }
        }
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach(row => this.selection.select(row));
        if (this.isAllSelected() == false) {
            this.scheduledEmails = [];
        }
    }

    btnExport() {
        TableUtil.exportArray(this.scheduledEmails, 'Scheduled Emails');
    }
}
