<div>
    <div>
        <p
            style="
                align-content: flex-start;
                padding-top: 10px;
                margin-left: 20px;
            ">
            {{ isAddOrEdit() }} Email Template
        </p>
    </div>

    <div class="rowStyle">
        <div class="divWidth">
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Email Template Name</mat-label>
                <input
                    type="text"
                    matInput
                    [formControl]="emailTempNameFormControl"
                    maxlength="512" />
            </mat-form-field>
            <mat-error
                class="mat-errorField"
                *ngIf="
                    emailTempNameFormControl.hasError('required') && submitted
                ">
                Email Template Name is <strong>required</strong>
            </mat-error>
        </div>
        <mat-checkbox
            class="checkboxstyling"
            [formControl]="isActiveFormControl">
            Active
        </mat-checkbox>

        <button
            class="buttonstyling"
            color="primary"
            mat-raised-button
            (click)="cancel()">
            Cancel
        </button>
        <button
            class="buttonstyling"
            color="primary"
            mat-raised-button
            (click)="save()">
            Save
        </button>
    </div>

    <!--<div class="checkbox-group">
        <mat-form-field>
            <mat-checkbox  [formControl]="isActiveFormControl"> Active </mat-checkbox>
        </mat-form-field>
    </div>-->
    <div class="divWidth">
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Subject Line</mat-label>
            <input
                type="text"
                matInput
                [formControl]="subjectLineFormControl"
                maxlength="512" />
        </mat-form-field>
        <mat-error
            *ngIf="subjectLineFormControl.hasError('required') && submitted"
            class="mat-errorField">
            Subject Line is <strong>required</strong>
        </mat-error>
    </div>
    <mat-form-field class="full-width templatestyling" appearance="fill">
        <mat-label>Email Template **HTML only**</mat-label>
        <textarea
            matInput
            rows="16"
            cols="80"
            [formControl]="templateFormControl">
        </textarea>
    </mat-form-field>
    <mat-error
        *ngIf="templateFormControl.hasError('required') && submitted"
        class="mat-errorField">
        Template is required.
    </mat-error>
</div>
