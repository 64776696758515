import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { count } from 'rxjs';
import { AgreementSort } from '../../../../model/Sort';

@Component({
    selector: 'app-sortmoveitem',
    templateUrl: './sortmoveitem.component.html',
    styleUrls: ['./sortmoveitem.component.scss'],
})
export class SortmoveitemComponent implements OnInit {
    public startingPositionFormControl: FormControl;
    public selectedAgreementsFormControl;
    selectedagreements: string;
    isInteger: boolean;
    data: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) private dialogdata: Array<AgreementSort>,
        private dialogRef: MatDialogRef<SortmoveitemComponent>
    ) {
        this.startingPositionFormControl = new FormControl('');
        this.selectedAgreementsFormControl = new FormControl('');
        this.isInteger = true;
        this.selectedagreements = '';
        if (dialogdata) {
            this.data = dialogdata;
        }
    }

    ngOnInit(): void {
        if (this.data != undefined) {
            for (let i = 0; i < this.data.length; i++) {
                this.selectedagreements =
                    this.selectedagreements + this.data[i].agreementId + ',';
            }
            this.selectedagreements = this.selectedagreements.substring(
                0,
                this.selectedagreements.length - 1
            );
            this.selectedAgreementsFormControl.setValue(
                this.selectedagreements
            );
        }
    }
    movedposition() {
        if (this.startingPositionFormControl.value === null) {
            this.isInteger = false;
        } else if (this.startingPositionFormControl.value % 1 === 0) {
            this.dialogRef.close({
                data: { Position: this.startingPositionFormControl.value },
            });
        } else {
            this.isInteger = false;
        }
    }
}
