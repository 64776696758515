import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { View } from '../../../model/View';
import { ReportService } from '../../../services/report.service';
import { TableUtil } from '../../../utilities/TableUtil';
import { DatasetDialogComponent } from '../dataset-dialog/dataset-dialog.component';

@Component({
    selector: 'app-dataset-list',
    templateUrl: './dataset-list.component.html',
    styleUrls: ['./dataset-list.component.scss'],
})
export class DatasetListComponent implements OnInit {
    @ViewChild(MatSort) sort!: MatSort;
    view: Array<View> = [];
    dataSource: MatTableDataSource<View>;
    displayedColumns: string[] = [
        'viewName',
        'updateDate',
        'version',
        'active',
        'actionsColumn',
    ];
    constructor(
        private dialog: MatDialog,
        private reportservice: ReportService
    ) {
        this.dataSource = new MatTableDataSource(this.view);
        this.dataSource.sort = this.sort;
    }

    ngOnInit(): void {
        this.loadDatasetList();
    }

    newDataset() {
        const userDialogConfig = new MatDialogConfig();
        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        userDialogConfig.width = '70%';
        userDialogConfig.height = '95%';
        this.dialog
            .open(DatasetDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                this.loadDatasetList();
            });
    }

    editDataSet(row: any) {
        const userDialogConfig = new MatDialogConfig();
        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        userDialogConfig.width = '70%';
        userDialogConfig.height = '95%';

        userDialogConfig.data = row;
        this.dialog
            .open(DatasetDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                this.loadDatasetList();
            });
    }

    btnExport() {
        TableUtil.exportArray(this.view, 'DataSet');
    }

    loadDatasetList() {
        this.reportservice.loadAllViewList().subscribe(result => {
            this.view = result;
            this.dataSource = new MatTableDataSource(this.view);
            this.dataSource.sort = this.sort;
        });
    }
}
