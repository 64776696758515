import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { clientSession } from './client-session';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
    MsalService,
    MsalBroadcastService,
    MSAL_GUARD_CONFIG,
    MsalGuardConfiguration,
} from '@azure/msal-angular';
import { Title } from '@angular/platform-browser';

interface NavItem {
    displayName: string;
    disabled?: boolean;
    route?: string;
    children?: NavItem[];
}

interface AzureProfile {
    givenName?: string;
    surname?: string;
    userPrincipalName?: string;
    id?: string;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public isIframe = false;
    private readonly _destroying$ = new Subject<void>();
    private GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
    public azureProfile: AzureProfile | undefined;

    constructor(
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration,
        private msalBroadcastService: MsalBroadcastService,
        private authService: MsalService,
        private http: HttpClient,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private title: Title
    ) {}

    ngOnInit() {
        this.setPageTitle();
        this.isIframe = window !== window.parent && !window.opener;
        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) =>
                        status === InteractionStatus.None
                ),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.getUserProfile();
            });
    }

    async getUserProfile() {
        this.azureProfile = await this.http
            .get<AzureProfile>(this.GRAPH_ENDPOINT)
            .toPromise();
        clientSession.userPrincipalName = this.azureProfile?.userPrincipalName;
        await clientSession.loadLinkUser(this.http);
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    private setPageTitle(): void {
        const defaultPageTitle = 'GPOlink';

        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => {
                    let child = this.activatedRoute.firstChild;

                    if (!child) {
                        return (
                            this.activatedRoute.snapshot.data['title'] ||
                            defaultPageTitle
                        );
                    }

                    while (child.firstChild) {
                        child = child.firstChild;
                    }

                    if (child.snapshot.data['title']) {
                        return child.snapshot.data['title'] || defaultPageTitle;
                    } else {
                        return defaultPageTitle;
                    }
                })
            )
            .subscribe((title: string) => this.title.setTitle(title));
    }

    menu: NavItem[] = [
        {
            displayName: 'Report',
            children: [
                {
                    displayName: 'Management',
                    route: 'reportmanagement',
                },
                {
                    displayName: 'Schedules',
                    route: 'schedules',
                },
                {
                    displayName: 'Datasets',
                    route: 'datasetlist',
                },
            ],
        },
        {
            displayName: 'Mailer',
            children: [
                {
                    displayName: 'Groups',
                    route: 'emailgrouplist',
                },
                {
                    displayName: 'Templates',
                    route: 'emailtemplatelist',
                },
                {
                    displayName: 'Emails',
                    route: 'scheduledemails',
                },
            ],
        },
        {
            displayName: 'Scorecard',
            children: [
                {
                    displayName: 'Notes',
                    route: 'noteslist',
                },
                {
                    displayName: 'Sort',
                    route: 'sort',
                },
            ],
        },
        {
            displayName: 'Rosters',
            children: [
                /*{
                    displayName: 'Roster Generation',
                    route: 'rosterGeneration',
                },*/
                {
                    displayName: 'Logs',
                    route: 'rosterLogs',
                },
            ],
        },
        {
            displayName: 'System',
            children: [
                {
                    displayName: 'Users',
                    route: 'usermanagement',
                },
                {
                    displayName: 'Status',
                    route: '/systemstatus',
                },
                {
                    displayName: 'Audit',
                    route: 'audit',
                },
                {
                    displayName: 'Settings',
                    route: '/systemconfiguration',
                },
            ],
        },
    ];
}
