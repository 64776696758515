import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Schedule } from '../../../model/Schedule';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { throwError } from 'rxjs';
import { ScheduleService } from '../../../services/schedule.service';
import { ScheduleDialogComponent } from '../schedule-dialog/schedule-dialog.component';
import { ScheduleReportlistComponent } from '../schedule-reportlist/schedule-reportlist.component';
import { ReportService } from '../../../services/report.service';
import { Report } from '../../../model/Report';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { clientSession } from '../../../client-session';
import { TableUtil } from '../../../utilities/TableUtil';

@Component({
    selector: 'app-schedule-list',
    templateUrl: './schedule-list.component.html',
    styleUrls: ['./schedule-list.component.scss'],
})
export class ScheduleListComponent implements OnInit {
    schedules: Array<Schedule> = [];

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    @ViewChild(MatSort) sort!: MatSort;
    dataSource: MatTableDataSource<Schedule>;
    displayedColumns: string[] = [
        'period',
        'dayOfWeek',
        'updateDate',
        'isActive',
        'actionsColumn',
    ];
    filterValues: Array<string> = ['All', 'Active', 'InActive'];
    ActiveFilterFormControl = new FormControl();
    panelOpenState = false;
    constructor(
        private http: HttpClient,
        private service: ScheduleService,
        private reportservice: ReportService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar
    ) {
        this.dataSource = new MatTableDataSource(this.schedules);
    }

    openUserDialog() {
        this.SaveFilterValues();
        const userDialogConfig = new MatDialogConfig();

        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;

        this.dialog
            .open(ScheduleDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.loadScheduleList();
                }
            });
    }

    resetFilters() {
        this.dataSource.filter = '';
        this.ActiveFilterFormControl.setValue(undefined);
    }

    filterChange(event: any) {
        if (event.target.value.trim() == 'InActive') {
            this.dataSource.filter = JSON.stringify('false');
        } else if (event.target.value.trim() == 'Active') {
            this.dataSource.filter = JSON.stringify('true');
        } else {
            this.dataSource.filter = JSON.stringify('');
        }
        this.dataSource.filterPredicate = this.customFilterPredicate();
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        this.dataSource.filter = filterValue;
    }

    customFilterPredicate() {
        const myFilterPredicate = function (
            data: Schedule,
            filter: string
        ): boolean {
            let searchString = JSON.parse(filter);
            return (
                data.active
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(searchString.toLowerCase()) !== -1
            );
        };
        return myFilterPredicate;
    }

    loadScheduleList() {
        this.service.loadScheduleList().subscribe(result => {
            this.schedules = result;

            for (var i = 0; i < this.schedules.length; i++) {
                if (this.schedules[i].period.toUpperCase() == 'WEEKLY') {
                    this.schedules[i].period = 'Once a Week';
                    switch (this.schedules[i].dayOfWeek) {
                        case 2:
                            this.schedules[i].dayOfWeek = 'Monday';
                            break;
                        case 3:
                            this.schedules[i].dayOfWeek = 'Tuesday';
                            break;
                        case 4:
                            this.schedules[i].dayOfWeek = 'Wednesday';
                            break;
                        case 5:
                            this.schedules[i].dayOfWeek = 'Thursday';
                            break;
                        case 6:
                            this.schedules[i].dayOfWeek = 'Friday';
                            break;
                        case 7:
                            this.schedules[i].dayOfWeek = 'Saturday';
                            break;
                        case 1:
                            this.schedules[i].dayOfWeek = 'Sunday';
                            break;
                        default:
                            this.schedules[i].dayOfWeek = '';
                    }
                } else if (this.schedules[i].period.toUpperCase() == 'YEARLY') {
                    this.schedules[i].period = 'Once a Year';
                    // this.schedules[i].dayOfWeek = this.schedules[i].day;
                    this.schedules[i].dayOfWeek =
                        this.schedules[i].month == null
                            ? this.schedules[i].day
                            : this.schedules[i].month +
                              '/' +
                              this.schedules[i].day;
                } else if (
                    this.schedules[i].period.toUpperCase() == 'QUARTERLY'
                ) {
                    this.schedules[i].period = 'Once a Quarter';
                    this.schedules[i].dayOfWeek =
                        this.schedules[i].month + '/' + this.schedules[i].day;
                } else if (
                    this.schedules[i].period.toUpperCase() == 'MONTHLY'
                ) {
                    this.schedules[i].period = 'Once a Month';
                    this.schedules[i].dayOfWeek = this.schedules[i].day;
                }
            }
            this.dataSource = new MatTableDataSource(this.schedules);

            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = this.customFilterPredicate();
            if (this.schedules.length > 0) {
                if (clientSession.schedulefilter === '') {
                    this.panelOpenState = true;
                    this.ActiveFilterFormControl.setValue('Active');
                    this.dataSource.filter = JSON.stringify('true');
                    this.dataSource.filterPredicate =
                        this.customFilterPredicate();
                } else {
                    this.GetFilterValues();
                }
            }
        });
    }

    public handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }

    getSchedule(rowid: any) {
        this.SaveFilterValues();
        const scheduleDialogConfig = new MatDialogConfig();

        scheduleDialogConfig.disableClose = true;
        scheduleDialogConfig.autoFocus = true;
        scheduleDialogConfig.data = rowid;

        this.dialog
            .open(ScheduleDialogComponent, scheduleDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.loadScheduleList();
                }
            });
    }

    deleteScheduleDialog(rowid: string) {
        this.SaveFilterValues();
        this.reportservice.loadReportByScheduleID(rowid).subscribe(result => {
            if (result.length > 0) {
                const userDialogConfig = new MatDialogConfig();
                userDialogConfig.disableClose = true;
                userDialogConfig.autoFocus = true;
                //userDialogConfig.data = rowid;
                userDialogConfig.data = {
                    scheduleid: rowid,
                    result: result,
                };
                userDialogConfig.width = '60%';
                userDialogConfig.height = '300 px';
                this.dialog
                    .open(ScheduleReportlistComponent, userDialogConfig)
                    .afterClosed()
                    .subscribe(saved => {
                        if (saved) {
                            this.openSnackBar(
                                'Schedule has been deleted and removed from the associated reports.'
                            );
                            this.loadScheduleList();
                        }
                    });
            } else {
                this.deleteSchedule(rowid);
            }
        });
    }

    ViewReport(rowid: string) {
        this.SaveFilterValues();
        this.reportservice.loadReportByScheduleID(rowid).subscribe(result => {
            if (result.length > 0) {
                const userDialogConfig = new MatDialogConfig();
                userDialogConfig.disableClose = true;
                userDialogConfig.autoFocus = true;
                //userDialogConfig.data = rowid;
                userDialogConfig.data = {
                    scheduleid: rowid,
                    result: result,
                    viewMode: 'true',
                };
                userDialogConfig.width = '60%';
                userDialogConfig.height = '300 px';
                this.dialog
                    .open(ScheduleReportlistComponent, userDialogConfig)
                    .afterClosed()
                    .subscribe();
            } else {
                this.openSnackBar(
                    'Schedule has not been associated with any reports.'
                );
            }
        });
    }

    SaveFilterValues() {
        if (this.ActiveFilterFormControl.value != undefined) {
            clientSession.schedulefilter = this.ActiveFilterFormControl.value;
        }
    }

    GetFilterValues() {
        this.panelOpenState = true;
        this.ActiveFilterFormControl.setValue(clientSession.schedulefilter);
        if (clientSession.schedulefilter.trim() == 'InActive') {
            this.dataSource.filter = JSON.stringify('false');
        } else if (clientSession.schedulefilter.trim() == 'Active') {
            this.dataSource.filter = JSON.stringify('true');
        } else {
            this.dataSource.filter = JSON.stringify('');
        }

        this.dataSource.filterPredicate = this.customFilterPredicate();
    }

    deleteSchedule(rowid: string) {
        const userDialogConfig = new MatDialogConfig();

        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = {
            data: {
                message:
                    'This Schedule is not associated with any Reports.Are you sure you want to delete?',
                rowid: rowid,
                source: 'schedules',
                buttonText: {
                    ok: 'Delete',
                    cancel: 'Cancel',
                },
            },
        };

        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.openSnackBar(
                        'Schedule has been deleted successfully.'
                    );
                    this.loadScheduleList();
                }
            });
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }

    ngOnInit(): void {
        this.loadScheduleList();
    }

    btnExport() {
        TableUtil.exportArray(
            this.dataSource.filteredData.map(item => item),
            'Schedules'
        );
    }
}
