import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScheduledEmail } from '../model/ScheduledEmail';

@Injectable({
    providedIn: 'root',
})
export class ScheduledEmailService {
    constructor(private client: HttpClient) {}

    LoadScheduledEmails(): Observable<Array<ScheduledEmail>> {
        return this.client.get<Array<ScheduledEmail>>('/api/scheduledemail');
    }

    UpdateScheduledEmails(
        scheduledEmail: Array<ScheduledEmail>
    ): Observable<any> {
        return this.client.put<any>('/api/scheduledemail', scheduledEmail);
    }
}
