<mat-dialog-content>
    <div class="user-form">
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Azure Username</mat-label>
            <input
                type="email"
                matInput
                [formControl]="azureUsernameFormControl"
                placeholder="Ex. A012345@amerisourcebergen.com"
                maxlength="100" />
            <mat-error
                *ngIf="
                    azureUsernameFormControl.hasError('email') &&
                    !azureUsernameFormControl.hasError('required')
                ">
                Please enter a valid Azure username
            </mat-error>
            <mat-error *ngIf="azureUsernameFormControl.hasError('required')">
                Azure username is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="azureUsernameFormControl.hasError('notUnique')">
                Azure username is <strong>already being used</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Email</mat-label>
            <input
                type="email"
                matInput
                [formControl]="emailFormControl"
                placeholder="Ex. john@example.com"
                maxlength="100" />
            <mat-error
                *ngIf="
                    emailFormControl.hasError('email') &&
                    !emailFormControl.hasError('required')
                ">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
            <mat-label>First Name</mat-label>
            <input
                type="text"
                matInput
                [formControl]="firstNameFormControl"
                maxlength="50" />
            <mat-error *ngIf="firstNameFormControl.hasError('required')">
                Name is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="fill">
            <mat-label>Last Name</mat-label>
            <input
                type="text"
                matInput
                [formControl]="lastNameFormControl"
                maxlength="50" />
            <mat-error *ngIf="lastNameFormControl.hasError('required')">
                Name is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <div class="checkbox-group">
            <mat-checkbox [formControl]="isEnabledFormControl"
                >Enabled</mat-checkbox
            >
        </div>

        <div class="permissions-group">
            <mat-form-field appearance="fill">
                <mat-label>Datasets permission</mat-label>
                <mat-select [formControl]="datasetsPermissionFormControl">
                    <mat-option
                        *ngFor="let permission of permissionsLevels"
                        [value]="permission">
                        {{ permission }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Users permission</mat-label>
                <mat-select [formControl]="usersPermissionFormControl">
                    <mat-option
                        *ngFor="let permission of permissionsLevels"
                        [value]="permission">
                        {{ permission }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="onSubmit()">Save</button>
</mat-dialog-actions>
