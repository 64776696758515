<!--<div class="main-container">-->
<div class="flex-container-center-row fixedDivStyle">
    <button mat-raised-button color="primary" (click)="openUserDialog()">
        Add User
    </button>
    <button
        color="primary"
        style="margin-left: 10px"
        mat-raised-button
        (click)="btnExport()">
        Export
    </button>
</div>
<div class="flex-container-center-row">
    <app-user-list
        class="user-list-width"
        [entries]="users"
        (handleEditUser)="editUser($event)"></app-user-list>
</div>
<!--</div>-->
