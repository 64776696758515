<!--<mat-action-list class="list">
    <button
        mat-list-item
        *ngFor="let user of users"
        class="mat-elevation-z2"
        (click)="editUser(user)">
        <div class="list-item">
            <div class="name">{{ user.lastName }}, {{ user.firstName }}</div>
            <div *ngIf="user.isEnabled">Enabled</div>
            <div *ngIf="!user.isEnabled">Disabled</div>
        </div>
    </button>
</mat-action-list>-->
<div class="flex-container-grid mainDivStyle">
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Last Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
                element.lastName
            }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >First Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
                element.firstName
            }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="Enabled">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Status</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.Enabled }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actionsColumn">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell
                class="icon-padding"
                *matCellDef="let element; let j = index">
                <mat-icon
                    inline="true"
                    matTooltip="Click to Edit"
                    (click)="editUser(element)"
                    >edit</mat-icon
                >
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
</div>
