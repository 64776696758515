﻿<app-loader></app-loader>
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav
        #sidenav
        class="sidenav"
        fixedInViewport
        [mode]="'over'"
        [opened]="sidenav.opened">
        <mat-toolbar>GPOlink</mat-toolbar>
        <mat-nav-list>
            <div *ngFor="let item of menu">
                <div *ngIf="item.children && item.children.length > 0">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-list-item>
                                    {{ item.displayName }}
                                </mat-list-item>
                            </mat-expansion-panel-header>
                            <div *ngFor="let child of item.children">
                                <mat-list-item
                                    routerLink="{{ child.route }}"
                                    (click)="sidenav.close()">
                                    {{ child.displayName }}
                                </mat-list-item>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div *ngIf="!item.children || item.children.length === 0">
                    <mat-list-item
                        routerLink="{{ item.route }}"
                        (click)="sidenav.close()">
                        {{ item.displayName }}
                    </mat-list-item>
                </div>
            </div>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button mat-icon-button (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <button mat-button [routerLink]="['']">GPOlink</button>
            <span class="toolbar-spacer"></span>
            <span class="mat-body">
                {{ azureProfile?.surname }},&nbsp;{{ azureProfile?.givenName }}
            </span>
        </mat-toolbar>
        <router-outlet *ngIf="!isIframe"></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
