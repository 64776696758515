import { Injectable } from '@angular/core';
import { BehaviorSubject, delay } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private loading = new BehaviorSubject<boolean>(false);
    public readonly isLoading = this.loading.asObservable().pipe(delay(1));

    constructor() {}

    show() {
        this.loading.next(true);
    }

    hide() {
        this.loading.next(false);
    }
}
