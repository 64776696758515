import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../../../model/User';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent {
    // @Input() users: Array<User> = [];
    @Input() set entries(users: Array<User>) {
        this.setDataSourceAttributes(users);
    }
    @Output() handleEditUser: EventEmitter<User> = new EventEmitter();
    //@ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatSort, { static: false }) sort!: MatSort;

    dataSource: MatTableDataSource<User>;
    displayedColumns: string[] = [
        'lastName',
        'firstName',
        'Enabled',
        'actionsColumn',
    ];
    constructor() {
        this.dataSource = new MatTableDataSource(this.entries);
    }

    editUser(user: User) {
        this.handleEditUser.emit(user);
    }

    setDataSourceAttributes(data: any) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
    }
}
