import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Report } from '../../../model/Report';
import { ReportService } from '../../../services/report.service';

@Component({
    selector: 'app-schedule-reportlist',
    templateUrl: './schedule-reportlist.component.html',
    styleUrls: ['./schedule-reportlist.component.scss'],
})
export class ScheduleReportlistComponent {
    reportlist: Array<Report> = [];
    message: string =
        'This Schedule is associated with the below Reports. Deleting this schedule will remove it from these reports. Are you sure you want to delete?';
    dataSource: MatTableDataSource<Report>;
    displayedColumns: string[] = [
        'manufacturerName',
        'productName',
        'gpo',
        'reportType',
        'reportPeriod',
    ];
    scheduleId: string;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    viewMode: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private reportservice: ReportService,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<ScheduleReportlistComponent>
    ) {
        this.scheduleId = '';
        if (data) {
            this.scheduleId = data.scheduleid;
            this.reportlist = data.result;

            if (data.viewMode != undefined) {
                this.viewMode = data.viewMode;
            }
            this.dataSource = new MatTableDataSource(this.reportlist);
        }
        this.dataSource = new MatTableDataSource(this.reportlist);
    }

    async delete() {
        await this.http
            .delete(`/api/schedules/reportschedules/${this.scheduleId}`)
            .toPromise()
            .catch((error: Response) => {
                console.log(error);
                this.openSnackBar('Error in deleteing the Schedule.');
                this.dialogRef.close(false);
            });
        this.dialogRef.close(true);
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }
}
