<mat-dialog-content class="mat-dialog-style">
    <!--<div class="user-form">-->
    <div class="divRowStyle fixedDivStyleDialog">
        <mat-form-field appearance="fill" class="filterstyle">
            <input
                type="text"
                [formControl]="inputFilterFormControl"
                matInput
                (keyup)="applyFilter($any($event.target).value)"
                placeholder="Filter by AgreementId or AgreementName" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="textboxstyling">
            <mat-label>Starting Position</mat-label>
            <input
                type="number"
                matInput
                [formControl]="startingPositionFormControl"
                min="1"
                step="1"
                pattern="\d+" />

            <mat-error
                *ngIf="startingPositionFormControl.hasError('min') && submitted"
                class="mat-errorField">
                Min Position is 1.
            </mat-error>
        </mat-form-field>
        <mat-error class="mat-errorField" *ngIf="!isInteger">
            Only <strong>Integer</strong> values are allowed
        </mat-error>
        <mat-dialog-actions align="end">
            <button color="primary" mat-raised-button (click)="add()">
                Add
            </button>
            <button color="primary" mat-raised-button mat-dialog-close>
                Close
            </button>
        </mat-dialog-actions>
    </div>

    <div class="mainDivStyleDialog">
        <mat-error
            class="mat-errorField"
            *ngIf="!selection.selected.length && submitted">
            <br />
            Please select an <strong>Agreement</strong>
        </mat-error>
        <div class="mat-elevation-z8" style="width: 100%">
            <mat-table
                [dataSource]="dataSource"
                class="mat-elevation-z8"
                style="width: 100%">
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>Select</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(element) : null"
                            [checked]="selection.isSelected(element)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="agreementId">
                    <mat-header-cell *matHeaderCellDef
                        >Agreement Id</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        {{ element.agreementId }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="agreementName">
                    <mat-header-cell *matHeaderCellDef>
                        Agreement Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.agreementName }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="agreementTypeDescription">
                    <mat-header-cell *matHeaderCellDef>
                        Agreement Type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.agreementTypeDescription }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="periodProfileDescription">
                    <mat-header-cell *matHeaderCellDef>
                        Period Profile
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.periodProfileDescription }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="manufacturer">
                    <mat-header-cell *matHeaderCellDef
                        >Manufacturer</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        {{ element.manufacturer }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="drug">
                    <mat-header-cell *matHeaderCellDef>Drug</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.drug }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="levelDescription">
                    <mat-header-cell *matHeaderCellDef>Level</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.levelDescription }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="gpo">
                    <mat-header-cell *matHeaderCellDef>GPO</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.gpo }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="route">
                    <mat-header-cell *matHeaderCellDef>Route</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.route }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="contractStartDate">
                    <mat-header-cell *matHeaderCellDef
                        >Start Date</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        {{ element.contractStartDate | date : 'MM/dd/yyyy' }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="contractExpDate">
                    <mat-header-cell *matHeaderCellDef
                        >Expiry date</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        {{ element.contractExpDate | date : 'MM/dd/yyyy' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="hide">
                    <mat-header-cell *matHeaderCellDef>Hide</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox
                            [value]="element.hide"
                            (click)="$event.stopPropagation()"
                            [(ngModel)]="element.hide"
                            [checked]="element.hide"></mat-checkbox>
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
    </div>
    <!--</div>-->
</mat-dialog-content>
