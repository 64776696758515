<div class="column-layout">
    <div class="fixedDivStyle">
        <!--<div align="end">-->
        <!--<div style="position:fixed; z-index:5">-->
        <button
            color="primary"
            (click)="newDataset()"
            mat-raised-button
            class="buttonstyling">
            New Dataset
        </button>

        <button
            color="primary"
            style="margin-left: 10px"
            mat-raised-button
            (click)="btnExport()">
            Export
        </button>
    </div>
</div>
<div class="flex-container-grid mainDivStyle">
    <mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        matSort
        style="width: 60%">
        <ng-container matColumnDef="viewName">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Dataset Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
                element.viewName
            }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="updateDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Last Modified</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
                {{ element.updateDate | date : 'MM/dd/yyyy' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="version">
            <mat-header-cell *matHeaderCellDef mat-sort-header
                >Version</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.version }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="active">
            <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-checkbox
                    [value]="element.active"
                    [checked]="element.active"
                    [disabled]="true"></mat-checkbox> </mat-cell
            >>
        </ng-container>
        <ng-container matColumnDef="actionsColumn">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell
                class="icon-padding"
                *matCellDef="let element; let j = index">
                <mat-icon
                    inline="true"
                    matTooltip="Click to Edit"
                    (click)="editDataSet(element)"
                    >edit</mat-icon
                >
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
</div>
