import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { formatDate } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    MatDialog,
    MatDialogConfig,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription, timer } from 'rxjs';
import { ReportingServicesLog } from '../../../model/ReportingServicesLog';
import {
    PharmaReportJobStatusDetails,
    PharmaReportJobStatusHeader,
} from '../../../model/PharmaReportJobStatus';
import { AuditlogService } from '../../../services/auditlog.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-log-viewer',
    templateUrl: './log-viewer.component.html',
    styleUrls: ['./log-viewer.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class LogViewerComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChildren('innerTables') innerTables:
        | QueryList<MatTable<PharmaReportJobStatusDetails>>
        | undefined;
    @ViewChildren('innerSort') innerSort!: QueryList<MatSort>;
    everySixtySeconds: Observable<number> = timer(0, 60000);
    // subscription: Subscription = new Subscription();
    reportingServicesLog: Array<ReportingServicesLog> = [];
    dataSource: MatTableDataSource<ReportingServicesLog>;
    dataSourceStatus: MatTableDataSource<PharmaReportJobStatusHeader>;
    pharmaReportJobStatusHeaderList: Array<PharmaReportJobStatusHeader> = [];
    showLogsTable: boolean = false;
    expandedElement: PharmaReportJobStatusHeader | null | undefined;
    displayedColumns: string[] = [ 'timestamp', 'message', 'exception'];
    innerDisplayedColumns = [
        'reportName',
        'jobStart',
        'jobEnd',
        'noOfRecords',
        'reportConfig',
        'eapData',
        'generateExcel',
        'blobUploadSF',
        'createSFUpload',
        'errorMessage',
    ];
    displayedColumnsStatus: string[] = [
        'jobStartedDate',
        'jobCompleteDate',
        'reportsRunCount',
        'actionsColumn',
    ];
    dateformcontrol = new FormControl(new Date());
    selecteddate: string;

    constructor(
        private auditlogservice: AuditlogService,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private cd: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource(this.reportingServicesLog);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSourceStatus = new MatTableDataSource(
            this.pharmaReportJobStatusHeaderList
        );
        this.dataSourceStatus.sort = this.sort;
        this.dataSourceStatus.paginator = this.paginator;
        if (data) {
            this.selecteddate = formatDate(
                data.SelectedDate,
                'MM-dd-yyyy',
                'en-US'
            );
            this.dateformcontrol.setValue(data.SelectedDate);
        } else {
            this.selecteddate = formatDate(Date.now(), 'MM-dd-yyyy', 'en-US');
        }
    }
    ngOnInit(): void {
        //this.subscription = this.everySixtySeconds.subscribe(() => {
        //    this.loadPharmaReportJobStatus(this.selecteddate);
        //    this.loadAppLogList(this.selecteddate);
        //});

        this.pharmaReportJobStatusHeaderList.forEach(item => {
            if (
                item.pharmaReportJobStatusDetails != undefined &&
                item.pharmaReportJobStatusDetails &&
                Array.isArray(item.pharmaReportJobStatusDetails) &&
                item.pharmaReportJobStatusDetails.length
            ) {
                this.pharmaReportJobStatusHeaderList = [
                    ...this.pharmaReportJobStatusHeaderList,
                    {
                        ...item,
                        pharmaReportJobStatusDetails: new MatTableDataSource(
                            item.pharmaReportJobStatusDetails
                        ),
                    },
                ];
            } else {
                this.pharmaReportJobStatusHeaderList = [
                    ...this.pharmaReportJobStatusHeaderList,
                    item,
                ];
            }
        });
    }

    loadReportingServicesLogList(selecteddate: any) {
        this.auditlogservice
            .loadReportingServicesLogListByDate(selecteddate)
            .subscribe(result => {
                this.reportingServicesLog = result;
                this.dataSource = new MatTableDataSource(
                    this.reportingServicesLog
                );
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            });
    }

    loadPharmaReportJobStatus(selecteddate: any) {
        this.pharmaReportJobStatusHeaderList = [];

        this.auditlogservice
            .loadPharmaReportJobStatusByDate(selecteddate)
            .subscribe(result => {
                result.forEach(item => {
                    if (
                        item.pharmaReportJobStatusDetails != undefined &&
                        item.pharmaReportJobStatusDetails &&
                        Array.isArray(item.pharmaReportJobStatusDetails) &&
                        item.pharmaReportJobStatusDetails.length
                    ) {
                        this.pharmaReportJobStatusHeaderList = [
                            ...this.pharmaReportJobStatusHeaderList,
                            {
                                ...item,
                                pharmaReportJobStatusDetails:
                                    new MatTableDataSource(
                                        item.pharmaReportJobStatusDetails
                                    ),
                            },
                        ];
                    } else {
                        this.pharmaReportJobStatusHeaderList = [
                            ...this.pharmaReportJobStatusHeaderList,
                            item,
                        ];
                    }
                });
                this.dataSourceStatus = new MatTableDataSource(
                    this.pharmaReportJobStatusHeaderList
                );
                this.dataSourceStatus.sort = this.sort;
                this.dataSourceStatus.paginator = this.paginator;
            });
    }

    showLogs() {
        this.loadReportingServicesLogList(this.selecteddate);
        this.showLogsTable = true;
    }

    ShowLoadStatus() {
        this.showLogsTable = false;
        this.loadPharmaReportJobStatus(this.selecteddate);
    }

    //refresh() {
    //    if (this.showLogsTable) {
    //        this.loadAppLogList(this.selecteddate);
    //    }
    //    else {
    //        this.loadPharmaReportJobStatus(this.selecteddate);
    //    }
    //}

    OnDateChange(selecteddate: any) {
        this.selecteddate = formatDate(selecteddate, 'MM-dd-yyyy', 'en-US');
        if (this.showLogsTable) {
            this.loadReportingServicesLogList(this.selecteddate);
        } else {
            this.loadPharmaReportJobStatus(this.selecteddate);
        }
    }

    openDialog(row: PharmaReportJobStatusDetails): void {
        const userDialogConfig = new MatDialogConfig();
        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        console.log(row);
        userDialogConfig.data = {
            data: {
                message: row.errorMessage,
                rowid: 0,
                source: 'logviewer',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel',
                },
            },
        };
        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe();
    }

    openDialogReportingServicesLogMessage(row: ReportingServicesLog): void {
        const userDialogConfig = new MatDialogConfig();
        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        console.log(row);
        userDialogConfig.data = {
            data: {
                message: row.message,
                rowid: 0,
                source: 'logviewer',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel',
                },
            },
        };
        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe();
    }

    openDialogReportingServicesLogError(row: ReportingServicesLog): void {
        const userDialogConfig = new MatDialogConfig();
        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        console.log(row);
        userDialogConfig.data = {
            data: {
                message: row.exception,
                rowid: 0,
                source: 'logviewer',
                buttonText: {
                    ok: 'Ok',
                    cancel: 'Cancel',
                },
            },
        };
        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe();
    }

    toggleRow(element: PharmaReportJobStatusHeader) {
        element.pharmaReportJobStatusDetails &&
        (
            element.pharmaReportJobStatusDetails as MatTableDataSource<PharmaReportJobStatusDetails>
        ).data?.length
            ? (this.expandedElement =
                  this.expandedElement === element ? null : element)
            : null;
        this.cd.detectChanges();

        if (this.innerTables != undefined) {
            this.innerTables.forEach(
                (table, index) =>
                    ((
                        table.dataSource as MatTableDataSource<PharmaReportJobStatusDetails>
                    ).sort = this.innerSort.toArray()[index])
            );
        }
    }

    ngOnDestroy() {
        //this.subscription.unsubscribe();
    }
}
