<div>
    <div class="fixedDivStyleLabel">
        <p style="align-content: flex-start">{{ isAddOrEdit() }} Report</p>
    </div>
    <div class="fixedDivStyle">
        <button
            class="buttonstyling"
            color="primary"
            mat-raised-button
            (click)="edit()">
            Edit
        </button>
        <button
            class="buttonstyling"
            color="primary"
            mat-raised-button
            (click)="cancel()">
            Cancel
        </button>
        <button
            class="buttonstyling"
            color="primary"
            mat-raised-button
            (click)="save()">
            Save
        </button>
    </div>
    <!--</div>-->
    <div class="mainDivStyle">
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="Report Config">
                <div align="center">
                    <div class="rowStyle">
                        <div>
                            <h4>Manufacturer</h4>

                            <mat-form-field
                                appearance="fill"
                                class="full-width">
                                <mat-label
                                    >Please select a Manufacturer</mat-label
                                >
                                <input
                                    type="text"
                                    placeholder="Pick one"
                                    matInput
                                    [formControl]="manufacturerFormControl"
                                    [matAutocomplete]="auto" />

                                <mat-autocomplete
                                    #auto="matAutocomplete"
                                    [displayWith]="displayManufacturer">
                                    <mat-option
                                        *ngFor="
                                            let manufacturer of filteredManufacturer
                                                | async
                                        "
                                        [value]="manufacturer">
                                        {{
                                            manufacturer.manufacturerName.concat(
                                                ' (',
                                                manufacturer.manufacturerId,
                                                ')'
                                            )
                                        }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-error
                                class="mat-errorField"
                                *ngIf="
                                    manufacturerFormControl.hasError(
                                        'required'
                                    ) && submitted
                                ">
                                Manufacturer is <strong>required</strong>
                            </mat-error>
                        </div>

                        <div class="margin">
                            <h4>Product Name</h4>
                            <mat-form-field
                                appearance="fill"
                                class="full-width">
                                <mat-label>Please select a Product</mat-label>
                                <input
                                    type="text"
                                    placeholder="Pick one"
                                    matInput
                                    [formControl]="productNameFormControl"
                                    [matAutocomplete]="auto2" />

                                <mat-autocomplete
                                    #auto2="matAutocomplete"
                                    [displayWith]="displayProduct">
                                    <mat-option
                                        *ngFor="
                                            let product of filteredProducts
                                                | async
                                        "
                                        [value]="product">
                                        {{ product.productName }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-error
                                class="mat-errorField"
                                *ngIf="
                                    productNameFormControl.hasError(
                                        'required'
                                    ) && submitted
                                ">
                                Product is <strong>required</strong>
                            </mat-error>
                        </div>
                    </div>

                    <div class="rowStyle">
                        <div>
                            <h4>Report Period</h4>

                            <mat-form-field
                                appearance="fill"
                                class="full-width">
                                <mat-label
                                    >Please select a Report Period</mat-label
                                >
                                <mat-select
                                    name="reportperiod"
                                    [formControl]="reportperiodFormControl"
                                    required>
                                    <mat-option
                                        *ngFor="
                                            let reportperiodval of reportperiod
                                        "
                                        [value]="reportperiodval.value">
                                        {{ reportperiodval.viewValue }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error
                                class="mat-errorField"
                                *ngIf="
                                    reportperiodFormControl.hasError(
                                        'required'
                                    ) && submitted
                                ">
                                Report Period is <strong>required</strong>
                            </mat-error>
                        </div>
                        <div class="margin">
                            <h4>Report Type</h4>

                            <mat-form-field
                                appearance="fill"
                                class="full-width">
                                <mat-label
                                    >Please select a Report Type</mat-label
                                >
                                <mat-select
                                    name="reporttype"
                                    [formControl]="reporttypeFormControl"
                                    required>
                                    <mat-option
                                        *ngFor="let reporttypeval of reporttype"
                                        [value]="reporttypeval.value">
                                        {{ reporttypeval.viewValue }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error
                                class="mat-errorField"
                                *ngIf="
                                    reporttypeFormControl.hasError(
                                        'required'
                                    ) && submitted
                                ">
                                Report Type is <strong>required</strong>
                            </mat-error>
                        </div>
                    </div>

                    <div class="rowStyle">
                        <div>
                            <h4>GPO</h4>

                            <mat-form-field
                                appearance="fill"
                                class="full-width">
                                <mat-label>Please select a GPO</mat-label>
                                <mat-select
                                    name="gpo"
                                    [formControl]="gpoFormControl"
                                    required>
                                    <mat-option
                                        *ngFor="let gpoval of gpo"
                                        [value]="gpoval.value">
                                        {{ gpoval.viewValue }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error
                                class="mat-errorField"
                                *ngIf="
                                    gpoFormControl.hasError('required') &&
                                    submitted
                                ">
                                GPO is <strong>required</strong>
                            </mat-error>
                        </div>
                        <div class="margin">
                            <h4>Dataset</h4>

                            <mat-form-field
                                appearance="fill"
                                class="full-width">
                                <mat-label>Please select a Dataset</mat-label>
                                <mat-select
                                    name="view"
                                    [formControl]="viewFormControl"
                                    required
                                    (selectionChange)="
                                        changeViewColumns($event)
                                    ">
                                    <mat-option
                                        *ngFor="let viewval of view"
                                        [value]="viewval.id">
                                        {{ viewval.viewName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error
                                class="mat-errorField"
                                *ngIf="
                                    viewFormControl.hasError('required') &&
                                    submitted
                                ">
                                View is <strong>required</strong>
                            </mat-error>
                        </div>
                    </div>

                    <div class="rowStyle">
                        <div>
                            <h4>Email Template</h4>
                            <mat-form-field
                                appearance="fill"
                                class="full-width">
                                <mat-label
                                    >Please select an Email Template</mat-label
                                >
                                <mat-select
                                    name="emailtemplate"
                                    [formControl]="emailtemplateFormControl"
                                    required>
                                    <mat-option
                                        *ngFor="
                                            let emailTemplateval of emailTemplates
                                        "
                                        [value]="emailTemplateval.id">
                                        {{ emailTemplateval.templateName }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error
                                class="mat-errorField"
                                *ngIf="
                                    emailtemplateFormControl.hasError(
                                        'required'
                                    ) && submitted
                                ">
                                Email Template is <strong>required</strong>
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div align="center">
                    <div align="center">
                        <h4 class="alignEmail">Append To Name</h4>
                        <mat-form-field
                            appearance="fill"
                            class="full-width tablewidth">
                            <textarea
                                matInput
                                rows="2"
                                maxlength="255"
                                [formControl]="appendToNameFormControl">
                            </textarea>
                        </mat-form-field>
                        <mat-error
                            class="mat-errorField"
                            *ngIf="appendToNameFormControl.hasError('pattern')">
                            Only alphanumeric, dash, underscore, and parentheses
                            for example a-z 0-9 and - _ ( ) are allowed!
                        </mat-error>
                    </div>

                    <div align="center" *ngIf="isEdit">
                        <h4 class="alignEmail">Report Name (Generated)</h4>
                        <mat-form-field
                            appearance="fill"
                            class="full-width tablewidth">
                            <textarea
                                matInput
                                readonly
                                rows="2"
                                [formControl]="reportNameFormControl">
                            </textarea>
                        </mat-form-field>
                    </div>
                    <div align="center" class="tablewidth">
                        <h4 class="alignEmail">Schedules</h4>
                        <mat-error
                            class="mat-errorField"
                            *ngIf="
                                !selectionSchedule.selected.length && submitted
                            ">
                            Schedule is <strong>required</strong>
                        </mat-error>
                        <mat-table
                            [dataSource]="dataSourceSchedule"
                            class="mat-tablefixedHeight">
                            <ng-container matColumnDef="select">
                                <mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox
                                        [disabled]="viewMode"
                                        (change)="
                                            $event
                                                ? masterToggleSchedule()
                                                : null
                                        "
                                        [checked]="
                                            selectionSchedule.hasValue() &&
                                            isAllSelectedSchedule()
                                        "
                                        [indeterminate]="
                                            selectionSchedule.hasValue() &&
                                            !isAllSelectedSchedule()
                                        ">
                                    </mat-checkbox>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <mat-checkbox
                                        [disabled]="viewMode"
                                        (click)="$event.stopPropagation()"
                                        (change)="
                                            $event
                                                ? selectionSchedule.toggle(row)
                                                : null
                                        "
                                        [checked]="
                                            selectionSchedule.isSelected(row)
                                        "
                                        (input)="enableSchedule(row, $event)">
                                    </mat-checkbox>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="dayOfWeek">
                                <mat-header-cell *matHeaderCellDef>
                                    Schedule
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{ element.dayOfWeek }}
                                </mat-cell>
                            </ng-container>

                            <mat-header-row
                                *matHeaderRowDef="
                                    displayedColumnsSchedule
                                "></mat-header-row>
                            <mat-row
                                *matRowDef="
                                    let row;
                                    columns: displayedColumnsSchedule
                                "></mat-row>
                        </mat-table>
                    </div>
                </div>

                <div align="center">
                    <div class="tablewidth">
                        <h4 class="alignEmail" style="padding-top: 10px">
                            Email Groups
                        </h4>
                        <mat-form-field
                            appearance="outline"
                            style="display: block !important">
                            <input
                                matInput
                                (keyup)="applyFilter($any($event.target).value)"
                                placeholder="Filter" />
                        </mat-form-field>
                    </div>
                    <div align="center" class="tablewidth">
                        <mat-error
                            class="mat-errorField"
                            *ngIf="!selection.selected.length && submitted">
                            EmailGroup is <strong>required</strong>
                        </mat-error>
                        <mat-table
                            [dataSource]="dataSource"
                            class="mat-tablefixedHeight">
                            <ng-container matColumnDef="selectGroup">
                                <mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox
                                        [disabled]="viewMode"
                                        (change)="
                                            $event ? masterToggle() : null
                                        "
                                        [checked]="
                                            selection.hasValue() &&
                                            isAllSelected()
                                        "
                                        [indeterminate]="
                                            selection.hasValue() &&
                                            !isAllSelected()
                                        ">
                                    </mat-checkbox>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <mat-checkbox
                                        [disabled]="viewMode"
                                        (click)="$event.stopPropagation()"
                                        (change)="
                                            $event
                                                ? selection.toggle(row)
                                                : null
                                        "
                                        [checked]="selection.isSelected(row)"
                                        (input)="enableEmailgroup(row, $event)">
                                    </mat-checkbox>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="groupName">
                                <mat-header-cell *matHeaderCellDef>
                                    Name
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{ element.groupName }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="totalContacts">
                                <mat-header-cell *matHeaderCellDef>
                                    contacts
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{ element.totalContacts }}
                                </mat-cell>
                            </ng-container>
                            <mat-header-row
                                *matHeaderRowDef="
                                    displayedColumns
                                "></mat-header-row>
                            <mat-row
                                *matRowDef="
                                    let row;
                                    columns: displayedColumns
                                "></mat-row>
                        </mat-table>
                    </div>
                </div>
                <br />
            </mat-tab>
            <mat-tab label="Report View/Sort">
                <div class="main-container">
                    <div>
                        <p style="align-content: flex-start; font-weight: bold">
                            Excluded Columns
                        </p>
                        <mat-selection-list
                            #collist
                            [multiple]="true"
                            class="matselection"
                            [disabled]="viewMode">
                            <mat-list-option
                                *ngFor="let col of typesOfReportViewColumns"
                                [value]="col"
                                [selected]="true"
                                title="{{ col }}">
                                {{ col }}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                    <div class="alignMatIcon">
                        <mat-icon
                            class="matIconDisplay"
                            (click)="
                                AddColumns(collist.selectedOptions.selected)
                            "
                            >arrow_forward</mat-icon
                        ><br />

                        <mat-icon
                            class="matIconDisplay"
                            (click)="
                                RemoveColumns(
                                    selectedcollist.selectedOptions.selected
                                )
                            "
                            >arrow_back</mat-icon
                        >
                    </div>
                    <div>
                        <p style="align-content: flex-start; font-weight: bold">
                            Included Columns
                        </p>
                        <mat-selection-list
                            #selectedcollist
                            [multiple]="false"
                            class="matselection"
                            [disabled]="viewMode">
                            <mat-list-option
                                *ngFor="
                                    let col of typesOfSelectedReportViewColumns
                                "
                                [value]="col.columnName">
                                {{ col.columnName }}
                            </mat-list-option>
                        </mat-selection-list>
                    </div>
                    <div class="alignMatIcon">
                        <mat-icon
                            class="matIconDisplay"
                            (click)="
                                SortColumnsUp(
                                    selectedcollist.selectedOptions.selected
                                )
                            "
                            >arrow_upward</mat-icon
                        ><br />
                        <mat-icon
                            class="matIconDisplay"
                            (click)="
                                SortColumnsDown(
                                    selectedcollist.selectedOptions.selected
                                )
                            "
                            >arrow_downward</mat-icon
                        >
                    </div>
                </div>
                <div style="margin: 30px 300px 0px 30px; text-align: center">
                    <h4>Column Name</h4>
                </div>
                <div class="main-container">
                    <div>
                        <div
                            *ngFor="let col of typesOfSelectedReportViewColumns"
                            class="matlabelColumns">
                            <mat-label>{{ col.columnName }}</mat-label>
                        </div>
                    </div>
                    <div style="margin-left: 35px">
                        <div
                            *ngFor="
                                let col of typesOfSelectedReportViewColumns
                            ">
                            <!--<mat-label>{{col}}</mat-label>-->
                            <mat-form-field appearance="fill">
                                <mat-label class="matlabel"
                                    >Alias Name</mat-label
                                >
                                <input
                                    [(ngModel)]="col.alias"
                                    maxlength="255"
                                    type="text"
                                    matInput
                                    value="{{ col.alias }}"
                                    [disabled]="viewMode" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Report Parameters">
                <div style="text-align: center">
                    <mat-form-field
                        appearance="fill"
                        class="full-width matReportParameters">
                        <mat-label>Column selection</mat-label>
                        <!--<mat-select name="column selection"
                                [formControl]="columnselectionFormControl">
                        <mat-option *ngFor="let colval of reportViewColumns"
                                    [value]="colval">
                            {{ colval }}
                        </mat-option>
                    </mat-select>-->
                        <input
                            style="margin-top: 1px"
                            type="text"
                            placeholder="Pick one"
                            matInput
                            [formControl]="columnselectionFormControl"
                            [matAutocomplete]="autoRptView" />
                        <mat-autocomplete #autoRptView="matAutocomplete">
                            <mat-option
                                *ngFor="let option of filteredOptions | async"
                                [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field
                        appearance="fill"
                        class="full-width matReportParameters">
                        <mat-label>Operator</mat-label>
                        <mat-select
                            style="margin-top: -4px"
                            name="operator"
                            [formControl]="operatorFormControl">
                            <mat-option
                                *ngFor="let operatorval of operator"
                                [value]="operatorval.value">
                                {{ operatorval.viewValue }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field
                        class="full-width matReportParameters textarea-width"
                        appearance="fill">
                        <mat-label>Value</mat-label>
                        <textarea
                            matInput
                            rows="1"
                            cols="100"
                            [formControl]="valueFormControl">
                        </textarea>

                        <mat-error
                            *ngIf="valueFormControl.hasError('required')">
                            value is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>

                    <button
                        class="buttonstyling"
                        color="primary"
                        mat-raised-button
                        (click)="AddReportParameters()">
                        Add Parameter
                    </button>
                </div>
                <h3 style="text-align: center">Configured Parameters</h3>
                <div class="flex-container-grid">
                    <mat-table
                        style="width: 85%"
                        class="mat-elevation-z8"
                        [dataSource]="dataSourceReportParameters">
                        <ng-container matColumnDef="columnName">
                            <mat-header-cell *matHeaderCellDef>
                                Column Name
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.columnName }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="operator">
                            <mat-header-cell *matHeaderCellDef>
                                Operator
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{ element.operator }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="parameterValue">
                            <mat-header-cell *matHeaderCellDef
                                >Value</mat-header-cell
                            >
                            <mat-cell *matCellDef="let element">
                                {{ element.parameterValue }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="active">
                            <mat-header-cell *matHeaderCellDef>
                                Active
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <mat-checkbox
                                    [value]="element.active"
                                    [(ngModel)]="element.active"
                                    [disabled]="viewMode"></mat-checkbox>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="actionsColumn">
                            <mat-header-cell *matHeaderCellDef>
                                Delete
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element; let j = index">
                                <mat-icon
                                    (click)="
                                        deleteReportParameterDialog(element)
                                    "
                                    >close</mat-icon
                                >
                            </mat-cell>
                        </ng-container>
                        <mat-header-row
                            *matHeaderRowDef="
                                displayedColumnsReportParameters
                            "></mat-header-row>
                        <mat-row
                            *matRowDef="
                                let row;
                                columns: displayedColumnsReportParameters
                            "></mat-row>
                    </mat-table>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
