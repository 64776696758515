<div style="margin: 10px">
    <div>
        <mat-form-field appearance="fill" class="textboxstyling">
            <mat-label>Selected Agreements</mat-label>
            <textarea
                matInput
                rows="5"
                cols="80"
                [formControl]="selectedAgreementsFormControl"
                readonly>
            </textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" class="textbox1styling">
            <mat-label>Starting Position</mat-label>
            <input
                type="number"
                min="1"
                step="1"
                pattern="\d+"
                matInput
                [formControl]="startingPositionFormControl"
                maxlength="255" />
        </mat-form-field>
        <mat-error
            class="mat-errorField"
            style="margin-left: 70%"
            *ngIf="!isInteger">
            Only <strong>Integer</strong> values are allowed
        </mat-error>
        <div>
            <mat-dialog-actions class="actions" align="center">
                <button
                    color="primary"
                    mat-raised-button
                    (click)="movedposition()">
                    Ok
                </button>
                <button color="primary" mat-raised-button mat-dialog-close>
                    Close
                </button>
            </mat-dialog-actions>
        </div>
    </div>
</div>
