import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Schedule } from '../../../model/Schedule';

@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent {
    schedules: Array<Schedule> = [];
    constructor(private http: HttpClient, private dialog: MatDialog) {}
}
