import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../../model/User';
import { Permission, PermissionName } from '../../../model/UserPermission';

@Component({
    selector: 'app-user-dialog',
    templateUrl: './user-dialog.component.html',
    styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent {
    public permissionsLevels: string[] = Object.values(Permission);
    public formGroup: FormGroup;
    public azureUsernameFormControl: FormControl;
    public emailFormControl: FormControl;
    public firstNameFormControl: FormControl;
    public lastNameFormControl: FormControl;
    public isEnabledFormControl: FormControl;
    public datasetsPermissionFormControl: FormControl;
    public usersPermissionFormControl: FormControl;
    private idFormControl: FormControl;

    constructor(
        private http: HttpClient,
        private dialogRef: MatDialogRef<UserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: User
    ) {
        this.idFormControl = new FormControl(data?.id ?? '');
        this.azureUsernameFormControl = new FormControl(
            { value: data?.azureUsername ?? '', disabled: this.isEdit() },
            [Validators.required, Validators.email, Validators.maxLength(100)]
        );
        this.emailFormControl = new FormControl(data?.email ?? '', [
            Validators.required,
            Validators.email,
            Validators.maxLength(100),
        ]);
        this.firstNameFormControl = new FormControl(data?.firstName ?? '', [
            Validators.required,
            Validators.maxLength(50),
        ]);
        this.lastNameFormControl = new FormControl(data?.lastName ?? '', [
            Validators.required,
            Validators.maxLength(50),
        ]);
        this.isEnabledFormControl = new FormControl(data?.isEnabled ?? true);
        this.datasetsPermissionFormControl = new FormControl(
            data?.userPermissions?.find(
                e => e.permissionName == PermissionName.Datasets
            )?.permission ?? Permission.None
        );
        this.usersPermissionFormControl = new FormControl(
            data?.userPermissions?.find(
                e => e.permissionName == PermissionName.Users
            )?.permission ?? Permission.None
        );
        this.formGroup = new FormGroup({
            azureUsername: this.azureUsernameFormControl,
            email: this.emailFormControl,
            firstName: this.firstNameFormControl,
            lastName: this.lastNameFormControl,
            isEnabled: this.isEnabledFormControl,
            datasetsPermission: this.datasetsPermissionFormControl,
            usersPermission: this.usersPermissionFormControl,
        });
    }

    isEdit() {
        return this.data != null;
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }

    onSubmit() {
        if (this.formGroup.invalid) {
            return;
        }

        if (this.isEdit()) {
            this.http
                .put<any>('/api/users', {
                    id: this.idFormControl.value,
                    azureUsername: this.azureUsernameFormControl.value,
                    email: this.emailFormControl.value,
                    firstName: this.firstNameFormControl.value,
                    lastName: this.lastNameFormControl.value,
                    isEnabled: this.isEnabledFormControl.value,
                    userPermissions: [
                        {
                            permissionName: PermissionName.Datasets,
                            permission:
                                this.datasetsPermissionFormControl.value,
                        },
                        {
                            permissionName: PermissionName.Users,
                            permission: this.usersPermissionFormControl.value,
                        },
                    ],
                })
                .pipe(catchError(this.handleError))
                .subscribe(() => this.dialogRef.close(true));
        } else {
            this.http
                .post<any>('/api/users', {
                    azureUsername: this.azureUsernameFormControl.value,
                    email: this.emailFormControl.value,
                    firstName: this.firstNameFormControl.value,
                    lastName: this.lastNameFormControl.value,
                    isEnabled: this.isEnabledFormControl.value,
                    userPermissions: [
                        {
                            permissionName: PermissionName.Datasets,
                            permission:
                                this.datasetsPermissionFormControl.value,
                        },
                        {
                            permissionName: PermissionName.Users,
                            permission: this.usersPermissionFormControl.value,
                        },
                    ],
                })
                .pipe(catchError(this.handleError))
                .subscribe(result =>
                    result.status === 'notUnique'
                        ? this.azureUsernameFormControl.setErrors({
                              notUnique: true,
                          })
                        : this.dialogRef.close(true)
                );
        }
    }
}
