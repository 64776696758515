import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LinkLog } from '../../model/LinkLog';
import { AuditlogService } from '../../services/auditlog.service';

@Component({
    selector: 'app-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.scss'],
})
export class AuditComponent implements OnInit {
    linklog: Array<LinkLog> = [];
    dynamicHeader: string;
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    dataSource: MatTableDataSource<LinkLog>;
    displayedColumns: string[] = [
        'source',
        'sourceName',
        'message',
        'data',
        'exception',
        'timestamp',
    ];
    sourceFilterFormControl = new FormControl();
    dateformcontrol = new FormControl(new Date());
    panelOpenState = false;
    sourceFilterValues: Array<string> = [];

    constructor(private auditlogservice: AuditlogService) {
        this.dataSource = new MatTableDataSource(this.linklog);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = this.customFilterPredicate();
        this.dynamicHeader = 'Name';
        this.panelOpenState = true;
    }
    ngOnInit(): void {
        this.loadAuditLogList(formatDate(Date.now(), 'MM-dd-yyyy', 'en-US'));
        this.loadAuditLogSourceList();
    }

    filterChange(event: any) {
        this.dataSource.filter = JSON.stringify(event.target.value.trim());
        this.dataSource.filterPredicate = this.customFilterPredicate();
        if (event.target.value.trim() === 'EmailSender') {
            this.dynamicHeader = 'Report Name';
        } else if (event.target.value.trim() === 'DataSets') {
            this.dynamicHeader = 'Dataset Name';
        } else {
            this.dynamicHeader = 'Name';
        }
    }

    customFilterPredicate() {
        const myFilterPredicate = function (
            data: LinkLog,
            filter: string
        ): boolean {
            let searchString = JSON.parse(filter);
            return (
                data.source
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(searchString.toLowerCase()) !== -1
            );
        };
        return myFilterPredicate;
    }
    resetFilters() {
        this.dataSource.filter = '';
        this.sourceFilterFormControl.setValue(undefined);
        this.dateformcontrol.setValue(new Date());
        this.loadAuditLogList(formatDate(Date.now(), 'MM-dd-yyyy', 'en-US'));
        this.dynamicHeader = 'Name';
    }

    OnDateChange(selecteddate: any) {
        selecteddate = formatDate(selecteddate, 'MM-dd-yyyy', 'en-US');
        this.loadAuditLogList(selecteddate);
    }

    loadAuditLogSourceList() {
        this.auditlogservice.loadLinkLogSourceList().subscribe(result => {
            this.sourceFilterValues = result;
        });
    }

    loadAuditLogList(selecteddate: any) {
        this.auditlogservice
            .loadLinkLogListByDate(selecteddate)
            .subscribe(result => {
                this.linklog = result;

                this.dataSource = new MatTableDataSource(this.linklog);

                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                this.dataSource.filterPredicate = (
                    data: LinkLog,
                    filter: string
                ): boolean => {
                    let searchString = JSON.parse(filter);
                    return (
                        data.source
                            .toString()
                            .trim()
                            .toLowerCase()
                            .indexOf(searchString.toLowerCase()) !== -1
                    );
                };
                if (
                    this.sourceFilterFormControl != null &&
                    this.sourceFilterFormControl != undefined &&
                    this.sourceFilterFormControl.value != null
                ) {
                    this.dataSource.filter = JSON.stringify(
                        this.sourceFilterFormControl.value.trim()
                    );
                }
            });
    }
}
