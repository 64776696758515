import { HttpClient } from '@angular/common/http';
import { filterValues, Report } from './model/Report';
import { User } from './model/User';
import { Permission, PermissionName } from './model/UserPermission';

export class ClientSession {
    userPrincipalName?: string;
    private _linkUser?: User;
    private _filterOptions: filterValues = {} as filterValues;

    get linkUser() {
        if (!this._linkUser) {
            let user = sessionStorage.getItem('gpo-user');
            if (user) {
                this._linkUser = JSON.parse(user);
            }
        }
        return this._linkUser;
    }

    public userHasPermission(
        permissionName: PermissionName,
        permission: Permission
    ) {
        return this._linkUser?.userPermissions.find(
            e =>
                e.permissionName == permissionName && e.permission == permission
        );
    }

    public async loadLinkUser(httpClient: HttpClient) {
        this._linkUser = await httpClient
            .get<User>(`/api/users/${this.userPrincipalName}`)
            .toPromise();
        sessionStorage.setItem('gpo-user', JSON.stringify(this._linkUser));
    }

    get filterOptions() {
        if (!this._filterOptions) {
            let filter = sessionStorage.getItem('gpo-filteroptions');
            if (filter) {
                this._filterOptions = JSON.parse(filter);
            }
        }
        return this._filterOptions;
    }

    set filterOptions(filter: filterValues) {
        sessionStorage.setItem('gpo-filteroptions', JSON.stringify(filter));
    }

    get schedulefilter() {
        let filter = sessionStorage.getItem('gpo-schedulefilteroptions');
        if (filter) {
            return JSON.parse(filter);
        } else {
            return '';
        }
    }

    set schedulefilter(filter: string) {
        sessionStorage.setItem(
            'gpo-schedulefilteroptions',
            JSON.stringify(filter)
        );
    }
}

export const clientSession = new ClientSession();
