import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SystemStatus } from '../../model/SystemStatus';

@Component({
    selector: 'app-system-status',
    templateUrl: './system-status.component.html',
    styleUrls: ['./system-status.component.scss'],
})
export class SystemStatusComponent implements OnInit {
    public systemStatus?: SystemStatus[];
    public databaseVersion: SystemStatus[] = [];
    public linkVersion: SystemStatus[] = [];

    constructor(private http: HttpClient) {}

    ngOnInit() {
        this.http.get<SystemStatus[]>('/api/systemstatus').subscribe(
            result => {
                if (this.systemStatus == undefined) {
                    this.systemStatus = result;
                } else {
                    this.systemStatus = this.systemStatus?.concat(result);
                }
                console.log(this.systemStatus);
            },
            error => console.error(error)
        );

        this.http.get<SystemStatus[]>('/api/linkversion').subscribe(
            result => {
                if (this.systemStatus == undefined) {
                    this.systemStatus = result;
                } else {
                    this.systemStatus = this.systemStatus?.concat(result);
                }
            },
            error => console.error(this.systemStatus)
        );

        this.http.get<SystemStatus[]>('/api/databaseversion').subscribe(
            result => {
                if (this.systemStatus == undefined) {
                    this.systemStatus = result;
                } else {
                    this.systemStatus = this.systemStatus?.concat(result);
                }
            },
            error => console.error(this.systemStatus)
        );
    }
}
