import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Schedule } from '../model/Schedule';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ScheduleService {
    constructor(private client: HttpClient) {}

    loadScheduleList(): Observable<Array<Schedule>> {
        return this.client.get<Array<Schedule>>('/api/schedules');
        //.pipe(
        //catchError((error: HttpErrorResponse) => {
        //    return throwError(
        //        `Error retrieving schedules data. ${error.statusText || "Unknown"} `
        //    );
        //})
        // );
    }
}
