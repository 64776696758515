import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
    PublicClientApplication,
    InteractionType,
    LogLevel,
} from '@azure/msal-browser';
import {
    MsalBroadcastService,
    MsalGuard,
    MsalInterceptor,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
} from '@azure/msal-angular';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { LayoutModule } from '@angular/cdk/layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { HomeComponent } from './components/home/home.component';
import { AppComponent } from './app.component';
import { UserManagementComponent } from './components/user/user-management.component';
import { UserDialogComponent } from './components/user/user-dialog/user-dialog.component';
import { SystemConfigurationComponent } from './components/systemconfiguration/system-configuration.component';
import { AppRoutingModule } from './app-routing.module';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MatTableModule } from '@angular/material/table';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { ScheduleComponent } from './components/reports/schedule/schedule.component';
import { ScheduleDialogComponent } from './components/reports/schedule-dialog/schedule-dialog.component';
import { ScheduleListComponent } from './components/reports/schedule-list/schedule-list.component';
import { ConfirmDialogComponent } from './components/shared/confirm-dialog/confirm-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { ScheduleService } from './services/schedule.service';
import { ReportDeleteDialogComponent } from './components/reports/report-delete-dialog/report-delete-dialog.component';
import { ReportManagementComponent } from './components/reports/report-management/report-management.component';
import { ReportWizardComponent } from './components/reports/report-wizard/report-wizard.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { EmailtemplateListComponent } from './components/mailer/emailtemplate-list/emailtemplate-list.component';
import { EmailtemplateComponent } from './components/mailer/emailtemplate/emailtemplate.component';
import { EmailgroupListComponent } from './components/mailer/emailgroup-list/emailgroup-list.component';
import { EmailgroupComponent } from './components/mailer/emailgroup/emailgroup.component';
import { EmailtemplateService } from './services/emailtemplate.service';
import { EmailgroupService } from './services/emailgroup.service';
import { ReportService } from './services/report.service';
import { NotesListComponent } from './components/scorecard/notes-list/notes-list.component';
import { NotesDialogComponent } from './components/scorecard/notes-dialog/notes-dialog.component';
import { SystemStatusComponent } from './components/systemstatus/system-status.component';
import { SortComponent } from './components/scorecard/sort/sort/sort.component';
import { SortmoveitemComponent } from './components/scorecard/sort/sortmoveitem/sortmoveitem.component';
import { SortadditemComponent } from './components/scorecard/sort/sortadditem/sortadditem.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ScheduledEmailService } from './services/scheduledemail.service';
import { ScheduledEmailComponent } from './components/mailer/scheduledemails/scheduledemail.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserAuthenticatedGuardService } from './services/authorization/user-authenticated-guard.service';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';
import { NotesCopydialogComponent } from './components/scorecard/notes-copydialog/notes-copydialog.component';
import { CustomToolTipComponent } from './components/shared/custom-tool-tip/custom-tool-tip.component';
import { ToolTipRendererDirective } from './components/shared/tool-tip-renderer.directive';
import { SortCopydialogComponent } from './components/scorecard/sort/sort-copydialog/sort-copydialog.component';
import { ScheduleReportlistComponent } from './components/reports/schedule-reportlist/schedule-reportlist.component';
import { DatasetListComponent } from './components/reports/dataset-list/dataset-list.component';
import { DatasetsGuardService } from './services/authorization/datasets-guard.service';
import { UsersGuardService } from './services/authorization/users-guard.service';
import { DatasetDialogComponent } from './components/reports/dataset-dialog/dataset-dialog.component';
import { AuditComponent } from './components/audit/audit.component';
import { LogViewerComponent } from './components/reports/log-viewer/log-viewer.component';
import { RosterGenerationComponent } from './components/rosters/roster-generation/roster-generation.component';
import { RosterLogsComponent } from './components/rosters/roster-logs/roster-logs.component';

export let AppInjector: Injector;
const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

const components = [
    BrowserAnimationsModule,
    LayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    MatTableModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTabsModule,
    MatAutocompleteModule,
];

const declarations = [
    AppComponent,
    SystemConfigurationComponent,
    HomeComponent,
    UserManagementComponent,
    UserDialogComponent,
    UserListComponent,
    UnauthorizedComponent,
    LoaderComponent,
    ScheduleComponent,
    ScheduleDialogComponent,
    ScheduleListComponent,
    ConfirmDialogComponent,
    ReportDeleteDialogComponent,
    ReportManagementComponent,
    ReportWizardComponent,
    EmailtemplateListComponent,
    EmailtemplateComponent,
    EmailgroupListComponent,
    EmailgroupComponent,
    NotesListComponent,
    ScheduledEmailComponent,
    NotesDialogComponent,
    SystemStatusComponent,
    SortComponent,
    SortmoveitemComponent,
    SortadditemComponent,
    NotesCopydialogComponent,
    CustomToolTipComponent,
    ToolTipRendererDirective,
    SortCopydialogComponent,
    ScheduleReportlistComponent,
    DatasetListComponent,
    DatasetDialogComponent,
    AuditComponent,
    LogViewerComponent,
    RosterGenerationComponent,
    RosterLogsComponent,
];

@NgModule({
    declarations: [...declarations],
    imports: [
        ...components,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: 'f7bba0a8-fa92-45a1-98cd-12f832810d36',
                    authority:
                        'https://login.microsoftonline.com/0b5ac31e-e17a-4021-9d04-550f86320765',
                    //redirectUri: 'https://localhost:4200',
                    //postLogoutRedirectUri: 'http://localhost:4200'
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
                },
                system: {
                    loggerOptions: {
                        loggerCallback(logLevel: LogLevel, message: string) {},
                        logLevel: LogLevel.Error,
                        piiLoggingEnabled: false,
                    },
                },
            }),
            {
                interactionType: InteractionType.Redirect, // Msal Guard Configuration
                authRequest: {
                    scopes: ['user.read'],
                },
                //loginFailedRoute: '/login-failed',
            },
            {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new Map([
                    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
                    [
                        '/api/',
                        [
                            'api://624c4673-ec81-458a-836a-cdf0665f8572/GPO-REST-API.ReadWrite.All',
                        ],
                    ],
                ]),
            }
        ),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        ScheduleService,
        ReportService,
        ScheduledEmailService,
        EmailtemplateService,
        EmailgroupService,
        LoaderService,
        UserAuthenticatedGuardService,
        DatasetsGuardService,
        UsersGuardService,
        CanDeactivateGuardService,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}
