<div class="column-layout">
    <div class="fixedDivStyle">
        <button
            color="primary"
            (click)="loadScheduleEmailsList()"
            mat-raised-button
            class="buttonrefresh">
            Refresh
        </button>
        <button
            color="primary"
            (click)="resend()"
            mat-raised-button
            class="buttonstyling">
            Send
        </button>
        <button color="primary" mat-raised-button (click)="btnExport()">
            Export
        </button>
    </div>

    <div class="flex-container-grid mainDivStyle">
        <div class="mat-elevation-z8" style="width: 90%">
            <mat-table
                [dataSource]="dataSource"
                class="mat-elevation-z8"
                style="width: 100%">
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                            (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="
                                selection.hasValue() && !isAllSelected()
                            ">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            (input)="AddRemoveForResend(row, $event)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="manufacturerName">
                    <mat-header-cell *matHeaderCellDef
                        >Manufacturer</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        {{ element.report.manufacturerName }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="productName">
                    <mat-header-cell *matHeaderCellDef
                        >Product Name</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        {{ element.report.productName }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="gpo">
                    <mat-header-cell *matHeaderCellDef>GPO</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.report.gpo }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="dataset">
                    <mat-header-cell *matHeaderCellDef>DataSet</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.report.reportView.viewName }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="reportGenerationDate">
                    <mat-header-cell *matHeaderCellDef
                        >Report Date</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        {{
                            element.reportGenerationDate
                                | date : 'MM/dd/yyyy h:mm a'
                        }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="emailSentDate">
                    <mat-header-cell *matHeaderCellDef
                        >Sent Date</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                        {{ element.emailSentDate | date : 'MM/dd/yyyy h:mm a' }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="error">
                    <mat-header-cell *matHeaderCellDef>Error</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ element.error }}
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
    </div>
</div>
