import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { catchError, throwError } from 'rxjs';
import { User } from '../../model/User';
import { TableUtil } from '../../utilities/TableUtil';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { UserListComponent } from './user-list/user-list.component';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
    @ViewChild(UserListComponent)
    private userListComponent!: UserListComponent;
    users: Array<User> = [];

    constructor(private http: HttpClient, private dialog: MatDialog) {
        this.loadUserList();
    }
    async ngOnInit(): Promise<void> {
        this.loadUserList();
    }
    loadUserList() {
        this.http
            .get<Array<User>>('/api/users')
            .pipe(catchError(this.handleError))
            .subscribe(result => {
                this.users = result;
                for (var i = 0; i < this.users.length; i++) {
                    if (this.users[i].isEnabled == true) {
                        this.users[i].Enabled = 'Enabled';
                    } else {
                        this.users[i].Enabled = 'Disabled';
                    }
                }
            });
    }

    openUserDialog(user?: User) {
        const userDialogConfig = new MatDialogConfig();

        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = user;

        this.dialog
            .open(UserDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.loadUserList();
                }
            });
    }

    editUser(user: User) {
        this.openUserDialog(user);
    }

    btnExport() {
        TableUtil.exportArray(this.users, 'Users');
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }
}
