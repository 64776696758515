import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { clientSession } from '../../../client-session';
import { Email, EmailGroup } from '../../../model/EmailGroup';
import { ManufacturerContact } from '../../../model/ManufacturerContact';
import { EmailgroupService } from '../../../services/emailgroup.service';

@Component({
    selector: 'app-emailgroup',
    templateUrl: './emailgroup.component.html',
    styleUrls: ['./emailgroup.component.scss'],
})
export class EmailgroupComponent implements OnInit {
    public formGroup: FormGroup;
    public GroupNameFormControl: FormControl;
    public GroupTypeFormControl: FormControl;
    public manufacturerFormControl: FormControl;
    public isActiveFormControl: FormControl;

    submitted: boolean = false;
    isVisibleManufacturer: boolean = false;
    isVisibleInternalUsers: boolean = false;
    PrevSelectedManufacturer: string = '';
    CurrSelectedManufacturer: string = '';
    SaveEmailGroup: EmailGroup = {} as EmailGroup;

    emailcontactList: Array<ManufacturerContact> = [];

    addedemailcontactList: Array<ManufacturerContact> = [];
    addedEmails: Array<Email> = [];

    dataSource: MatTableDataSource<ManufacturerContact>;
    dataSourceSelected: MatTableDataSource<ManufacturerContact>;
    displayedColumns = ['select', 'firstName', 'lastName', 'email'];
    selection = new SelectionModel<ManufacturerContact>(true, []);
    selectedEmails = new SelectionModel<ManufacturerContact>(true, []);
    manufacturer: Array<string> = [];
    manufacturerContactList: Array<ManufacturerContact> | undefined = [];
    manufacturerContactListTemp: Array<ManufacturerContact> | undefined = [];
    manufacturerContactGlobal: Array<ManufacturerContact> | undefined = [];
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    data: any;
    isEdit: boolean = false;
    isSync: boolean = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        private snackBar: MatSnackBar,
        private emailgroupservice: EmailgroupService
    ) {
        var mode = this.router.getCurrentNavigation()?.extras.state;

        if (mode != null) {
            this.data = mode['editData'];

            this.isEdit = true;
        }
        this.dataSource = new MatTableDataSource(this.emailcontactList);
        this.dataSourceSelected = new MatTableDataSource(
            this.addedemailcontactList
        );
        this.GroupNameFormControl = new FormControl('', [
            Validators.required,
            Validators.maxLength(100),
        ]);
        this.GroupTypeFormControl = new FormControl('', [Validators.required]);
        this.manufacturerFormControl = new FormControl('', [
            Validators.required,
        ]);
        this.isActiveFormControl = new FormControl('' ?? false);

        this.formGroup = new FormGroup({
            groupName: this.GroupNameFormControl,
            groupType: this.GroupTypeFormControl,
            manufacturer: this.manufacturerFormControl,
            activeFormControl: this.isActiveFormControl,
        });
    }

    ngOnInit(): void {
        this.loadManufacturercontactList();
        // this.loadInternalcontactList();

        if (this.isEdit) {
            this.GroupNameFormControl.setValue(this.data.groupName);
            if (this.data.manufacturerName === 'Internal Users') {
                this.GroupTypeFormControl.setValue('2');
                this.loadInternalcontactList();
                this.isVisibleManufacturer = false;
                this.isVisibleInternalUsers = true;
            } else {
                this.GroupTypeFormControl.setValue('1');
                //this.loadManufacturercontactList();
                this.isVisibleManufacturer = true;
                this.isVisibleInternalUsers = false;
                this.manufacturerFormControl.setValue(
                    this.data.manufacturerName
                );
            }

            this.isActiveFormControl.setValue(this.data.active);
        } else {
            this.loadManufacturercontactList();
            this.isActiveFormControl.setValue(true);
        }
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach(row => this.selection.select(row));
    }
    //Adds the contact to the selected list
    AddEmail() {
        for (let i = 0; i < this.selection.selected.length; i++) {
            this.addedemailcontactList.push(this.selection.selected[i]);

            if (
                this.emailcontactList.indexOf(this.selection.selected[i]) >= 0
            ) {
                this.emailcontactList.splice(
                    this.emailcontactList.indexOf(this.selection.selected[i]),
                    1
                );
            }
        }
        this.selection.clear();
        this.dataSourceSelected = new MatTableDataSource(
            this.addedemailcontactList
        );
        this.dataSource = new MatTableDataSource(this.emailcontactList);
    }
    //Removes the contact from the selected list
    RemoveEmail() {
        for (let i = 0; i < this.selectedEmails.selected.length; i++) {
            if (this.GroupTypeFormControl.value == 1) {
                const manuf = this.manufacturerFormControl.value;
                const distinctList = this.manufacturerContactList?.filter(
                    function (manufacturerList) {
                        return manufacturerList.manufacturerName == manuf;
                    }
                );

                this.manufacturerContactListTemp = distinctList?.filter(
                    u => u.eapId == this.selectedEmails.selected[i].eapId
                );
                if (this.manufacturerContactListTemp?.length == 1) {
                    this.emailcontactList.push(this.selectedEmails.selected[i]);
                }
            } else if (this.GroupTypeFormControl.value == 2) {
                this.manufacturerContactGlobal =
                    this.manufacturerContactGlobal?.filter(
                        u => u.eapId == this.selectedEmails.selected[i].eapId
                    );
                if (this.manufacturerContactGlobal?.length == 1) {
                    this.emailcontactList.push(this.selectedEmails.selected[i]);
                }
            }

            if (
                this.addedemailcontactList.indexOf(
                    this.selectedEmails.selected[i]
                ) >= 0
            ) {
                this.addedemailcontactList.splice(
                    this.addedemailcontactList.indexOf(
                        this.selectedEmails.selected[i]
                    ),
                    1
                );
            }
        }
        this.selectedEmails.clear();
        this.dataSourceSelected = new MatTableDataSource(
            this.addedemailcontactList
        );
        this.dataSource = new MatTableDataSource(this.emailcontactList);
    }

    isAllSelectedEmail() {
        const numSelected = this.selectedEmails.selected.length;
        const numRows = this.dataSourceSelected.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggleSelectedValue() {
        this.isAllSelectedEmail()
            ? this.selectedEmails.clear()
            : this.dataSourceSelected.data.forEach(row =>
                  this.selectedEmails.select(row)
              );
    }

    //Handles the radiobutton change event(Internal users or Manufacturer)
    radioButtonChanged() {
        let radioValue = this.GroupTypeFormControl.value;
        if (radioValue == 1) {
            this.isVisibleManufacturer = true;
            this.isVisibleInternalUsers = false;
            this.LoadManufacturerTable(this.manufacturerFormControl.value);
        } else {
            this.isVisibleManufacturer = false;
            this.isVisibleInternalUsers = true;
            this.loadInternalcontactList();
        }
    }
    onSubmit() {
        this.submitted = true;

        if (this.GroupTypeFormControl.value == 2) {
            this.formGroup.removeControl('manufacturer');
        }
        if (this.addedemailcontactList.length <= 0) {
            return;
        } else if (this.formGroup.invalid) {
            return;
        }

        this.SaveEmailGroup.active = true;
        this.SaveEmailGroup.groupName = this.GroupNameFormControl.value;
        this.SaveEmailGroup.manufacturerName =
            this.manufacturerFormControl.value;
        this.SaveEmailGroup.updateUserId = this.getLoginUserId();
        var userType = '';

        this.SaveEmailGroup.active = this.isActiveFormControl.value;
        if (this.isActiveFormControl.value == '') {
            this.SaveEmailGroup.active = false;
        }

        const manuf = this.manufacturerFormControl.value;
        const distinctList = this.manufacturerContactList?.filter(function (
            manufacturerList
        ) {
            return manufacturerList.manufacturerName == manuf;
        });
        this.SaveEmailGroup.manufacturerName = 'Internal Users';
        const emails: Array<Email> = [];

        this.addedemailcontactList.forEach(
            (emailContact: ManufacturerContact) => {
                userType = 'Internal';

                distinctList?.forEach(
                    (emailContactList: ManufacturerContact) => {
                        if (emailContactList.eapId === emailContact.eapId) {
                            userType = 'External';
                            this.SaveEmailGroup.manufacturerName =
                                this.manufacturerFormControl.value;
                        }
                    }
                );

                emails.push({
                    eapId: emailContact.eapId,
                    userType: userType,
                    firstName: emailContact.firstName,
                    lastName: emailContact.lastName,
                    emailAddress: emailContact.email,
                    updateDate: new Date(),
                    updateUserId: this.SaveEmailGroup.updateUserId,
                });
            }
        );

        this.SaveEmailGroup.emails = emails;

        if (this.isEdit) {
            this.SaveEmailGroup.id = this.data.id;
            this.emailgroupservice
                .EditEmailgroup(this.SaveEmailGroup)
                .pipe(catchError(this.handleError))
                .subscribe(
                    result => {
                        if (result != undefined) {
                            if (result.status === 'notUnique') {
                                this.openSnackBar('Email Group notUnique.');
                            } else {
                                this.openSnackBar(
                                    'Email Group updated successfully'
                                );
                                this.router.navigateByUrl('/emailgrouplist');
                            }
                        }
                    },
                    err => {
                        this.openSnackBar(err);
                    }
                );
        } else {
            this.emailgroupservice
                .SaveEmailgroup(this.SaveEmailGroup)
                .pipe(catchError(this.handleError))
                .subscribe(
                    result => {
                        if (result != undefined) {
                            if (result.status === 'notUnique') {
                                this.openSnackBar('Email Group notUnique.');
                            } else {
                                this.openSnackBar(
                                    'EmailGroup saved successfully'
                                );
                                this.router.navigateByUrl('/emailgrouplist');
                            }
                        }
                    },
                    err => {
                        this.openSnackBar(err);
                    }
                );
        }
    }
    getLoginUserId() {
        return clientSession.linkUser?.id || '';
    }

    onCancel() {
        this.router.navigateByUrl('/emailgrouplist');
    }

    //Loads the Manufacturer dropdown
    async loadManufacturercontactList() {
        this.manufacturerContactList = await this.http
            .get<Array<ManufacturerContact>>('/api/manufacturers/contacts')
            .toPromise();

        if (this.manufacturerContactList != null) {
            const unique = Array.from(
                new Set(
                    this.manufacturerContactList.map(
                        item => item.manufacturerName
                    )
                )
            );
            this.manufacturer = unique;
        }
        if (
            this.isEdit &&
            this.manufacturerContactList != null &&
            this.data.manufacturerName !== 'Internal Users'
        ) {
            this.LoadManufacturerTable(this.manufacturerFormControl.value);
            if (this.isSync == false) {
                this.LoadandSyncSelectedContacts();
                this.isSync = true;
            }
        }
    }

    //Loads the internal contact list
    loadInternalcontactList() {
        this.emailgroupservice.loadInternalContactsList().subscribe(result => {
            this.emailcontactList = [];

            this.emailcontactList = result;
            this.manufacturerContactGlobal = result;

            this.ClearMattableselections();
            if (this.isEdit && this.isSync == false) {
                this.LoadandSyncSelectedContacts();
                this.isSync = true;
            }
            this.SyncContactsTable();
        });
    }

    //Clear the selections in the mattable
    ClearMattableselections() {
        this.selection.clear();
        this.selectedEmails.clear();
        this.dataSourceSelected = new MatTableDataSource(
            this.addedemailcontactList
        );
        this.dataSource = new MatTableDataSource(this.emailcontactList);
        //console.log(this.emailcontactList.length);
    }

    //Remove the added contacts from source table
    SyncContactsTable() {
        this.addedemailcontactList.forEach(
            (emailContact: ManufacturerContact) => {
                this.emailcontactList = this.emailcontactList.filter(
                    u => u.eapId !== emailContact.eapId
                );
            }
        );

        this.dataSource = new MatTableDataSource(this.emailcontactList);
    }

    //Sync the tables during Edit
    LoadandSyncSelectedContacts() {
        this.data.emails.forEach((emailContact: Email) => {
            var ObjContact: ManufacturerContact = {} as ManufacturerContact;

            ObjContact.eapId = emailContact.eapId;
            ObjContact.firstName = emailContact.firstName;
            ObjContact.lastName = emailContact.lastName;
            ObjContact.email = emailContact.emailAddress;

            this.addedemailcontactList.push(ObjContact);
        });

        this.addedemailcontactList.forEach(
            (emailContact: ManufacturerContact) => {
                this.emailcontactList = this.emailcontactList.filter(
                    u => u.eapId !== emailContact.eapId
                );
            }
        );

        this.dataSourceSelected = new MatTableDataSource(
            this.addedemailcontactList
        );
        this.dataSource = new MatTableDataSource(this.emailcontactList);
    }

    //Handles the manufacturer dropdown change event
    onChangeManufacturer(event: any) {
        this.LoadManufacturerTable(event);
    }

    //Removes the manufacturer from the added list if the Manufacturer is changed in the dropdown
    RemoveManufacturerContact(event: any) {
        const distinctList = this.manufacturerContactList?.filter(function (
            manufacturerList
        ) {
            return manufacturerList.manufacturerName == event;
        });

        distinctList?.forEach((emailContact: ManufacturerContact) => {
            this.addedemailcontactList = this.addedemailcontactList.filter(
                u => u.eapId !== emailContact.eapId
            );
        });

        this.dataSourceSelected = new MatTableDataSource(
            this.addedemailcontactList
        );

        this.selectedEmails.clear();
        this.dataSourceSelected = new MatTableDataSource(
            this.addedemailcontactList
        );
        this.dataSource = new MatTableDataSource(this.emailcontactList);
    }

    //Loads the Manufacturer list
    LoadManufacturerTable(event: any) {
        if (this.PrevSelectedManufacturer == '') {
            this.PrevSelectedManufacturer = event;
        } else {
            //Remove RightTable entries After the Manufacturer dropdown change
            if (this.PrevSelectedManufacturer != event) {
                this.RemoveManufacturerContact(this.PrevSelectedManufacturer);
            }
            this.PrevSelectedManufacturer = event;
        }
        const distinctList = this.manufacturerContactList?.filter(function (
            manufacturerList
        ) {
            return manufacturerList.manufacturerName == event;
        });
        this.emailcontactList = [];

        if (distinctList != null) {
            this.emailcontactList = distinctList;
        }
        //console.log('clear');
        this.ClearMattableselections();
        this.SyncContactsTable();
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
        });
    }
    isAddOrEdit() {
        return this.isEdit ? 'Edit' : 'Create';
    }
    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }
}
