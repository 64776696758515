export interface UserPermission {
    permissionName: string;
    permission: string;
}

export enum PermissionName {
    Datasets = 'Datasets',
    Users = 'Users',
}

export enum Permission {
    None = 'None',
    Read = 'Read',
    All = 'All',
}
